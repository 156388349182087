import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import './styles/buttons.css';
import './styles/global.css';
import './styles/pagination.css';
import './styles/loading.css';
import 'typeface-poppins';
import { AuthProvider } from './AuthContext';
import AuthContent from './AuthContent';
import LoginForm from './Forms/Login/Login';

const globalStyles = {
  fontFamily: 'Poppins, sans-serif',
  backgroundColor: '#F0F0F0'
  // Otros estilos globales si es necesario
};

function App() {
  const [isCollapsed, setIsCollapsed] = useState(false);

const toggleSidebar = () => {
  setIsCollapsed(!isCollapsed);
};

  return (
    <AuthProvider>
      <Router>
        <div className="App" style={{ ...globalStyles  }}>
          <Routes>
            <Route path="/login" element={
              <LoginForm />} />
            <Route path="/*" element={
              <AuthContent 
                globalStyles={globalStyles} 
                isCollapsed={isCollapsed} 
                toggleSidebar={toggleSidebar} 
              />} 
            />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
