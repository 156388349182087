import jsPDF from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import medicGeekApi from "../../config/axiosConfig";
import { jwtDecode } from "jwt-decode";
import "./../../styles/scrollbar.css";
import ConsultaForm from "./../Consulta/ConsultaForm";

const PatientsHistory = ({
  paciente,
  onCloseForm,
  formulario,
  setSelectedPaciente,
  setCurrentForm,
  currentForm,
}) => {
  const { pcteId, consultId } = useParams();
  const [selectedPacienteLocal, setSelectedPacienteLocal] = useState(null);
  const [selectedFormulario, setSelectedFormulario] = useState("");
  const [selected, setSelected] = useState(false);
  const [pacienteConsulta, setPacienteConsulta] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const navigate = useNavigate();
  let recetasPorConsultId = {};

  const history = useNavigate();
  const [examComentario, setExamComentario] = useState("");
  const [estGabinete, setEstGabinete] = useState("");
  const [consultDiag, setConsultDiag] = useState("");
  const [consultTipDiag, setConsultTipDiag] = useState("");
  const [consultClaDiag, setConsultClaDiag] = useState("");

  // Acceso para acceder a todo el detalle de la consulta
  const [selectedTappcte, setSelectedTappcte] = useState(null);
  const [fechaConsulta, setFechaConsulta] = useState(null);
  const [fechaConsultaListado, setFechaConsultaListado] = useState(null);
  const [activeTabDetail, setActiveTabDetail] = useState("1");

  // Acceso para acceder a toda la consulta seleccionada
  const [consultaDetalleRelacionada, setConsultaDetalleRelacionada] =
    useState(null);

  // Activar los tabs
  const [activeTab, setActiveTab] = useState("1");
  const [activeTabLab, setActiveTabLab] = useState("1");

  const [modalOpenDetal, setModalOpenDetal] = useState(false);
  const [activeTabDetal, setActiveTabDetal] = useState("1");

  // Patologia
  const [tappctesData, setTappctesData] = useState({
    checke1: false,
    checke2: false,
    checke3: false,
    checke4: false,
    checke5: false,
    checke6: false,
    checke7: false,
    checke8: false,
    checke9: false,
    checke10: false,
    checke11: false,
    checke12: false,
    checke13: false,
    checke14: false,
    checke15: false,
    toxicChecke1: false,
    toxicChecke2: false,
    toxicChecke3: false,
    toxicOtros: "",
  });

  // Consultas
  const [consultas, setConsultas] = useState([]);
  const [antecedentes, setAntecedentes] = useState([]);
  const [recetas, setRecetas] = useState([]);
  const [sortedRecetas, setSortedRecetas] = useState([]);
  const [setDisplayedRecetas] = useState([]);
  const [ultimaConsulta, setUltimaConsulta] = useState(0);

  // Cruds
  const [externalCauses, setExternalCauses] = useState([]);
  const [causeId, setCauseId] = useState("");

  // Agregar altura
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [parentDivHeight, setParentDivHeight] = useState("18rem");
  const [alturaContenedorCita, setAlturaContenedorCita] = useState("10rem");
  const parentDivRef = useRef();
  const [expanded, setExpanded] = useState(false);

  // Categoria
  const [categoryId, setCategoryId] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [cie10Data, setCie10Data] = useState([]);

  // Paginacion
  const [pageNumberRecetas, setPageNumberRecetas] = useState(0);
  const consultasPerPage = 10;
  const antecedentesPerPage = 10;
  const recetasPerPage = 10;

  const [pageNumber, setPageNumber] = useState(0);
  const pageCountConsult = Math.ceil(consultas.length / consultasPerPage);
  const pageCountAntecedentes = Math.ceil(
    antecedentes.length / antecedentesPerPage
  );
  const pageCountRecetas = Math.ceil(
    Object.values(recetas).length / recetasPerPage
  );

  const [currentPage, setCurrentPage] = useState(0);

  // Modal
  const [modalRecetaOpen, setModalRecetaOpen] = useState(false);
  const [selectedReceta, setSelectedReceta] = useState(null);

  const [modalDetailOpen, setModalDetailOpen] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState(null);

  const [profile, setProfile] = useState({
    docNom: "",
    docEsp: "",
    docCed: "",
    docExe: "",
    docProv: "",
    docNac: "",
  });

  // Pantalla de Carga
  const [isLoading, setIsLoading] = useState(false); // Estado de carga

  const [profileData, setProfileData] = useState(null);
  const [calendarData, setCalendarData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  // Encapsular Recetas
  const [loteReceta, setLoteReceta] = useState({});
  const [fechaConsultaSeleccionada, setFechaConsultaSeleccionada] = useState(null);


  const [medicamentos, setMedicamentos] = useState([]);

  const [recetaFecha, setRecetaFecha] = useState({});

  const [selectedConsulta, setSelectedConsulta] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchProfile();
    fetchData();
    fetchCalendar();
  }, [paciente, isAccordionOpen, pcteId, consultId]);



  const fetchData = async () => {
    try {

      setIsLoading(true);
      if (!paciente || !paciente.pcteId) {
        console.error("Error: El paciente no tiene un ID definido.");
        return;
      }

      const [consultPcteResponse, tappctesResponse, recetasResponse] =
        await Promise.all([
          medicGeekApi.get(`/consults/by-paciente/${paciente.pcteId}`),
          medicGeekApi.get(`/tappctes/by-paciente/${paciente.pcteId}`),
          medicGeekApi.get(`/recetas`),
        ]);

      const consultData =  consultPcteResponse.data;
      const tappctesData =  tappctesResponse.data;
      const recetasData =  recetasResponse.data;

      // Filtrar consultas del paciente seleccionado
      const consultasPaciente = consultData.filter(
        (consulta) => Number(consulta.pcteId) === Number(paciente.pcteId)
      );

      const sortedConsults = consultasPaciente.sort((a, b) => b.consultId - a.consultId);
      setConsultas(sortedConsults);

      if (sortedConsults.length === 0) {
        console.warn("No se encontraron consultas para este paciente.");
      }

      const sortedtappctes = tappctesData.sort((a, b) => b.consultId - a.consultId);
      setAntecedentes(sortedtappctes);

      // Filtrar y agrupar recetas por consultId
      const recetasPaciente = recetasData.filter(
        (receta) =>
          sortedConsults.some(
            (consulta) => Number(consulta.consultId) === Number(receta.consultId) // Cambia a consulta.consultId
          )
      );
      
      // Agrupar recetas por consultId
      const recetasPorConsultId = groupRecetasByConsultId(recetasPaciente);
      
      // Convertir el objeto a un array y ordenar por consultId descendente
      const sortedRecetas = Object.entries(recetasPorConsultId)
        .sort((a, b) => b[0] - a[0])
        .map((entry) => entry[1])
        .flat();

      setRecetas(recetasPorConsultId);
      setSortedRecetas(sortedRecetas);
      
      const displayedRecetas = sortedRecetas.map((receta) => {
        const medicId = receta.medicId;
        const medicNm = receta.medicNm;
        return { medicId, medicNm };
      });

      if (parentDivRef.current) {
        setParentDivHeight(`${parentDivRef.current.scrollHeight}px`);
      }

      if (consultData.length > 0 && tappctesData.length > 0) {
        const ultimaConsulta = consultData[consultData.length - 1];
        const ultimoTappctes = tappctesData[tappctesData.length - 1];
  
        
        
        // setDisplayedRecetas(displayedRecetas);
        setConsultClaDiag(ultimaConsulta.consultClaDiag);
        setConsultTipDiag(ultimaConsulta.consultTipDiag);
        setConsultDiag(ultimaConsulta.consultDiag);
        setCauseId(ultimaConsulta.causeId || "");
        setCategoryId(ultimaConsulta.categoryId || "");
  
        setTappctesData({
          checke1: ultimoTappctes.checke1,
          checke2: ultimoTappctes.checke2,
          checke3: ultimoTappctes.checke3,
          checke4: ultimoTappctes.checke4,
          checke5: ultimoTappctes.checke5,
          checke6: ultimoTappctes.checke6,
          checke7: ultimoTappctes.checke7,
          checke8: ultimoTappctes.checke8,
          checke9: ultimoTappctes.checke9,
          checke10: ultimoTappctes.checke10,
          checke11: ultimoTappctes.checke11,
          checke12: ultimoTappctes.checke12,
          checke13: ultimoTappctes.checke13,
          checke14: ultimoTappctes.checke14,
          checke15: ultimoTappctes.checke15,
          antOtros: ultimoTappctes.antOtros,
          mquirur: ultimoTappctes.mquirur,
          malergi: ultimoTappctes.malergi,
          mtrauma: ultimoTappctes.mtrauma,
          mtransf: ultimoTappctes.mtransf,
          mmadre: ultimoTappctes.mmadre,
          mpadre: ultimoTappctes.mpadre,
          mconyu: ultimoTappctes.mconyu,
          motros: ultimoTappctes.motros,
          antFarmacos: ultimoTappctes.antFarmacos,
          toxicChecke1: ultimoTappctes.toxicChecke1,
          toxicChecke2: ultimoTappctes.toxicChecke2,
          toxicChecke3: ultimoTappctes.toxicChecke3,
          toxicOtros: ultimoTappctes.toxicOtros,
        });

      } else {
        console.error("No hay consultas o antecedentes patológicos disponibles.");
      }
    } catch (error) {
      console.error("Error al recuperar las consultas:", error.message);
    }
    finally {
      setIsLoading(false); // Ocultar pantalla de carga cuando termine
    }
  };

  const fetchProfile = async () => {
    try {
      // Obtener el token desde localStorage
      const token = localStorage.getItem("token");
      if (token) {
        // Decodificar el token para obtener el nombre de usuario
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username; // Asegúrate de que 'username' sea correcto

        if (username) {
          // Buscar perfil por nombre de usuario
          const response = await medicGeekApi.get(`/profile/user/${username}`);
          const data = response.data;

          // Verificar si se recibió el perfil
          if (data) {
            setProfileData(data);
            setIsEditing(true);
          } else {
            console.error("No se encontró el perfil del usuario.");
          }
        } else {
          console.error("No se pudo obtener el nombre de usuario del token.");
        }
      } else {
        console.error("No se encontró el token.");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchCalendar = async () => {
    try {
      // Obtener el token desde localStorage
      const token = localStorage.getItem("token");
      if (token) {
        // Decodificar el token para obtener el nombre de usuario
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username; // Asegúrate de que 'username' sea correcto

        if (username) {
          // Buscar perfil por nombre de usuario
          const response = await medicGeekApi.get(`/calendars/user/${username}`);
          const data = response.data;

          // Verificar si se recibió el perfil
          if (data) {
            setCalendarData(data);
            setIsEditing(true);
          } else {
            console.error("No se encontró la Cita del usuario.");
          }
        } else {
          console.error("No se pudo obtener el nombre de usuario del token.");
        }
      } else {
        console.error("No se encontró el token.");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    // console.log("Datos actuales de tappctesData en el renderizado: ", tappctesData);
    // console.log("Tiene antecedentes: ", tieneAntecedentes());
  }, [tappctesData]);
  

  const handleVerClick = (tappcte) => {
    setSelectedTappcte(tappcte);
    toggleModalDetal(); // Abre el modal
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleTabLab = (tab) => {
    if (activeTabLab !== tab) setActiveTabLab(tab);
  };

  const toggleTabDetal = (tab) => {
    if (activeTabDetal !== tab) setActiveTabDetal(tab);
  };

  const toggleModalDetal = (tappctesId) => {
    const tappcteSeleccionado = displayedTappctes.find(
      (tappcte) => tappcte.tappctesId === tappctesId
    );
    if (tappcteSeleccionado) {
      const consultaRelacionada = consultas.find(
        (consulta) => Number(consulta.consultId) === Number(tappcteSeleccionado.consultId)
      );
      const fechaConsulta = consultaRelacionada
        ? consultaRelacionada.consultFec
        : "Sin fecha";
      setSelectedTappcte(tappcteSeleccionado);
      setConsultaDetalleRelacionada(consultaRelacionada);
      setFechaConsulta(fechaConsulta);
      setModalOpenDetal(!modalOpenDetal);
    } else {
      // console.error("No se encontró el tappcte seleccionado.");
      setModalOpenDetal(false);
    }
    // console.log("Consulta relacionada:", consultaDetalleRelacionada);
    // console.log('Datos del tappcte seleccionado:', selectedTappcte);
  };
  
  // Modal
  const handleVerRecetaClick = (receta) => {
    setSelectedReceta(receta); // Actualizas la receta seleccionada
    setModalRecetaOpen(true); // Abres el modal
  
    // Busca la consulta relacionada según el `consultId` de la receta seleccionada
    const consultaRelacionada = consultas.find(
      (consulta) => Number(consulta.consultId) === Number(receta[0].consultId)
    );
  
    // Si se encuentra la consulta relacionada, actualiza la fecha, de lo contrario, coloca "Sin fecha"
    const fechaConsulta = consultaRelacionada
  ? consultaRelacionada.consultFec.split(" ")[0]
  : "Sin fecha";
    setFechaConsultaSeleccionada(fechaConsulta); // Actualizas el estado con la fecha de la consulta
  };
  
  // Tab y modal para detalle de la consulta
  const handleOpenDetailModal = (tappcte) => {
    setSelectedDetail(tappcte);
    setModalDetailOpen(true);
  };

  const handleCloseDetailModal = () => {
    setSelectedDetail(null);
    setModalDetailOpen(false);
  };

  // Modal para el detalle de las recetas
  const handleCloseRecetaModal = () => {
    setSelectedReceta(null);
    setModalRecetaOpen(false);
  };

  // Sixe
  const handleToggleSize = () => {
    setExpanded(!expanded);
  };

  // Nueva función para manejar el cambio de página
  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const groupRecetasByConsultId = (recetasData) => {
    const recetasPorConsultId = {};

    // Iterar sobre cada receta
    recetasData.forEach((receta) => {
      // Obtener el consultId de la receta
      const consultId = receta.consultId;

      // Si ya existe una entrada para este consultId, agregar la receta a la lista existente
      if (recetasPorConsultId[consultId]) {
        recetasPorConsultId[consultId].push(receta);
      } else {
        // Si no existe una entrada para este consultId, crear una nueva lista con esta receta
        recetasPorConsultId[consultId] = [receta];
      }
    });

    return recetasPorConsultId;
  };

  // Consultas para los Listados
  const displayedConsultas = consultas.slice(
    pageNumber * consultasPerPage,
    (pageNumber + 1) * consultasPerPage
  );

  const displayedTappctes = antecedentes.slice(
    pageNumber * antecedentesPerPage,
    (pageNumber + 1) * antecedentesPerPage
  );

  const startIdx = pageNumber * recetasPerPage;
  const endIdx = startIdx + recetasPerPage;
  const displayedRecetas = Object.values(recetas).slice(startIdx, endIdx);

  const getCategoryName = (categoryId, categoryData) => {
    const categoriaSeleccionada = categoryData.find(
      (item) => item.categoryId === categoryId
    );
    return categoriaSeleccionada
      ? categoriaSeleccionada.categoryNm
      : "Categoría no encontrada";
  };

  // Mostrar antecedentes
  const tieneAntecedentes = () => {
    if (!tappctesData) {
      // console.log("tappctesData es undefined o null");
      return false;
    }
  
    const antecedentes = [
      tappctesData.checke1,
      tappctesData.checke2,
      tappctesData.checke3,
      tappctesData.checke4,
      tappctesData.checke5,
      tappctesData.checke6,
      tappctesData.checke7,
      tappctesData.checke8,
      tappctesData.checke9,
      tappctesData.checke10,
      tappctesData.checke11,
      tappctesData.checke12,
      tappctesData.checke13,
      tappctesData.checke14,
      tappctesData.checke15,
    ];
  
    const result = antecedentes.some((antecedente) => antecedente);
    // console.log("Datos de antecedentes evaluados: ", antecedentes);
    // console.log("Tiene antecedentes: ", result);
    return result;
  };
  
  const handleCloseForm = () => {
    setShowForm(false);
  };

  const cargarUltimaConsulta = async () => {
    try {
      const response = await medicGeekApi.get("/consults");
      const consultas = response.data;

      const ultimoConsultId =
        consultas.length > 0 ? consultas[consultas.length - 1].consultId : 0;
      const nuevaConsult = parseInt(ultimoConsultId, 10) + 1;

      setUltimaConsulta(nuevaConsult);

      // Devolver nuevaConsult para que pueda ser usado después de la resolución de la promesa
      return nuevaConsult;
    } catch (error) {
      console.log(error);
      throw error; // Lanzar el error para que pueda ser capturado en la función que llama
    }
  };

  const handleEnviarConsultaClick = async (paciente) => {
    try {
      setSelectedPaciente(paciente);
      setCurrentForm("consultaForm");
    } catch (error) {
      console.error("Error al cargar la última consulta:", error);
    }
  };

  const formatearFechaCitaReport = (fecha) => {
    const opciones = { day: "numeric", month: "long", year: "numeric" };
    return new Intl.DateTimeFormat("es-ES", opciones).format(fecha);
  };

  const convertirFechaLarga = (fechaStr, horaStr) => {
    const opcionesFecha = { day: "numeric", month: "long", year: "numeric" };
    const opcionesHora = { hour: "numeric", minute: "numeric", hour12: true };

    if (!fechaStr || !horaStr) {
      return ""; // Retorna una cadena vacía si no hay fecha u hora
    }

    // Dividir la fecha y hora correctamente
    const [year, month, day] = fechaStr.split("-");
    const [hour, minute] = horaStr.split(":");

    // Crear una instancia de Date
    const fecha = new Date(year, month - 1, day, hour, minute);

    // Verificar si la fecha es válida
    if (isNaN(fecha.getTime())) {
      return "Fecha inválida"; // Manejar el error de fecha inválida
    }

    // Formatear la fecha
    const fechaLarga = new Intl.DateTimeFormat("es-ES", opcionesFecha).format(
      fecha
    );

    // Formatear la hora en formato AM/PM
    const horaLarga = new Intl.DateTimeFormat("es-ES", opcionesHora).format(
      fecha
    );

    const partesFecha = fechaLarga.split(" de ");
    const dia = partesFecha[0];
    const mes = partesFecha[1];
    const año = partesFecha[2];

    return `día ${dia} del mes de ${mes} del año ${año} a las ${horaLarga}`;
  };

  const fechaStr = "6/6/2024 9:44 AM";
  const fechaCitaLarga = convertirFechaLarga(fechaStr);

  // Imprimir receta
  const previsualizarPDF = () => {
    const doc = new jsPDF();

    // Configurar la página
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 10;
    const lineHeight = 5;
    let y = margin; // Posición inicial Y
    const paddingSuperior = 30;
    const medicamentosMaxHeight = 80;

    const fechaFormateada = fechaConsultaSeleccionada || "Fecha no disponible";

  


    const agregarDatosPaciente = () => {
        y += paddingSuperior; // Agregar padding antes de los datos del paciente
        doc.setFontSize(12);
        const lineHeight = 5;
        const margin = 8;
        const resultadoMargin = 30;

        doc.text(`Paciente:`, margin, y);
        const nombreCompleto = `${paciente.pcteNom || ""} ${paciente.pcteNom2 || ""} ${paciente.pcteApe1 || ""} ${paciente.pcteApe2 || ""}`.trim();
        doc.text(nombreCompleto, resultadoMargin, y);
        y += lineHeight;

        doc.text(`Cédula:`, margin, y);
        const cedulaTexto = `${paciente.pcteNumDoc}`;
        doc.text(cedulaTexto, resultadoMargin, y);
        y += lineHeight;

        doc.text(`Fecha:`, margin, y);
        doc.text(fechaFormateada, resultadoMargin, y);
        y += lineHeight;
    };

    const agregarTextoRX = (xPos, yPos) => {
      doc.setFontSize(16); // Tamaño adecuado para "RX"
      doc.text("R", xPos, yPos);
      doc.setFontSize(12); // Reducir tamaño para "X" si es necesario
      doc.text("X", xPos + 2.5, yPos + 1.9); // Ajustar posiciones para sobreponer la "X"
      doc.setFont(undefined, "bold");
    };

    const agregarCita = () => {
      const y = pageHeight - 150;
  
      // Establecer la fuente a normal para "Cita:"
      doc.setFont(undefined, "normal");
      doc.text(`Cita: `, margin, y);
  
      // Establecer la fuente a negrita para fechaCitaLarga
      doc.setFont(undefined, "bold");
      // doc.text(fechaCitaLarga ? fechaCitaLarga : "No hay próxima cita asignada", margin + doc.getTextWidth("Cita: "), y);
  
      // Restablecer la fuente a normal si es necesario
      doc.setFont(undefined, "normal");
  };

    const agregarFirmaDoctor = () => {
      y = pageHeight - 150;
      const firmaMarginLeft = pageWidth / 2 - -45;
      doc.line(firmaMarginLeft, y, firmaMarginLeft + 60, y);
      y += lineHeight;

      const doctorName = profileData.docNom;
      const doctorNameWidth = doc.getTextWidth(doctorName);
      const nameMarginRight = 75;
      doc.text(doctorName, (pageWidth - doctorNameWidth) / 2 + nameMarginRight, y);
      y += lineHeight;

      const doctorExe = `${profileData.docExe}`;
      const doctorExeWidth = doc.getTextWidth(doctorExe);
      doc.setFontSize(10);
      doc.text(doctorExe, (pageWidth - doctorExeWidth) / 2 + nameMarginRight, y);
    };

    const agregarMedicamentos = () => {
        let totalHeight = 0;
        y += lineHeight + 8;

        selectedReceta.forEach((medicamento, index) => {
            const medicamentoTexto = `${index + 1} - ${medicamento.medicNm}`;
            const dosisTexto = medicamento.medicDosis.toUpperCase();

            const alturaMedicamento = lineHeight;
            const alturaDosis = lineHeight + 5;

            totalHeight += alturaMedicamento + alturaDosis;
            if (totalHeight > medicamentosMaxHeight) {
                agregarCita();
                agregarFirmaDoctor();
                doc.addPage();
                y = margin;
                agregarDatosPaciente();
                totalHeight = alturaMedicamento + alturaDosis;
            }

            doc.setFont(undefined, "bold");
            doc.text(medicamentoTexto, margin, y);
            y += alturaMedicamento;

            doc.setFontSize(10);
            doc.setFont(undefined, "normal");
            doc.text(dosisTexto, margin, y);
            y += alturaDosis;
            doc.setFontSize(12);
        });
    };
  

    agregarDatosPaciente();
    agregarTextoRX(margin, y + 2); // Posiciona el texto RX por encima de los medicamentos
    agregarMedicamentos();
    agregarCita();
    agregarFirmaDoctor();

     // Convertir el PDF a Blob
    const pdfBlob = doc.output('blob');

    // Crear un iframe oculto en el DOM
    let iframe = document.createElement('iframe');
    iframe.style.display = 'none';  // Mantener el iframe oculto
    document.body.appendChild(iframe);

    // Crear una URL para el blob del PDF
    const blobUrl = URL.createObjectURL(pdfBlob);

    // Cargar el PDF en el iframe
    iframe.src = blobUrl;

    // Ejecutar el diálogo de impresión cuando el PDF se haya cargado
    iframe.onload = () => {
        iframe.contentWindow.focus();
        iframe.contentWindow.print();

        // Escuchar el evento de impresión finalizada para cerrar el iframe
        iframe.contentWindow.onafterprint = () => {
            // Remover el iframe del DOM una vez que se completa la impresión
            document.body.removeChild(iframe);
            // Liberar el objeto URL creado
            URL.revokeObjectURL(blobUrl);
        };
    };
  };

  const getAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDifference = today.getMonth() - birth.getMonth();
  
    // Si el mes actual es anterior al mes de nacimiento o si es el mismo mes pero el día actual es anterior al día de nacimiento
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
  
    return age;
  };

  const age = getAge(paciente.pcteEd);

  // Función para manejar la apertura del modal con la consulta seleccionada
  const handleViewDetail = (consult) => {
    setSelectedConsulta(consult); // Establece la consulta seleccionada
    setModalOpen(true); // Abre el modal
  };
  
  // Función para cerrar el modal
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleShowModal = (consult) => {
    setSelectedConsulta(consult);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedConsulta(null);
  };


  return (
    <div className="consulta-form">
      {/* Mostrar pantalla de carga si isLoading es true */}
      {isLoading && (
        <div className="loading-screen">
          <div className="spinner"></div>
          <p>Cargando...</p>
        </div>
      )}
       {/* El contenido principal de la página */}
      {!isLoading && (
      <div class="sticky-top pt-4 border-bottom miDivConGradiente text-white px-2 rounded-bottom pb-2">
        <div className="d-flex justify-content-start">
          <div
            className="d-flex align-items-center"
            style={{
              width: "4rem",
            }}
          >
            <img
              alt="Sample"
              src="https://www.shareicon.net/data/128x128/2016/08/05/806962_user_512x512.png"
              style={{
                width: "100%",
                // marginTop:'1rem'
              }}
            />
          </div>
          <div className="input-group mx-2 input-group-sm text-uppercase fw-bold d-flex align-items-center">
            <label className="border-0 fs-4" id="pcteNom">
              {paciente.pcteNom} {paciente.pcteNom2} {paciente.pcteApe1}{" "}
              {paciente.pcteApe2}
            </label>
          </div>
        </div>
        <div class="d-flex bd-highlight mb-3">
          <div className="d-flex justify-content-start">
            <label className="border-0 fs-4 m-1" id="pcteEd">
              {paciente.pcteEd} <div class="vr"></div>
            </label>
            <label className="border-0 fs-4 m-1" id="pcteEd">
              {age} años <div class="vr"></div>
            </label>
            <label className="border-0 fs-4 m-1" id="pcteNumDoc">
              {paciente.pcteNumDoc} <div class="vr"></div>
            </label>
            <label className="border-0 fs-4 m-1" id="pcteSx">
              {paciente.pcteSx}
            </label>
          </div>

          <div
            className="ms-auto cursor-pointer d-flex justify-content-end"
            style={{
              margin: "0 0",
              width: "36rem",
              height: "2rem",
            }}
          >
            <Link
              to={`/patients/${paciente.pcteId}/encounters/${ultimaConsulta}`}
            >
              <button
                className="fs-6 text-uppercase fw-bold bg-danger text-white border border-0 rounded px-3"
                style={{
                  height: "3rem",
                }}
                placeholder="Nueva Consulta"
                onClick={() => handleEnviarConsultaClick(paciente)}
              >
                <i className="bi bi-file-earmark-text px-2"></i>
                Nueva Consulta
              </button>
            </Link>
          </div>
        </div>
      </div>
       )}
      {/* Banner Superior */}
      <div className="px-3 pb-5 pt-2 d-flex">
        {/* Consulta */}
        <div
          className="mx-2"
          style={{
            width: "100%",
          }}
        >
          <Row>
            <Form
              d-flex
              justify-content-end
              className="bg-white pt-2 rounded-2"
            >
              <div
                className={`mb-5 `}
                style={{ height: expanded ? "30rem" : "15rem" }}
              >
                <div
                  className={`row bg-light mb-1 mx-1 border border-light px-2 pt-2 rounded col ${
                    expanded ? "transicionWipeButton" : ""
                  }`}
                  style={{
                    height: "90%",
                  }}
                >
                  <div className="col-6">
                    <Form>
                      <div
                        className="d-flex flex-column align-items-start py-2"
                        style={{
                          height: "7rem",
                        }}
                      >
                        <div className="fw-bold fs-6 ">
                          <label className="text-decoration-underline">
                            {" "}
                            Antecedentes Patológicos
                          </label>
                        </div>

                        <div className="">
                          {(() => {
                            const tieneAntecedentesResult = tieneAntecedentes();
                            // console.log("Tiene antecedentes: ", tieneAntecedentesResult);  // Agregar el log aquí
                            return tieneAntecedentesResult;
                          })() ? (
                            // Mostrar antecedentes como tags
                            <div className="d-flex flex-wrap align-items-center gap-1 py-1">
                              <span className={`badge bg-primary rounded-pill me-2 ${
                                tappctesData.checke1 ? "d-flex" : "d-none"}`}>
                                Cardiopatias
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke2 ? "d-flex" : "d-none"
                                }`}
                              >
                                Diabletes
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke3 ? "d-flex" : "d-none"
                                }`}
                              >
                                Enfermedades Infecciosas
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke4 ? "d-flex" : "d-none"
                                }`}
                              >
                                HTA
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke5 ? "d-flex" : "d-none"
                                }`}
                              >
                                Infertilidad
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke6 ? "d-flex" : "d-none"
                                }`}
                              >
                                Edampsia
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke7 ? "d-flex" : "d-none"
                                }`}
                              >
                                Alergias
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke8 ? "d-flex" : "d-none"
                                }`}
                              >
                                Asma Bronquial
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke9 ? "d-flex" : "d-none"
                                }`}
                              >
                                Cirugias
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke10 ? "d-flex" : "d-none"
                                }`}
                              >
                                Ulceras
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke11 ? "d-flex" : "d-none"
                                }`}
                              >
                                Epilepsia
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke12 ? "d-flex" : "d-none"
                                }`}
                              >
                                Neoplasias
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke13 ? "d-flex" : "d-none"
                                }`}
                              >
                                Preeclamsia
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke14 ? "d-flex" : "d-none"
                                }`}
                              >
                                VIH/SIDA
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke15 ? "d-flex" : "d-none"
                                }`}
                              >
                                Ninguno
                              </span>
                              {/* ... agregar el resto de los antecedentes */}
                            </div>
                          ) : (
                            // Mostrar mensaje si no hay antecedentes
                            <label>
                              No tiene ningun antecedente Registrado
                            </label>
                          )}
                        </div>
                      </div>

                      <div
                        className="d-flex flex-direction-column align-items-start"
                        style={{
                          height: "7rem",
                        }}
                      >
                        <div
                          className=""
                          style={{
                            width: "30%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "0.5rem",
                          }}
                        >
                          <label className="fw-bold text-decoration-underline">
                            Seguridad social:{" "}
                          </label>
                          <label className="fw-bold">
                            Nombre del Paciente:{" "}
                          </label>
                        </div>

                        <div
                          className=""
                          style={{
                            width: "70%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "0.5rem",
                          }}
                        >
                          <br></br>
                          <span>
                            {paciente.pcteNom} {paciente.pcteNom2}{" "}
                            {paciente.pcteApe1} {paciente.pcteApe2}{" "}
                          </span>
                        </div>
                      </div>
                    </Form>
                  </div>
                  {/* Antecedentes Toxicologicos */}

                  <div className="col-6">
                    <div
                      className="d-flex flex-column align-items-start py-2"
                      style={{
                        height: "7rem",
                      }}
                    >
                      <div className=" fw-bold fs-6">
                        <label className="text-decoration-underline">
                          {" "}
                          Antecedentes Alérgicos
                        </label>
                      </div>
                      <div className="">
                        <label>Datos de los antecedentes</label>
                      </div>
                    </div>
                  </div>
                  {/* Antecedentes Alergicos */}

                  <div
                    className={`mb-3 col-12`}
                    style={{
                      height: expanded ? "30rem" : "15rem",
                      visibility: expanded ? "visible" : "hidden",
                    }}
                  >
                    <Form>
                      <div className="text-start fw-bold fs-6 mb-3">
                        <label className="text-decoration-underline">
                          Información complementaria
                        </label>
                      </div>

                      <div className="d-flex align-items-start">
                        <div
                          className="fw-bold"
                          style={{
                            width: "15%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "0.5rem",
                            textColor: "#gris-claro",
                          }}
                        >
                          <label>Género: </label>
                          <label>País: </label>
                          <label>Provincia: </label>
                          <label>Ciudad: </label>
                        </div>

                        <div
                          className=""
                          style={{
                            width: "30%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "0.5rem",
                          }}
                        >
                          <span>{paciente.pcteSx} </span>
                          <span>{paciente.pcteNac}</span>
                          <span>{paciente.pcteProv}</span>
                          <span>{paciente.pcteCiud}</span>
                        </div>
                      </div>
                      {/* Informacion complementaria */}
                    </Form>
                  </div>
                  {/* Información complementaria
                   */}
                </div>
                <div
                  className="col my-2"
                  style={{
                    height: "10%",
                  }}
                >
                  <button
                    className="rounded-2 border-0 bg-light text-primary bg-opacity-25"
                    type="button"
                    onClick={handleToggleSize}
                  >
                    {expanded
                      ? "Ocultar Información"
                      : "Conoce más Información del paciente"}
                    <i
                      className={`bi bi-arrow-${expanded ? "up" : "down"}`}
                    ></i>
                  </button>
                </div>
              </div>
              {/* Informacion del Paciente */}
              <div className="fs-4 fw-bold d-flex justify-content-star my-4 text-decoration-underline">
                <Label>Historial Clínico</Label>
              </div>
              <div>
                <Row>
                  <Form
                    d-flex
                    justify-content-end
                    style={{
                      marginBottom: "1rem",
                    }}
                  >
                    <Col md={12} className="border border-0 text-uppercase">
                      <Nav tabs className="text-uppercase">
                        <NavItem style={{ cursor: "pointer" }}>
                          <NavLink
                            className={activeTab === "1" ? "active" : ""}
                            onClick={() => toggleTab("1")}
                          >
                            Atenciones
                          </NavLink>
                        </NavItem>
                        <NavItem style={{ cursor: "pointer" }}>
                          <NavLink
                            className={activeTab === "2" ? "active" : ""}
                            onClick={() => toggleTab("2")}
                          >
                            Otra Información
                          </NavLink>
                        </NavItem>
                        <NavItem style={{ cursor: "pointer" }}>
                          <NavLink
                            className={activeTab === "3" ? "active" : ""}
                            onClick={() => toggleTab("3")}
                          >
                            Recetas
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent
                        activeTab={activeTab}
                        style={{
                          backgroundColor: "aliceblue",
                        }}
                      >
                        <TabPane tabId="1">
                          <div className="">
                            {" "}
                            <div
                              className="d-flex align-content-start flex-wrap gap-3 text-secondary tabGb"
                              style={{
                                height: "50rem",
                                flexDirection: "column",
                                padding: "1.5rem",
                                textAlign: "left",
                              }}
                            >
                              <div
                                className="mb-5"
                                style={{
                                  height: "35rem",
                                  width: "100%",
                                }}
                              >
                                <table className="table table-striped table-hover ">
                                  <thead>
                                    <tr>
                                      <th>No. Expediente</th>
                                      <th>Fecha de consulta</th>
                                      <th>Atención</th>
                                      <th>Motivo</th>
                                      <th className="text-center">Acción</th>
                                    </tr>
                                  </thead>
                                  <tbody className="text-start">
                                    {displayedConsultas.map((consult) => (
                                      <tr key={consult.consultId}>
                                        <td style={{ width: "9%" }}>
                                          Exp-{consult.consultId}
                                        </td>
                                        <td style={{ width: "15%" }}>
                                          {consult.consultFec}
                                        </td>
                                        <td style={{ width: "12%" }}>
                                          {consult.categoryId &&
                                          categoryData.length > 0
                                            ? getCategoryName(
                                                consult.categoryId,
                                                categoryData
                                              )
                                            : "Sin categoría"}
                                        </td>
                                        <td
                                          style={{ width: "30%" }}
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title={consult.consultMot}
                                        >
                                          {consult.consultMot?.length > 20
                                            ? `${consult.consultMot.slice(
                                                0,
                                                50
                                              )}...`
                                            : consult.consultMot}
                                        </td>
                                        <td style={{ width: "5%" }}>
                                          <div className="text-center">
                                            <button
                                              type="button"
                                              className="p-1 border-0"
                                              title="Visualizar"
                                              id="hoverIcon"
                                              onClick={() => handleViewDetail(consult)}
                                            >
                                              <i className="bi bi-folder2-open"></i>
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                    {displayedConsultas.length === 0 && (
                                      <tr>
                                        <td colSpan="4">
                                          No hay datos disponibles
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                               {/* Modal para mostrar detalles de la consulta */}
                              <Modal isOpen={modalOpen} toggle={toggleModal} 
                                size="lg"
                                style={{ maxWidth: "60rem" }}>
                                <ModalHeader toggle={toggleModal}>Detalle Resumido de la Consulta</ModalHeader>
                                <ModalBody>
                                  {selectedConsulta && (
                                    <div>
                                      <div className="d-flex py-2 m-1 border rounded bg-primary text-white">
                                        <div
                                          className="d-flex align-items-center px-2"
                                          style={{
                                            width: "7rem",
                                          }}
                                        >
                                          <img
                                            alt="Sample"
                                            src="https://www.shareicon.net/data/128x128/2016/08/05/806962_user_512x512.png"
                                            style={{
                                              width: "5rem",
                                              // marginTop:'1rem'
                                            }}
                                          />
                                        </div>
                                        {/* Imagen */}
                                        <div>
                                          <div>
                                            <label className="mx-1">
                                              No. Expediente:
                                            </label>
                                            <span className="fw-bold">
                                              {selectedConsulta.consultId}
                                            </span>
                                          </div>
                                          {/* Expediente */}
                                          <div>
                                            <label className="mx-1">
                                              Fecha del Antecedente:
                                            </label>
                                            <span className="fw-bold">
                                              {selectedConsulta.consultFec}
                                            </span>
                                          </div>
                                          {/* Fecha */}
                                          
                                          {/* Paciente */}
                                        </div>
                                      </div>
                                      {/* Información del paciente y consulta */}

                                      <div className="d-flex pt-3">
                                        {/* Contenido del Nav */}
                                        <div className="pe-1 d-flex flex-column" style={{ flex: 1 }}>
                                          <p className="d-flex flex-column pb-4 border-bottom fs-4"><strong className="text-decoration-underline pb-2">Motivo de Consulta:</strong> {selectedConsulta.consultMot}</p>
                                          <p><strong>Observación:</strong> {selectedConsulta.consultHea || "SIN OBSERVACIÓN"}</p>
                                          <p><strong>Tipo de Diagnóstico:</strong> {selectedConsulta.consultDiag || "SIN TIPO DE DIAGNÓSTICO"}</p>
                                          <p><strong>Clasificación de Diagnóstico:</strong> {selectedConsulta.consultClaDiag || "SIN CLASIFICACIÓN DE DIAGNÓSTICO"}</p>
                                          <p><strong>Cie-10:</strong> {selectedConsulta.codeCie10 || "SIN ENFERMEDAD"}</p>
                                          <p><strong>Estudio de Gabinete:</strong> {selectedConsulta.estGabinete || "SIN ESTUDIO DE GABINETE"}</p>
                                          <p><strong>Resultado de Laboratorio:</strong> {selectedConsulta.resultLab || "SIN RESULTADO DE LAB."}</p>
                                        </div>
                                        {/* Contenido del TabContent */}
                                      </div>
                                    </div>
                                  )}
                                  
                                </ModalBody>
                                <ModalFooter>
                                  <Button color="secondary" onClick={toggleModal}>
                                    Cerrar
                                  </Button>
                                </ModalFooter>
                              </Modal>
                              {/* Listado */}

                              <div className="d-flex justify-content-center gap-2">
                                <div
                                  className="d-flex"
                                  style={{
                                    width: "",
                                  }}
                                >
                                  <ReactPaginate
                                    pageCount={pageCountConsult}
                                    pageRangeDisplayed={5}
                                    marginPagesDisplayed={4}
                                    onPageChange={handlePageChange}
                                    containerClassName={"pagination"}
                                    activeClassName={"pagination__link--active"}
                                    previousLabel={"<<"}
                                    nextLabel={">>"}
                                    breakClassName={"break-me"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"pagination__link"}
                                    previousClassName={
                                      "previous pagination__arrow"
                                    }
                                    nextClassName={"next pagination__arrow"}
                                    pageLinkRenderer={({
                                      page,
                                      onPageClick,
                                    }) => (
                                      <a
                                        key={page}
                                        href="!#"
                                        onClick={onPageClick}
                                        className={"page-link"}
                                      >
                                        {page}
                                      </a>
                                    )}
                                  />
                                </div>
                                {/* Cantidad */}
                                <div className="d-flex">
                                  <label className="">
                                    Total atenciones: {consultas.length}{" "}
                                  </label>
                                </div>
                                {/* Contador */}
                              </div>
                            </div>
                          </div>
                        </TabPane>

                        <TabPane tabId="2">
                          <div className="">
                            {" "}
                            <div
                              className="d-flex align-content-start flex-wrap gap-3 text-secondary tabGb"
                              style={{
                                height: "50rem",
                                flexDirection: "column",
                                padding: "1.5rem",
                                textAlign: "left",
                              }}
                            >
                              <div
                                className="mb-5"
                                style={{
                                  height: "35rem",
                                  width: "100%",
                                }}
                              >
                                <table className="table table-striped table-hover">
                                  <thead>
                                    <tr>
                                      <th>No. Expediente</th>
                                      <th>Fecha de antecedente</th>
                                      <th>Diagnóstico</th>
                                      <th className="text-center">Tipo</th>
                                      <th className="text-center">Acción</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {displayedTappctes.map((tappcte) => {
                                      const consultaRelacionada =
                                        consultas.find(
                                          (consulta) =>
                                            consulta.consultId ===
                                            tappcte.consultId
                                        );
                                      const fechaConsulta = consultaRelacionada
                                        ? consultaRelacionada.consultFec
                                        : "Sin fecha";
                                      const toxicidad = tappcte.toxicChecke1
                                        ? "Drogas"
                                        : tappcte.toxicChecke2
                                        ? "Tabaquismo"
                                        : tappcte.toxicChecke3
                                        ? "Consumo Alcohol"
                                        : "Sin toxicidad";

                                      return (
                                        <tr key={tappcte.tappctesId}>
                                          <td style={{ width: "9%" }}>
                                            Exp-{tappcte.consultId}
                                          </td>
                                          <td style={{ width: "12%" }}>
                                            {fechaConsulta}
                                          </td>
                                          <td
                                            style={{ width: "30%" }}
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title={tappcte.antOtros}
                                          >
                                            {tappcte.antOtros?.length > 50
                                              ? `${tappcte.antOtros.slice(
                                                  0,
                                                  50
                                                )}...`
                                              : tappcte.antOtros}
                                          </td>
                                          <td
                                            className="text-center"
                                            style={{ width: "10%" }}
                                          >
                                            {toxicidad}
                                          </td>
                                          <td style={{ width: "5%" }}>
                                            <div className="text-center">
                                              <button
                                                type="button"
                                                className="p-1 border-0 button-view text-uppercase"
                                                title="Visualizar"
                                                id="hoverIcon"
                                                style={{
                                                  height: "2.5rem",
                                                  width: "8rem",
                                                }}
                                                onClick={() =>
                                                  tappcte &&
                                                  toggleModalDetal(
                                                    tappcte.tappctesId
                                                  )
                                                }
                                              >
                                                Ver
                                              </button>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                    {displayedTappctes.length === 0 && (
                                      <tr>
                                        <td colSpan="4">
                                          No hay datos disponibles
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              {/* Listado */}

                              <div className="d-flex justify-content-center gap-2">
                                <div
                                  className="d-flex"
                                  style={{
                                    width: "",
                                  }}
                                >
                                  <ReactPaginate
                                    pageCount={pageCountAntecedentes}
                                    pageRangeDisplayed={5}
                                    marginPagesDisplayed={4}
                                    onPageChange={handlePageChange}
                                    containerClassName={"pagination"}
                                    activeClassName={"pagination__link--active"}
                                    previousLabel={"<<"}
                                    nextLabel={">>"}
                                    breakClassName={"break-me"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"pagination__link"}
                                    previousClassName={
                                      "previous pagination__arrow"
                                    }
                                    nextClassName={"next pagination__arrow"}
                                    pageLinkRenderer={({
                                      page,
                                      onPageClick,
                                    }) => (
                                      <a
                                        key={page}
                                        href="!#"
                                        onClick={onPageClick}
                                        className={"page-link"}
                                      >
                                        {page}
                                      </a>
                                    )}
                                  />
                                </div>
                                {/* Cantidad */}
                                <div className="d-flex">
                                  <label className="">
                                    Total atenciones: {displayedTappctes.length}
                                  </label>
                                </div>
                                {/* Contador */}
                              </div>
                              {/* Paginación */}

                              {/* Modal */}
                              <Modal
                                isOpen={modalOpenDetal}
                                toggle={toggleModalDetal}
                                size="lg"
                                style={{ maxWidth: "75rem" }}
                              >
                                <ModalHeader toggle={toggleModalDetal}>
                                  Detalle del Expediente:{" "}
                                  <span className="fw-bold">
                                    {selectedTappcte
                                      ? `${selectedTappcte.tappctesId}`
                                      : ""}
                                  </span>
                                </ModalHeader>
                                <ModalBody>
                                  <div className="d-flex py-2 m-1 border rounded bg-primary text-white">
                                    <div
                                      className="d-flex align-items-center px-2"
                                      style={{
                                        width: "7rem",
                                      }}
                                    >
                                      <img
                                        alt="Sample"
                                        src="https://www.shareicon.net/data/128x128/2016/08/05/806962_user_512x512.png"
                                        style={{
                                          width: "5rem",
                                          // marginTop:'1rem'
                                        }}
                                      />
                                    </div>
                                    {/* Imagen */}
                                    <div>
                                      <div>
                                        <label className="mx-1">
                                          No. Expediente:
                                        </label>
                                        <span className="fw-bold">
                                          {selectedTappcte
                                            ? `${selectedTappcte.tappctesId}`
                                            : ""}
                                        </span>
                                      </div>
                                      {/* Expediente */}
                                      <div>
                                        <label className="mx-1">
                                          Fecha del Antecedente:
                                        </label>
                                        <span className="fw-bold">
                                          {fechaConsulta}
                                        </span>
                                      </div>
                                      {/* Fecha */}
                                      <div className="input-group input-group-sm d-flex align-items-center">
                                        <label className="mx-1">
                                          Paciente:{" "}
                                        </label>
                                        <label
                                          className="border-0 fw-bold"
                                          id="pcteNom"
                                        >
                                          {paciente.pcteNom} {paciente.pcteNom2}{" "}
                                          {paciente.pcteApe1}{" "}
                                          {paciente.pcteApe2}
                                        </label>
                                      </div>
                                      {/* Paciente */}
                                    </div>
                                  </div>
                                  {/* Información del paciente y consulta */}

                                  <div className="d-flex pt-3">
                                    {/* Contenido del Nav */}
                                    <div className="border-end pe-1" style={{ flex: 1 }}>
                                      <Nav
                                        tabs
                                        className="flex-column gap-3"
                                        style={{ cursor: "pointer" }}
                                      >
                                        <NavItem>
                                          <NavLink
                                            className={
                                              activeTabDetal === "1"
                                                ? "active"
                                                : ""
                                            }
                                            onClick={() => {
                                              toggleTabDetal("1");
                                            }}
                                          >
                                            Ant. Personales Patológicos
                                          </NavLink>
                                        </NavItem>
                                        <NavItem>
                                          <NavLink
                                            className={
                                              activeTabDetal === "2"
                                                ? "active"
                                                : ""
                                            }
                                            onClick={() => {
                                              toggleTabDetal("2");
                                            }}
                                          >
                                            Ant. No Patológicos & Heredo
                                            Familiares
                                          </NavLink>
                                        </NavItem>
                                        <NavItem>
                                          <NavLink
                                            className={
                                              activeTabDetal === "3"
                                                ? "active"
                                                : ""
                                            }
                                            onClick={() => {
                                              toggleTabDetal("3");
                                            }}
                                          >
                                            Toxicológicos
                                          </NavLink>
                                        </NavItem>
                                        <NavItem>
                                          <NavLink
                                            className={
                                              activeTabDetal === "4"
                                                ? "active"
                                                : ""
                                            }
                                            onClick={() => {
                                              toggleTabDetal("4");
                                            }}
                                          >
                                            Examén Físico
                                          </NavLink>
                                        </NavItem>
                                        <NavItem>
                                          <NavLink
                                            className={
                                              activeTabDetal === "5"
                                                ? "active"
                                                : ""
                                            }
                                            onClick={() => {
                                              toggleTabDetal("5");
                                            }}
                                          >
                                            Diagnóstico
                                          </NavLink>
                                        </NavItem>
                                      </Nav>
                                    </div>
                                    {/* Contenido del TabContent */}
                                    <div style={{ flex: 3 }}>
                                      <TabContent
                                        activeTab={activeTabDetal}
                                        className=""
                                        style={{
                                          height: "50rem",
                                          backgroundColor: "#fff",
                                        }}
                                      >
                                        <TabPane tabId="1">
                                          <div className="bg-white text-black d-flex flex-column">
                                            <div className=" p-2">
                                              <h4>
                                                Detalle de los antecedentes
                                                Personales Patológicos
                                              </h4>
                                            </div>
                                            <div
                                              className="p-1 "
                                              style={{ height: "40rem" }}
                                            >
                                              {selectedTappcte && (
                                                <div
                                                  className="p-3"
                                                  style={{ height: "40rem" }}
                                                >
                                                  <div className="form-check form-switch">
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      id="switch1"
                                                      disabled
                                                      checked={
                                                        selectedTappcte.checke1
                                                      }
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor="switch1"
                                                    >
                                                      Cardiopatias
                                                    </label>
                                                  </div>
                                                  <div className="form-check form-switch">
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      id="switch2"
                                                      disabled
                                                      checked={
                                                        selectedTappcte.checke2
                                                      }
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor="switch2"
                                                    >
                                                      Diabletes
                                                    </label>
                                                  </div>
                                                  <div className="form-check form-switch">
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      id="switch2"
                                                      disabled
                                                      checked={
                                                        selectedTappcte.checke3
                                                      }
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor="switch2"
                                                    >
                                                      Enfermedades Infecciosas
                                                    </label>
                                                  </div>
                                                  <div className="form-check form-switch">
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      id="switch2"
                                                      disabled
                                                      checked={
                                                        selectedTappcte.checke4
                                                      }
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor="switch2"
                                                    >
                                                      HTA
                                                    </label>
                                                  </div>
                                                  <div className="form-check form-switch">
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      id="switch2"
                                                      disabled
                                                      checked={
                                                        selectedTappcte.checke5
                                                      }
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor="switch2"
                                                    >
                                                      Infertilidad
                                                    </label>
                                                  </div>
                                                  <div className="form-check form-switch">
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      id="switch2"
                                                      disabled
                                                      checked={
                                                        selectedTappcte.checke6
                                                      }
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor="switch2"
                                                    >
                                                      Edampsia
                                                    </label>
                                                  </div>
                                                  <div className="form-check form-switch">
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      id="switch2"
                                                      disabled
                                                      checked={
                                                        selectedTappcte.checke7
                                                      }
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor="switch2"
                                                    >
                                                      Alergias
                                                    </label>
                                                  </div>
                                                  <div className="form-check form-switch">
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      id="switch2"
                                                      disabled
                                                      checked={
                                                        selectedTappcte.checke8
                                                      }
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor="switch2"
                                                    >
                                                      Asma Bronquial{" "}
                                                    </label>
                                                  </div>
                                                  <div className="form-check form-switch">
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      id="switch2"
                                                      disabled
                                                      checked={
                                                        selectedTappcte.checke9
                                                      }
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor="switch2"
                                                    >
                                                      Cirugias
                                                    </label>
                                                  </div>
                                                  <div className="form-check form-switch">
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      id="switch2"
                                                      disabled
                                                      checked={
                                                        selectedTappcte.checke10
                                                      }
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor="switch2"
                                                    >
                                                      Ulceras
                                                    </label>
                                                  </div>
                                                  <div className="form-check form-switch">
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      id="switch2"
                                                      disabled
                                                      checked={
                                                        selectedTappcte.checke11
                                                      }
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor="switch2"
                                                    >
                                                      Epilepsia
                                                    </label>
                                                  </div>
                                                  <div className="form-check form-switch">
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      id="switch2"
                                                      disabled
                                                      checked={
                                                        selectedTappcte.checke12
                                                      }
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor="switch2"
                                                    >
                                                      Neoplasias
                                                    </label>
                                                  </div>
                                                  <div className="form-check form-switch">
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      id="switch2"
                                                      disabled
                                                      checked={
                                                        selectedTappcte.checke13
                                                      }
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor="switch2"
                                                    >
                                                      Preeclamsia
                                                    </label>
                                                  </div>
                                                  <div className="form-check form-switch">
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      id="switch2"
                                                      disabled
                                                      checked={
                                                        selectedTappcte.checke14
                                                      }
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor="switch2"
                                                    >
                                                      VIH/SIDA
                                                    </label>
                                                  </div>
                                                  <div className="form-check form-switch">
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      id="switch2"
                                                      disabled
                                                      checked={
                                                        selectedTappcte.checke15
                                                      }
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor="switch2"
                                                    >
                                                      Ninguno
                                                    </label>
                                                  </div>
                                                  <div className="mt-3">
                                                    <label
                                                      htmlFor="antOtros"
                                                      className="form-label"
                                                    >
                                                      Antecedentes Otros:
                                                    </label>
                                                    <textarea
                                                      id="antOtros"
                                                      className="form-control"
                                                      value={
                                                        selectedTappcte.antOtros
                                                      }
                                                      disabled
                                                      readOnly
                                                      style={{
                                                        height: "5rem",
                                                      }}
                                                    ></textarea>
                                                    <label
                                                      htmlFor="antFarmacos"
                                                      className="form-label mt-3"
                                                    >
                                                      Antecedentes
                                                      Farmacológicos:
                                                    </label>
                                                    <textarea
                                                      id="antFarmacos"
                                                      className="form-control"
                                                      value={
                                                        selectedTappcte.antFarmacos
                                                      }
                                                      disabled
                                                      readOnly
                                                      style={{
                                                        height: "5rem",
                                                      }}
                                                    ></textarea>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </TabPane>

                                        <TabPane tabId="2">
                                          <div className="bg-white text-black d-flex flex-column">
                                            <div className="p-2">
                                              <h4>
                                                Detalle de los antecedentes No
                                                Patalógicos & Heredo Familiares
                                              </h4>
                                            </div>
                                            <div
                                              className="p-1 "
                                              style={{ height: "40rem" }}
                                            >
                                              {selectedTappcte && (
                                                <div
                                                  className="p-3 d-flex gap-3"
                                                  style={{ height: "40rem" }}
                                                >
                                                  <div
                                                    style={{
                                                      width: "50%",
                                                    }}
                                                  >
                                                    <label className="col fw-bold mb-3">
                                                      Antecedentes Personales No
                                                      Patológicos
                                                    </label>
                                                    <Col md={12}>
                                                      <FormGroup floating>
                                                        <Input
                                                          type="text"
                                                          className="form-control"
                                                          id="mquirur"
                                                          value={
                                                            selectedTappcte.mquirur
                                                          }
                                                          disabled
                                                        />
                                                        <Label For="Consulta">
                                                          Quirúrgicos
                                                        </Label>
                                                      </FormGroup>
                                                    </Col>
                                                    <Col md={12}>
                                                      <FormGroup floating>
                                                        <Input
                                                          type="text"
                                                          className="form-control"
                                                          id="malergi"
                                                          value={
                                                            selectedTappcte.malergi
                                                          }
                                                          disabled
                                                        />
                                                        <Label For="Consulta">
                                                          Alérgicos
                                                        </Label>
                                                      </FormGroup>
                                                    </Col>
                                                    <Col md={12}>
                                                      <FormGroup floating>
                                                        <Input
                                                          type="text"
                                                          className="form-control"
                                                          id="mtrauma"
                                                          value={
                                                            selectedTappcte.mtrauma
                                                          }
                                                          disabled
                                                        />
                                                        <Label For="Consulta">
                                                          Traumáticos
                                                        </Label>
                                                      </FormGroup>
                                                    </Col>
                                                    <Col md={12}>
                                                      <FormGroup floating>
                                                        <Input
                                                          type="text"
                                                          className="form-control"
                                                          id="mtransf"
                                                          value={
                                                            selectedTappcte.mtransf
                                                          }
                                                          disabled
                                                        />
                                                        <Label For="Consulta">
                                                          Transfucionales
                                                        </Label>
                                                      </FormGroup>
                                                    </Col>
                                                  </div>

                                                  <div
                                                    style={{
                                                      width: "50%",
                                                    }}
                                                  >
                                                    <label className="col fw-bold mb-3">
                                                      Antecedentes
                                                      Heredo-Familiares
                                                    </label>
                                                    <Col md={12}>
                                                      <FormGroup floating>
                                                        <Input
                                                          type="text"
                                                          className="form-control"
                                                          id="mmadre"
                                                          value={
                                                            selectedTappcte.mmadre
                                                          }
                                                          disabled
                                                        />
                                                        <Label For="Consulta">
                                                          Madre
                                                        </Label>
                                                      </FormGroup>
                                                    </Col>
                                                    <Col md={12}>
                                                      <FormGroup floating>
                                                        <Input
                                                          type="text"
                                                          className="form-control"
                                                          id="mpadre"
                                                          value={
                                                            selectedTappcte.mpadre
                                                          }
                                                          disabled
                                                        />
                                                        <Label For="Consulta">
                                                          Padre
                                                        </Label>
                                                      </FormGroup>
                                                    </Col>
                                                    <Col md={12}>
                                                      <FormGroup floating>
                                                        <Input
                                                          type="text"
                                                          className="form-control"
                                                          id="mconyu"
                                                          value={
                                                            selectedTappcte.mconyu
                                                          }
                                                          disabled
                                                        />
                                                        <Label For="Consulta">
                                                          Cónyuge
                                                        </Label>
                                                      </FormGroup>
                                                    </Col>
                                                    <Col md={12}>
                                                      <FormGroup floating>
                                                        <Input
                                                          type="text"
                                                          className="form-control"
                                                          id="motros"
                                                          value={
                                                            selectedTappcte.motros
                                                          }
                                                          disabled
                                                        />
                                                        <Label For="Consulta">
                                                          Otros
                                                        </Label>
                                                      </FormGroup>
                                                    </Col>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </TabPane>

                                        <TabPane tabId="3">
                                          <div className="bg-white text-black d-flex flex-column">
                                            <div className=" p-2">
                                              <h4>
                                                Detalle de los antecedentes
                                                Toxicológicos
                                              </h4>
                                            </div>
                                            <div
                                              className="p-1 "
                                              style={{ height: "40rem" }}
                                            >
                                              {selectedTappcte && (
                                                <div
                                                  className="p-3"
                                                  style={{ height: "40rem" }}
                                                >
                                                  <div className="d-flex flex-wrap justify-content-center gap-1 py-2 px-2">
                                                    <Col md={12}>
                                                      <FormGroup switch>
                                                        <Input
                                                          id="toxicChecke1"
                                                          type="checkbox"
                                                          checked={
                                                            selectedTappcte.toxicChecke1
                                                          }
                                                          disabled
                                                        />{" "}
                                                        <Label check>
                                                          Drogas
                                                        </Label>
                                                      </FormGroup>
                                                      <FormGroup switch>
                                                        <Input
                                                          id="toxicChecke2"
                                                          type="checkbox"
                                                          checked={
                                                            selectedTappcte.toxicChecke2
                                                          }
                                                          disabled
                                                        />{" "}
                                                        <Label check>
                                                          Tabaquismo
                                                        </Label>
                                                      </FormGroup>
                                                      <FormGroup switch>
                                                        <Input
                                                          id="toxicChecke3"
                                                          type="checkbox"
                                                          checked={
                                                            selectedTappcte.toxicChecke3
                                                          }
                                                          disabled
                                                        />{" "}
                                                        <Label check>
                                                          Consumo Alcohol
                                                        </Label>
                                                      </FormGroup>
                                                    </Col>
                                                    <Col md={12}>
                                                      <FormGroup floating>
                                                        <Input
                                                          type="textarea"
                                                          className="form-control"
                                                          id="toxicOtros"
                                                          value={
                                                            selectedTappcte.toxicOtros
                                                          }
                                                          disabled
                                                          placeholder="Otros"
                                                          style={{
                                                            height: "10rem",
                                                            marginTop: "1rem",
                                                          }}
                                                        />
                                                        <Label For="toxicOtros">
                                                          Otros (Opcional)
                                                        </Label>
                                                      </FormGroup>
                                                    </Col>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </TabPane>

                                        <TabPane tabId="4">
                                          <div className="bg-white text-black d-flex flex-column">
                                            <div className=" p-2">
                                              <h4>Detalle del Examen Físico</h4>
                                            </div>
                                            <div
                                              className="p-1"
                                              style={{
                                                height: "45rem",
                                              }}
                                            >
                                              {consultaDetalleRelacionada && (
                                                <div
                                                  className="p-3"
                                                  style={
                                                    {
                                                      // height: '40rem'
                                                    }
                                                  }
                                                >
                                                  <div
                                                    className="row"
                                                    style={{
                                                      height: "30rem",
                                                    }}
                                                  >
                                                    <div
                                                      className="d-flex flex-wrap p-0"
                                                      style={{
                                                        gap: "0.3rem",
                                                        height: "10rem",
                                                      }}
                                                    >
                                                      <Col md={2}>
                                                        <FormGroup floating>
                                                          <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="exam1"
                                                            value={
                                                              consultaDetalleRelacionada.exam1
                                                            }
                                                            disabled
                                                          />
                                                          <Label For="exam1">
                                                            F.C
                                                          </Label>
                                                        </FormGroup>
                                                      </Col>
                                                      <Col md={2}>
                                                        <FormGroup floating>
                                                          <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="exam2"
                                                            value={
                                                              consultaDetalleRelacionada.exam2
                                                            }
                                                            disabled
                                                          />
                                                          <Label For="exam2">
                                                            F.R
                                                          </Label>
                                                        </FormGroup>
                                                      </Col>
                                                      <Col md={2}>
                                                        <FormGroup floating>
                                                          <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="exam3"
                                                            value={
                                                              consultaDetalleRelacionada.exam3
                                                            }
                                                            disabled
                                                          />
                                                          <Label For="exam3">
                                                            P.ABD
                                                          </Label>
                                                        </FormGroup>
                                                      </Col>
                                                      <Col md={2}>
                                                        <FormGroup floating>
                                                          <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="exam4"
                                                            value={
                                                              consultaDetalleRelacionada.exam4
                                                            }
                                                            disabled
                                                          />
                                                          <Label For="exam4">
                                                            Temperatura
                                                          </Label>
                                                        </FormGroup>
                                                      </Col>
                                                      <Col md={2}>
                                                        <FormGroup floating>
                                                          <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="exam5"
                                                            value={
                                                              consultaDetalleRelacionada.exam5
                                                            }
                                                            disabled
                                                          />
                                                          <Label For="exam5">
                                                            Talla
                                                          </Label>
                                                        </FormGroup>
                                                      </Col>
                                                      <Col md={2}>
                                                        <FormGroup floating>
                                                          <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="exam6"
                                                            value={
                                                              consultaDetalleRelacionada.exam6
                                                            }
                                                            disabled
                                                          />
                                                          <Label For="exam6">
                                                            Peso
                                                          </Label>
                                                        </FormGroup>
                                                      </Col>
                                                      <div
                                                        className="border border-success rounded d-flex p-2 ms-2"
                                                        style={{
                                                          height: "8rem",
                                                          width: "10rem",
                                                        }}
                                                      >
                                                        <Col md={12}>
                                                          <FormGroup floating>
                                                            <Input
                                                              type="text"
                                                              className="form-control"
                                                              id="exam7"
                                                              value={
                                                                consultaDetalleRelacionada.exam7
                                                              }
                                                              disabled
                                                            />
                                                            <Label For="exam7">
                                                              IMC
                                                            </Label>
                                                          </FormGroup>
                                                        </Col>
                                                      </div>
                                                    </div>

                                                    <div
                                                      className="d-flex flex-wrap p-0"
                                                      style={{
                                                        gap: "0.3rem",
                                                        height: "10rem",
                                                      }}
                                                    >
                                                      <Col md={2}>
                                                        <FormGroup floating>
                                                          <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="exam8"
                                                            value={
                                                              consultaDetalleRelacionada.exam8
                                                            }
                                                            disabled
                                                          />
                                                          <Label For="exam8">
                                                            Corazón
                                                          </Label>
                                                        </FormGroup>
                                                      </Col>
                                                      <Col md={2}>
                                                        <FormGroup floating>
                                                          <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="exam9"
                                                            value={
                                                              consultaDetalleRelacionada.exam9
                                                            }
                                                            disabled
                                                          />
                                                          <Label For="exam9">
                                                            Cuello
                                                          </Label>
                                                        </FormGroup>
                                                      </Col>
                                                      <Col md={2}>
                                                        <FormGroup floating>
                                                          <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="exam10"
                                                            value={
                                                              consultaDetalleRelacionada.exam10
                                                            }
                                                            disabled
                                                          />
                                                          <Label For="exam10">
                                                            Pulmón
                                                          </Label>
                                                        </FormGroup>
                                                      </Col>
                                                      <Col md={2}>
                                                        <FormGroup floating>
                                                          <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="exam11"
                                                            value={
                                                              consultaDetalleRelacionada.exam11
                                                            }
                                                            disabled
                                                          />
                                                          <Label For="exam11">
                                                            M.I
                                                          </Label>
                                                        </FormGroup>
                                                      </Col>
                                                      <Col md={2}>
                                                        <FormGroup floating>
                                                          <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="exam12"
                                                            value={
                                                              consultaDetalleRelacionada.exam12
                                                            }
                                                            disabled
                                                          />
                                                          <Label For="exam12">
                                                            T.A B Izquierdo
                                                          </Label>
                                                        </FormGroup>
                                                      </Col>
                                                      <Col md={2}>
                                                        <FormGroup floating>
                                                          <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="exam13"
                                                            value={
                                                              consultaDetalleRelacionada.exam13
                                                            }
                                                            disabled
                                                          />
                                                          <Label For="exam13">
                                                            T.A B Derecho
                                                          </Label>
                                                        </FormGroup>
                                                      </Col>
                                                      <Col md={2}>
                                                        <FormGroup floating>
                                                          <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="exam14"
                                                            value={
                                                              consultaDetalleRelacionada.exam14
                                                            }
                                                            disabled
                                                          />
                                                          <Label For="exam14">
                                                            TA Decúbico
                                                          </Label>
                                                        </FormGroup>
                                                      </Col>
                                                      <Col md={2}>
                                                        <FormGroup floating>
                                                          <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="exam15"
                                                            value={
                                                              consultaDetalleRelacionada.exam15
                                                            }
                                                            disabled
                                                          />
                                                          <Label For="exam15">
                                                            Cabeza
                                                          </Label>
                                                        </FormGroup>
                                                      </Col>
                                                      <Col md={2}>
                                                        <FormGroup floating>
                                                          <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="exam16"
                                                            value={
                                                              consultaDetalleRelacionada.exam16
                                                            }
                                                            disabled
                                                          />
                                                          <Label For="exam16">
                                                            Torax
                                                          </Label>
                                                        </FormGroup>
                                                      </Col>
                                                      <Col md={2}>
                                                        <FormGroup floating>
                                                          <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="exam17"
                                                            value={
                                                              consultaDetalleRelacionada.exam17
                                                            }
                                                            disabled
                                                          />
                                                          <Label For="exam17">
                                                            Abdomen
                                                          </Label>
                                                        </FormGroup>
                                                      </Col>
                                                      <Col md={2}>
                                                        <FormGroup floating>
                                                          <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="exam18"
                                                            value={
                                                              consultaDetalleRelacionada.exam18
                                                            }
                                                            disabled
                                                          />
                                                          <Label For="exam18">
                                                            Especuloscopia
                                                          </Label>
                                                        </FormGroup>
                                                      </Col>
                                                      <Col md={6}>
                                                        <FormGroup floating>
                                                          <Input
                                                            type="textarea"
                                                            className="form-control"
                                                            id="examComentario"
                                                            value={
                                                              consultaDetalleRelacionada.examComentario
                                                            }
                                                            disabled
                                                            step={{
                                                              height: "10rem",
                                                            }}
                                                          />
                                                          <Label For="examComentario">
                                                            Comentario
                                                          </Label>
                                                        </FormGroup>
                                                      </Col>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </TabPane>

                                        <TabPane tabId="5">
                                          <div className="bg-white text-black d-flex flex-column">
                                            <div className="p-2">
                                              <h4>
                                                Detalle del Diagnóstico
                                              </h4>
                                            </div>
                                            <div
                                              className="p-1 "
                                              style={{ height: "40rem" }}
                                            >
                                              {selectedTappcte && (
                                                <div
                                                  className="p-3 d-flex gap-3"
                                                  style={{ height: "40rem" }}
                                                >
                                                  <div
                                                    style={{
                                                      width: "100%",
                                                    }}
                                                  >
                                                   <div class="accordion-body row">
                                                   <div className="" style={{ width: "100%" }}>
                                                   <FormGroup>
                                                    <Input
                                                      type="text"
                                                      className="form-control"
                                                      id="descriptionCie10"
                                                      value={(consultaDetalleRelacionada && consultaDetalleRelacionada.codeCie10) || ""}
                                                      placeholder="Diagnóstico principal (CIE-10)"
                                                      disabled={true}
                                                    />
                                                  </FormGroup>

                                                    </div>
                                                      <Col md={6}>
                                                        <FormGroup floating>
                                                          <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="consultClaDiag"
                                                            value={(consultaDetalleRelacionada && consultaDetalleRelacionada.consultClaDiag) || ""}
                                                            placeholder="Clasificación de Diagnóstico"
                                                            disabled={true}
                                                          >
                                                          </Input>
                                                          
                                                          <Label For="Causa Externa">
                                                            Clasificación de diagnóstico
                                                            <span className="text-danger fw-bold">*</span>
                                                          </Label>
                                                        </FormGroup>
                                                      </Col>
                                                      <Col md={6}>
                                                        <FormGroup floating>
                                                          <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="consultTipDiag"
                                                            value={(consultaDetalleRelacionada && consultaDetalleRelacionada.consultTipDiag) || ""}
                                                            placeholder="Caso Externo"
                                                            disabled={true}
                                                          >
                                                          </Input>
                                                          <Label For="Causa Externa">
                                                            Tipo de diagnóstico
                                                            <span className="text-danger fw-bold">*</span>
                                                          </Label>
                                                        </FormGroup>
                                                      </Col>
                                                      <Col>
                                                        <FormGroup floating>
                                                          <Input
                                                            type="textarea"
                                                            className="form-control"
                                                            id="consultDiag"
                                                            value={(consultaDetalleRelacionada && consultaDetalleRelacionada.consultDiag) || ""}
                                                            placeholder="Diagnostico"
                                                            style={{ height: "10rem" }}
                                                            disabled={true}
                                                          />
                                                          <Label For="Diagnostico">
                                                            Observaciones (Opcional)
                                                          </Label>
                                                          <p
                                                            style={{ fontSize: "12px", display: "flex" }}
                                                          >
                                                            
                                                          </p>
                                                        </FormGroup>
                                                      </Col>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </TabPane>


                                      </TabContent>
                                    </div>
                                  </div>
                                </ModalBody>
                                <ModalFooter>
                                  <Button
                                    color="secondary"
                                    onClick={toggleModalDetal}
                                  >
                                    Cerrar
                                  </Button>
                                </ModalFooter>
                              </Modal>
                            </div>
                          </div>
                        </TabPane>

                        <TabPane tabId="3">
                          <div className="">
                            {" "}
                            <div
                              className="d-flex align-content-start flex-wrap gap-3 text-secondary tabGb"
                              style={{
                                height: "50rem",
                                flexDirection: "column",
                                padding: "1.5rem",
                                textAlign: "left",
                              }}
                            >
                              <div
                                className="mb-5"
                                style={{
                                  height: "35rem",
                                  width: "100%",
                                }}
                              >
                                <table className="table table-striped table-hover">
                                  <thead>
                                    <tr>
                                      <th>No. Expediente</th>
                                      <th>Fecha de consulta</th>
                                      <th>Medicamentos</th>
                                      <th className="text-center">Acción</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {displayedRecetas &&
                                    displayedRecetas.length > 0 ? (
                                      displayedRecetas.map(
                                        (recetaGroup, index) => {
                                          const consultIdReceta =
                                          Number(recetaGroup[0].consultId);
                                          const consultaRelacionada =
                                            consultas.find(
                                              (consulta) =>
                                                Number(consulta.consultId) ===
                                                consultIdReceta
                                            );
                                          const nombresMedicamentos =
                                            recetaGroup
                                              .map((item) => item.medicNm)
                                              .join(", ");
                                          const fechaConsulta =
                                            consultaRelacionada
                                              ? consultaRelacionada.consultFec
                                              : "Sin fecha";

                                          return (
                                            <tr key={consultIdReceta}>
                                              <td style={{ width: "5%" }}>
                                                Exp-{consultIdReceta}
                                              </td>
                                              <td style={{ width: "10%" }}>
                                                {fechaConsulta}
                                              </td>
                                              <td
                                                style={{ width: "20%" }}
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                title={nombresMedicamentos}
                                              >
                                                {nombresMedicamentos.length > 20
                                                  ? `${nombresMedicamentos.slice(
                                                      0,
                                                      50
                                                    )}...`
                                                  : nombresMedicamentos}
                                              </td>
                                              <td style={{ width: "5%" }}>
                                                <div className="text-center">
                                                  <button
                                                    type="button"
                                                    className="p-1 border-0 button-view text-uppercase"
                                                    title="Visualizar"
                                                    id="hoverIcon"
                                                    style={{
                                                      height: "2.5rem",
                                                      width: "8rem",
                                                    }}
                                                    onClick={() => {
                                                      handleVerRecetaClick(
                                                        recetaGroup
                                                      );
                                                    }}
                                                  >
                                                    Ver
                                                  </button>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    ) : (
                                      <tr>
                                        <td colSpan="4">
                                          No hay datos disponibles
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              {/* Listado */}

                              <div className="d-flex justify-content-center gap-2">
                                <div
                                  className="d-flex"
                                  style={{
                                    width: "",
                                  }}
                                >
                                  <ReactPaginate
                                    pageCount={pageCountRecetas}
                                    pageRangeDisplayed={5}
                                    marginPagesDisplayed={4}
                                    onPageChange={handlePageChange}
                                    containerClassName={"pagination"}
                                    activeClassName={"pagination__link--active"}
                                    previousLabel={"<<"}
                                    nextLabel={">>"}
                                    breakClassName={"break-me"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"pagination__link"}
                                    previousClassName={
                                      "previous pagination__arrow"
                                    }
                                    nextClassName={"next pagination__arrow"}
                                    pageLinkRenderer={({
                                      page,
                                      onPageClick,
                                    }) => (
                                      <a
                                        key={page}
                                        href="!#"
                                        onClick={onPageClick}
                                        className={"page-link"}
                                      >
                                        {page}
                                      </a>
                                    )}
                                  />
                                </div>
                                {/* Cantidad */}
                                <div className="d-flex">
                                  <label className="">
                                    Total de recetas: {displayedRecetas.length}
                                  </label>
                                </div>
                                {/* Contador */}
                              </div>
                              {/* Paginación */}

                              {/* Modal */}
                              <Modal
                                isOpen={modalRecetaOpen}
                                toggle={handleCloseRecetaModal}
                                style={{
                                  maxWidth: "50rem",
                                }}
                              >
                                <ModalHeader toggle={handleCloseRecetaModal}>
                                  Detalles de la receta
                                </ModalHeader>
                                <ModalBody>
                                  {selectedReceta && (
                                    <div className="modal-content">
                                      <div className="d-flex align-items-center justify-content-between px-1">
                                        <div>
                                          <h2 className="modal-title p-2">
                                            Datos de la Receta
                                          </h2>
                                        </div>
                                        <div>
                                          <label className="fs-6 fw-bold">
                                            Fecha de Registro <span class="text-decoration-underline"> {fechaConsultaSeleccionada}</span>
                                          </label>
                                        </div>
                                      </div>

                                      <div className="modal-body">
                                        <table className="table table-striped text-uppercase">
                                          <thead>
                                            <tr>
                                              <th>Nombre del Medicamento</th>
                                              <th>Dosis</th>
                                              <th className="text-center">
                                                Total
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {displayedRecetas && displayedRecetas.length > 0 ? (
                                              selectedReceta.map((recetaGroup, index) => {
                                                return (
                                                  <tr key={index}>
                                                    <td>{recetaGroup.medicNm}</td>
                                                    <td>{recetaGroup.medicDosis}</td>
                                                    <td>{recetaGroup.medicTotal}</td>
                                                  </tr>
                                                );
                                              })
                                            ) : (
                                              <tr>
                                                <td colSpan="4">No hay datos disponibles</td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  )}
                                </ModalBody>

                                <ModalFooter>
                                  <Button
                                    className="button-print"
                                    style={{
                                      color: "#cb05f3",
                                    }}
                                    onClick={() => {
                                      if (
                                        selectedReceta &&
                                        fechaConsultaSeleccionada
                                      ) {
                                        previsualizarPDF(
                                          selectedReceta,
                                          fechaConsultaSeleccionada
                                        );
                                      } else {
                                        console.log(
                                          "No se ha seleccionado ninguna receta o fecha de consulta."
                                        );
                                      }
                                    }}
                                  >
                                    Imprimir Receta
                                  </Button>
                                  <Button
                                    className="button-cancel text-secondary"
                                    onClick={handleCloseRecetaModal}
                                  >
                                    Cerrar
                                  </Button>
                                </ModalFooter>
                              </Modal>
                            </div>
                          </div>
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Form>
                </Row>
              </div>
              {/* Tabs de Historico */}
            </Form>
          </Row>
        </div>
        {/* Consulta */}
      </div>
      {currentForm === "consultaForm" && selected && paciente && formulario && (
        <div className="main-content">
          <ConsultaForm
            onCloseForm={handleCloseForm}
            paciente={paciente}
            formulario={formulario}
            setCurrentForm={setCurrentForm}
          />
        </div>
      )}
    </div>
  );
};
export default PatientsHistory;
