import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  showErrorAlert,
  showSuccessAlert,
  showSuccessEditAlert,
} from "../../../components/alerts";
import medicGeekApi from "../../../config/axiosConfig";

const ConceptosList = ({ currentForm }) => {
  const [conceptos, setConceptos] = useState([]);
  const [showForm] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const conceptosPerPage = 10;
  const [selectedConceptoLocal, setSelectedConceptoLocal] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  // Skeleton
  const [loading, setLoading] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);

  const [formulario, setFormulario] = useState({
    nombreConceptos: "",
    descripcionConceptos: "",
    estatusConceptos: "activo",
  });

  const openModal = (concepto) => {
    setSelectedConceptoLocal(concepto); // Configura el concepto seleccionado para la edición

    if (concepto) {
      setIsEditing(true); // Establecer como modo edición cuando se abre el modal
      setFormulario({
        nombreConcepto: concepto.conceptoNm,
        descripcionConcepto: concepto.conceptoDesc,
        estatusConcepto: concepto.estatus ? "activo" : "inactivo",
      });
    } else {
      setIsEditing(false); // Establecer como modo creación cuando se abre el modal para crear uno nuevo
      // Limpia los campos si es necesario
      setFormulario({
        nombreConcepto: "",
        descripcionConcepto: "",
        estatusConcepto: "activo",
      });
    }
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedConceptoLocal(null); // Limpia el concepto seleccionado
    setIsEditing(false); // Resetear el modo edición cuando se cierra el modal
    setModalOpen(false);
  };

  const handleSearchTermChange = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Iniciar la carga
      try {
        const response = await medicGeekApi.get(
          `/conceptos?search=${searchTerm}`,
          {
            params: {
              $or: [
                { conceptoNm: { $regex: searchTerm, $options: "i" } },
                { conceptoDesc: { $regex: searchTerm, $options: "i" } },
              ],
            },
          }
        );
        const sortedConceptos = response.data.sort(
          (a, b) => a.conceptoId - b.conceptoId
        );
        setConceptos(sortedConceptos);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false); // Finalizar carga
      }
    };

    fetchData();
  }, [searchTerm]);

  const pageCount = Math.ceil(conceptos.length / conceptosPerPage);

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const displayedConceptos = conceptos
    .filter((concepto) => {
      // Filtrar conceptos basados en el término de búsqueda
      return (
        concepto.conceptoNm.toLowerCase().includes(searchTerm.toLowerCase()) ||
        concepto.conceptoDesc.toLowerCase().includes(searchTerm.toLowerCase())
      );
    })
    .slice(pageNumber * conceptosPerPage, (pageNumber + 1) * conceptosPerPage);

  const updateConceptosList = () => {
    medicGeekApi
      .get("/conceptos")
      .then((response) => {
        setConceptos(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const guardarNuevoConcepto = () => {
    const nuevoConcepto = {
      conceptoNm: document.getElementById("nombreConcepto").value,
      conceptoDesc: document.getElementById("descripcionConcepto").value,
      estatus:
        document.getElementById("estatusConcepto").value === "activo"
          ? "Activo"
          : "Inactivo",
    };
    medicGeekApi
      .post("/conceptos", nuevoConcepto)
      .then((response) => {
        showSuccessAlert();
        closeModal();
        updateConceptosList();
      })
      .catch((error) => {
        console.error("Error al crear el concepto", error);
      });
  };

  const editarConcepto = () => {
    if (selectedConceptoLocal && selectedConceptoLocal.conceptoId) {
      const { conceptoId } = selectedConceptoLocal;
      const nombreConcepto = document.getElementById("nombreConcepto").value;
      const descripcionConcepto =
        document.getElementById("descripcionConcepto").value;
      const estatusConcepto =
        document.getElementById("estatusConcepto").value === "activo"
          ? "Activo"
          : "Inactivo";

      const updatedConcepto = {
        conceptoId, // Asegurarse de enviar el ID
        conceptoNm: nombreConcepto,
        conceptoDesc: descripcionConcepto,
        estatus: estatusConcepto,
      };

      medicGeekApi
        .patch(`/conceptos/${conceptoId}`, updatedConcepto)
        .then((response) => {
          showSuccessEditAlert();
          closeModal();
          updateConceptosList();
        })
        .catch((error) => {
          console.error("Error al actualizar el concepto", error);
        });
    } else {
      // Manejo de error o mensaje de que no se puede actualizar
      showErrorAlert("No se puede actualizar: concepto no válido.");
    }
  };

  
  const SkeletonTableRow = () => (
    <tr>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "60px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "150px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "100px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "70px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "200px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "100px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "60px" }}></div>
      </td>
    </tr>
  );

  return (
    <div>
       <div
          className="d-flex flex-column flex-md-row justify-content-between miDivConGradiente px-3 rounded-bottom height-responsive"
        >
          <div className="d-flex align-items-center text-white mb-2 mb-md-0">
            <h2>Conceptos</h2>
          </div>

          <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-md-0">
          <form className="d-flex mb-2 mb-md-0" role="search">
            <input
              className="form-control me-2"
              type="search"
              placeholder="Buscar"
              aria-label="Buscar"
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </form>
          <button
            type="button"
            className="btn btn-danger ms-2"
            title="Crear Nuevo concepto"
            onClick={() => openModal(null)}
          >
            <i className="bi bi-file-plus"></i> Nuevo concepto
          </button>
        </div>
      </div>

      <div className="mb-2 table-responsive">
        {/* Mostrar tabla en pantallas grandes */}
        <div className="d-none d-md-block mb-2" style={{ height: "45vh", padding: "0rem 1rem" }}>
          <table className="table table-striped table-hover mt-5">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre del concepto</th>
                <th>Descripción del concepto</th>
                <th>Estatus</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
            {loading ? (
              // Mostrar 5 filas de skeleton mientras carga
              Array.from({ length: 15 }).map((_, index) => <SkeletonTableRow key={index} />)
            ) : (
              displayedConceptos.map((concepto) => (
                <tr key={concepto.conceptoId}>
                  <td>{concepto.conceptoId}</td>
                  <td>{concepto.conceptoNm}</td>
                  <td
                    style={{ width: "30%"}}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={concepto.conceptoDesc}
                  >
                    {concepto.conceptoDesc?.length > 20
                      ? `${concepto.conceptoDesc.slice(0, 30)}...`
                      : concepto.conceptoDesc}
                  </td>
                  <td>{concepto.estatus}</td>
                  <td>
                    <div>
                      <button
                        type="button"
                        className="p-1 border-0"
                        title="Editar"
                        id="hoverIcon"
                        onClick={() => openModal(concepto)}
                      >
                        <i className="bi bi-pencil-square"></i>
                      </button>
                      <button
                        type="button"
                        className="p-1 border-0"
                        title="Eliminar"
                        id="hoverIcon"
                        // onClick={() => handleDelete(concepto.conceptoId)}
                      >
                        <i className="bi bi-trash"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
            </tbody>
          </table>
        </div>
    
        {/* Mostrar tarjetas en vista móvil */}
        <div className="d-md-none mb-2" style={{ padding: "0rem 1rem" }}>
          <div className="row">
          {loading ? (
            Array.from({ length: 5 }).map((_, index) => (
              <div className="col-12 mb-3" key={index}>
                <div className="card">
                  <div className="card-body">
                    <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "60%" }}></div>
                    <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "80%" }}></div>
                    <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "70%" }}></div>
                    <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "90%" }}></div>
                    <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "75%" }}></div>
                    <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "50%" }}></div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            displayedConceptos.map((concepto) => (
              <div className="col-12 mb-3" key={concepto.conceptoId}>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">ID-{concepto.conceptoId}</h5>
                    <p className="card-text">
                      <strong>Nombre del concepto:</strong> {concepto.conceptoNm}
                    </p>
                    <p className="card-text">
                      <strong>Descripción:</strong> {concepto.conceptoDesc}
                    </p>
                    <p className="card-text">
                      <strong>Estatus:</strong> {concepto.estatus}
                    </p>
                    <div className="d-flex justify-content-around">
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        title="Editar"
                        onClick={() => openModal(concepto)}
                      >
                        <i className="bi bi-pencil-square"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-danger"
                        title="Eliminar"
                        // onClick={() => handleDelete(concepto.conceptoId)}
                      >
                        <i className="bi bi-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
           ))
          )}
          </div>
        </div>
      </div>
      
       {/* Paginación */}
       {!showForm && (
        <div className="d-flex justify-content-end" style={{ padding: "0rem 1rem" }}>
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
              
              {/* Botón de página anterior */}
              <li className={`page-item ${pageNumber === 0 ? "disabled" : ""}`}>
                <span
                  className="page-link"
                  aria-label="Previous"
                  onClick={() => handlePageChange({ selected: pageNumber - 1 })}
                  style={{ cursor: pageNumber === 0 ? "not-allowed" : "pointer" }}
                >
                  <span aria-hidden="true">&laquo;</span>
                </span>
              </li>

              {Array.from(
                { length: Math.min(pageCount, 10) },
                (_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      pageNumber === index ? " active" : ""
                    }`}
                    style={{ cursor: "pointer" }}
                  >
                    <span
                      className="page-link"
                      onClick={() => handlePageChange({ selected: index })}
                    >
                      {index + 1}
                    </span>
                  </li>
                )
              )}

              {/* Botón de siguiente página */}
              <li className={`page-item ${pageNumber === pageCount - 1 ? "disabled" : ""}`}>
                <span
                  className="page-link"
                  aria-label="Next"
                  onClick={() => handlePageChange({ selected: pageNumber + 1 })}
                  style={{ cursor: pageNumber === pageCount - 1 ? "not-allowed" : "pointer" }}
                >
                  <span aria-hidden="true">&raquo;</span>
                </span>
              </li>
              
            </ul>
          </nav>
        </div>
      )}

      {currentForm === "conceptoForm" && selectedConceptoLocal && (
        <div className="main-content"></div>
      )}
      
      <Modal isOpen={modalOpen} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>
          {isEditing ? "Editar Concepto" : "Crear Nuevo Concepto"}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="nombreConcepto">Nombre del Concepto</Label>
              <Input
                type="text"
                id="nombreConcepto"
                value={formulario.nombreConcepto}
                onChange={(e) =>
                  setFormulario({
                    ...formulario,
                    nombreConcepto: e.target.value,
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="descripcionConcepto">Descripción</Label>
              <Input
                type="text"
                id="descripcionConcepto"
                value={formulario.descripcionConcepto}
                onChange={(e) =>
                  setFormulario({
                    ...formulario,
                    descripcionConcepto: e.target.value,
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="estatusConcepto">Estado</Label>
              <Input
                type="select"
                id="estatusConcepto"
                value={formulario.estatusConcepto}
                onChange={(e) =>
                  setFormulario({
                    ...formulario,
                    estatusConcepto: e.target.value,
                  })
                }
              >
                <option value="activo">Activo</option>
                <option value="inactivo">Inactivo</option>
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button className="button-cancel text-secondary" onClick={closeModal} style={{ height: "3rem" }}>
            Cancelar
          </Button>
          <Button className="button-save text-primary" onClick={isEditing ? editarConcepto : guardarNuevoConcepto} style={{ height: "3rem" }}>
            {isEditing ? "Guardar Edición" : "Guardar"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
  

};
export default ConceptosList;
