import React, { useEffect,useRef, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import {
  showErrorAlert,
  showSuccessAlert,
  showSuccessEditAlert,
} from "../../components/alerts";
import medicGeekApi from "../../config/axiosConfig";
import jsPDF from "jspdf";
import { jwtDecode } from "jwt-decode";

const FacturaForm = ({ currentForm }) => {
  const [facturas, setFacturas] = useState([]);
  const [showForm] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const facturasPerPage = 10;
  const [selectedFacturaLocal, setSelectedFacturaLocal] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isPacienteModalOpen, setIsPacienteModalOpen] = useState(false);
  const [pacientes, setPacientes] = useState([]);
  const [conceptos, setConceptos] = useState([]);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [modalSearchTerm, setModalSearchTerm] = useState("");
  const [modalCurrentPage, setModalCurrentPage] = useState(1);
  const [pacienteSeleccionado, setPacienteSeleccionado] = useState(null);
  const [monto, setMonto] = useState("");
  const [displayedMonto, setDisplayedMonto] = useState(''); // Guardar el monto formateado para la visualización
  const [profileData, setProfileData] = useState(null);
  const searchInputRef = useRef(null);
  // Skeleton
  const [loading, setLoading] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);

  const [formulario, setFormulario] = useState({
    nombrePaciente: "",
    detalleFactura: "",
    conceptoFacturas: "",
    montoFactura: "",
  });

  const openModal = (factura) => {
    if (factura) {
        setSelectedFacturaLocal(factura);
        setIsEditing(true);  // Configura el modo de edición si hay una factura
        setFormulario({
            pcteId: factura.pcteId || "",
            detalleFactura: factura.factDetails || "",
            fechaFactura: factura.fatFec || "",
            conceptoFacturas: factura.conceptoId || "",
            montoFactura: factura.facturaCt || "",
        });
    } else {
        setIsEditing(false); // En modo de creación si no hay factura seleccionada
        setFormulario({
            pcteId: "",
            nombrePaciente: "",
            detalleFactura: "",
            conceptoFacturas: "",
            montoFactura: "",
        });
    }

    setModalOpen(true); // Abre el modal al final
};

  
  const closeModal = () => {
    setSelectedFacturaLocal(null); // Limpia el factura seleccionado
    setIsEditing(false); // Resetear el modo edición cuando se cierra el modal
    setModalOpen(false);
  };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
    setPageNumber(0);
  };

  useEffect(() => {
    const fetchDataAndPaciente = async () => {
        await fetchData();
        await fetchProfile();

        if (selectedFacturaLocal && formulario.pcteId) {
            const paciente = await buscarPacientePorId(formulario.pcteId);
            // console.log('Estas editando el paciente', !!paciente);
        }
    };

    fetchDataAndPaciente();
}, [searchTerm, formulario.pcteId, selectedFacturaLocal]);


  
  
  useEffect(() => {
    if (selectedFacturaLocal) {
      setFormulario({
        nombrePaciente: selectedFacturaLocal.pcteId || "",
        detalleFactura: selectedFacturaLocal.factDetails || "",
        fechaFactura: selectedFacturaLocal.fatFec || "",
        conceptoId: selectedFacturaLocal.conceptoId || "", 
        montoFactura: selectedFacturaLocal.facturaCt || "",
      });
    }
  }, [selectedFacturaLocal]);
  
  const fetchData = async () => {
    setLoading(true); // Iniciar la carga
    try {
      // Obtener facturas
      const response = await medicGeekApi.get(`/facturas/detail?search=${searchTerm}`, {
        params: {
          $or: [
            { facturaNm: { $regex: searchTerm, $options: "i" } },
            { facturaDesc: { $regex: searchTerm, $options: "i" } },
          ],
        },
      });
      const sortedFacturas = response.data.sort((a, b) => a.factId - b.factId);

      // Obtener pacientes
      const patientsResponse = await medicGeekApi.get(
        `/patients?search=${searchTerm}`,
        {
          params: {
            $or: [
              { pcteNom: { $regex: searchTerm, $options: "i" } },
              { pcteNom2: { $regex: searchTerm, $options: "i" } },
              { pcteApe1: { $regex: searchTerm, $options: "i" } },
            ],
          },
        }
      );
      const sortedPacientes = patientsResponse.data.sort((a, b) => a.pcteId - b.pcteId);

      // Obtener conceptos
      const conceptoResponse = await medicGeekApi.get(`/conceptos?search=${searchTerm}`, {
          params: {
            $or: [
              { conceptoNm: { $regex: searchTerm, $options: "i" } },
              { conceptoDesc: { $regex: searchTerm, $options: "i" } },
            ],
          },
        });
        const sortedConceptos = conceptoResponse.data.sort(
          (a, b) => a.conceptoId - b.conceptoId
        );
        

      setFacturas(sortedFacturas);
      setPacientes(sortedPacientes);
      setConceptos(sortedConceptos);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Finalizar carga
    }
  };

  const fetchProfile = async () => {
    try {
      // Obtener el token desde localStorage
      const token = localStorage.getItem("token");
      if (token) {
        // Decodificar el token para obtener el nombre de usuario
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username; // Asegúrate de que 'username' sea correcto

        if (username) {
          // Buscar perfil por nombre de usuario
          const response = await medicGeekApi.get(`/profile/user/${username}`);
          const data = response.data;

          // Verificar si se recibió el perfil
          if (data) {
            setProfileData(data);
            // setIsEditing(true);
          } else {
            console.error("No se encontró el perfil del usuario.");
          }
        } else {
          console.error("No se pudo obtener el nombre de usuario del token.");
        }
      } else {
        console.error("No se encontró el token.");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  
  const pageCount = Math.ceil(facturas.length / facturasPerPage);

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  function parseDate(dateString) {
    if (!dateString) return 0; // Retorna 0 si la fecha está vacía

    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("/").map(Number);
    const [time, modifier] = timePart.split(" ");
    let [hours, minutes] = time.split(":").map(Number);

    if (modifier === "PM" && hours < 12) {
        hours += 12;
    } else if (modifier === "AM" && hours === 12) {
        hours = 0;
    }

    const date = new Date(year, month - 1, day, hours, minutes);
    return date.getTime();
}

  const displayedFacturas = facturas
    .filter((factura) => {
        const factId = factura.factId ? factura.factId.toString().toLowerCase() : "";
        const factDetails = factura.factDetails ? factura.factDetails.toLowerCase() : "";

        return (
            factId.includes(searchTerm.toLowerCase()) ||
            factDetails.includes(searchTerm.toLowerCase())
        );
    })
    .slice() // Crear una copia del array para evitar mutaciones
    .sort((a, b) => parseDate(b.factFec) - parseDate(a.factFec)) // Ordenar de manera descendente por fecha
    .slice(pageNumber * facturasPerPage, (pageNumber + 1) * facturasPerPage); // Aplicar paginación


  const updateFacturaForm = () => {
    medicGeekApi
      .get("/facturas")
      .then((response) => {
        setFacturas(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openPacienteModal = () => {
    setIsPacienteModalOpen(true);
    // Forzar enfoque en el input aquí, después de abrir el modal
    setTimeout(() => {
      if (searchInputRef.current) {
          searchInputRef.current.focus();
      }
  }, 100);
  };

  const closePacienteModal = () => {
    setIsPacienteModalOpen(false);
  };

  const handleModalSearchTermChange = (e) => {
    setModalSearchTerm(e.target.value);
    setModalCurrentPage(1);
  };

  const filteredPacientes = pacientes
  .filter((paciente) => {
    // Separar los términos de búsqueda por espacio y eliminar espacios vacíos
    const searchWords = modalSearchTerm.toLowerCase().trim().split(' ');

    // Combinar nombre completo del paciente
    const fullName = [
      paciente.pcteNom,
      paciente.pcteNom2,
      paciente.pcteApe1,
      paciente.pcteApe2,
    ]
      .filter(Boolean) // Filtrar los valores nulos o undefined
      .map((name) => name.toLowerCase()) // Convertir cada nombre a minúsculas
      .join(' '); // Unir en una cadena completa

    // Verificar si todos los términos de búsqueda coinciden con el nombre completo
    return searchWords.every((word) => fullName.includes(word));
  });

  const totalPages = Math.ceil(filteredPacientes.length / itemsPerPage);

  const handlePacienteSelection = (paciente) => {
    if (paciente) {
      setFormulario({
        ...formulario,
        pcteId: paciente.pcteId || "",
        nombreDelPaciente: `${paciente.pcteNom || ""} ${paciente.pcteNom2 || ""} ${paciente.pcteApe1 || ""} ${paciente.pcteApe2 || ""}`,
        direccionPaciente: paciente.pcteDir || "",
        contactoPaciente: paciente.pcteTel || paciente.pcteCel,
        emailPaciente: paciente.pcteEmail || "",
      });
  
      setPacienteSeleccionado(paciente);
      setModalOpen(true);
      closePacienteModal();
    } else {
      console.error("El paciente seleccionado es nulo.");
    }
  };
  
  const buscarPacientePorId = async (pcteId) => {
    try {
      // Hacer la solicitud al endpoint de pacientes para obtener el paciente por ID
      const response = await medicGeekApi.get(`/patients/${pcteId}`);
      
      const paciente = response.data;
  
      // Verificar si se encontró un paciente y actualizar el formulario
      if (paciente) {
        setFormulario((formulario) => ({
          ...formulario,
          pcteId: paciente.pcteId,
          nombreDelPaciente: `${paciente.pcteNom || ""} ${paciente.pcteNom2 || ""} ${paciente.pcteApe1 || ""} ${paciente.pcteApe2 || ""}`,
          direccionPaciente: paciente.pcteDir || "",
          contactoPaciente: paciente.pcteTel || paciente.pcteCel || "",
          emailPaciente: paciente.pcteEmail || "",
        }));
      } else {
        console.error("No se encontró un paciente con el ID proporcionado.");
      }
    } catch (error) {
      console.error("Error al buscar el paciente:", error);
    }
  };
 
  const handleSelectChange = (e) => {
    const conceptoSeleccionado = e.target.value;

    setFormulario({
        ...formulario,
        conceptoFacturas: conceptoSeleccionado,
    });
  };

  const getConceptoNombre = (conceptoId) => {
    const concepto = conceptos.find(c => Number(c.conceptoId) === Number(conceptoId));
    return concepto ? concepto.conceptoNm : "Concepto no disponible";
  };

  const SkeletonTableRow = () => (
    <tr>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "60px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "150px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "100px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "70px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "200px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "100px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "60px" }}></div>
      </td>
    </tr>
  );

  const formatNumber = (value) => {
    return new Intl.NumberFormat("es-DO", {
      style: "currency",
      currency: "DOP",
      minimumFractionDigits: 0,
    }).format(value);
  };

  const handleChange = (e) => {
    const rawValue = e.target.value.replace(/RD/g, '').replace(/,/g, ''); // Eliminar el prefijo RD y comas
  
    if (!isNaN(rawValue) || rawValue === "") {
      const numericValue = rawValue === "" ? "" : Number(rawValue); // Convertir a número
  
      // Actualizar el estado del formulario con el monto plano para almacenamiento
      setFormulario({
        ...formulario,
        montoFactura: numericValue, // Guardar como número plano
      });
  
      // Actualizar la visualización si necesitas mostrar el monto formateado
      const formattedValue = rawValue === "" ? "" : formatNumber(numericValue);
      setDisplayedMonto(formattedValue); // Esto asume que tienes un estado separado para la visualización
    }
  };

  const handleFocus = (event) => {
    event.target.select(); // Selecciona todo el texto del input
  };  

  const guardarNuevoFactura = () => {

    // if (!formulario.pcteId) {
    //   showErrorAlert("Por favor, seleccione un paciente antes de guardar la factura.");
    //   return; // Detener la ejecución si no hay un paciente seleccionado
    // }

    const fechaActual = new Date();
  
    const dia = String(fechaActual.getDate()).padStart(2, "0");
    const mes = String(fechaActual.getMonth() + 1).padStart(2, "0");
    const año = fechaActual.getFullYear();
  
    const ampm = fechaActual.getHours() >= 12 ? "PM" : "AM";
    let horas = fechaActual.getHours() % 12;
    horas = horas === 0 ? 12 : horas; // Ajuste para formato de 12 horas
    const minutos = String(fechaActual.getMinutes()).padStart(2, "0");
  

    const fechaHoraFormateada = `${dia}/${mes}/${año} ${horas}:${minutos} ${ampm}`;

  
    const nuevoFactura = {
      pcteId: formulario.pcteId || null,
      factDetails: formulario.detalleFactura,
      factFec: fechaHoraFormateada,
      conceptoId: formulario.conceptoId,
      facturaCt: formulario.montoFactura,
    };

    console.log(nuevoFactura)
  
    medicGeekApi
      .post("/facturas", nuevoFactura)
      .then((response) => {
        showSuccessAlert(); // Mensaje de éxito
        closeModal(); // Cierra el modal
        fetchData(); // Refresca los datos
      })
      .catch((error) => {
        console.error("Error al crear la factura", error);
      });
  };
  
  const editarFactura = () => {
    if (selectedFacturaLocal && selectedFacturaLocal.factId) {
      const fechaActual = new Date();
  
      // Obtener el día, mes, año, horas y minutos
      const dia = String(fechaActual.getDate()).padStart(2, "0");
      const mes = String(fechaActual.getMonth() + 1).padStart(2, "0");
      const año = fechaActual.getFullYear();
  
      const ampm = fechaActual.getHours() >= 12 ? "PM" : "AM";
      let horas = fechaActual.getHours() % 12;
      horas = horas === 0 ? 12 : horas; // Ajuste para formato de 12 horas
      const minutos = String(fechaActual.getMinutes()).padStart(2, "0");
  
      // Formatear la fecha y la hora correctamente
      const fechaHoraFormateada = `${dia}/${mes}/${año} ${horas}:${minutos} ${ampm}`;
  
      // Datos de la factura editada
      const facturaEditada = {
        pcteId: formulario.pcteId, // ID del paciente
        factDetails: formulario.detalleFactura, // Detalle de la factura
        factFec: fechaHoraFormateada, // Fecha actualizada
        conceptoId: formulario.conceptoId, // Concepto de la factura
        facturaCt: formulario.montoFactura, // Monto de la factura
      };

      // Llamada a la API para editar la factura existente
      medicGeekApi
        .patch(`/facturas/${selectedFacturaLocal.factId}`, facturaEditada)
        .then((response) => {
          showSuccessEditAlert(); // Mensaje de éxito
          closeModal(); // Cierra el modal
          fetchData(); // Refresca los datos
        })
        .catch((error) => {
          console.error("Error al editar la factura", error);
        });
    } else {
      showErrorAlert("No se puede editar: factura no válida.");
    }
  };

  const numeroALetras = (num) => {
    const unidades = ["", "UNO", "DOS", "TRES", "CUATRO", "CINCO", "SEIS", "SIETE", "OCHO", "NUEVE"];
    const especiales = ["DIEZ", "ONCE", "DOCE", "TRECE", "CATORCE", "QUINCE", "DIECISEIS", "DIECISIETE", "DIECIOCHO", "DIECINUEVE"];
    const decenas = ["", "", "VEINTE", "TREINTA", "CUARENTA", "CINCUENTA", "SESENTA", "SETENTA", "OCHENTA", "NOVENTA"];
    const centenas = ["", "CIENTO", "DOSCIENTOS", "TRESCIENTOS", "CUATROCIENTOS", "QUINIENTOS", "SEISCIENTOS", "SETECIENTOS", "OCHOCIENTOS", "NOVECIENTOS"];

    if (num === 0) return "CERO";

    let letras = "";

    // Miles
    if (Math.floor(num / 1000) > 0) {
        if (Math.floor(num / 1000) === 1) {
            letras += "MIL ";
        } else {
            letras += numeroALetras(Math.floor(num / 1000)) + " MIL ";
        }
        num %= 1000;
    }

    // Centenas
    if (Math.floor(num / 100) > 0) {
        letras += centenas[Math.floor(num / 100)] + " ";
        num %= 100;
    }

    // Decenas y unidades especiales (10-19)
    if (num >= 10 && num < 20) {
        letras += especiales[num - 10] + " ";
    } else {
        // Decenas
        if (Math.floor(num / 10) > 1) {
            letras += decenas[Math.floor(num / 10)];
            if (num % 10 > 0) letras += " Y ";
        }

        // Unidades
        letras += unidades[num % 10] + " ";
    }

    return letras.trim();
  };

  const convertirFacturaCtALetras = (valor) => {
    if (valor === undefined || isNaN(valor)) return "CERO PESOS";
    
    const [parteEntera, decimales] = parseFloat(valor).toFixed(2).split(".");
    const cantidadEnLetras = numeroALetras(parseInt(parteEntera, 10));
    const centavosEnLetras = decimales === "00" ? "" : `CON ${numeroALetras(parseInt(decimales, 10))} CENTAVOS`;

    return `${cantidadEnLetras} PESOS ${centavosEnLetras}`.trim();
  };

  const previsualizarPDF = async (factura) => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 10;
    const additionalMargin = 40;
    const fontSize = 10;

    doc.setFontSize(fontSize);

    const fechaLarga = new Date().toLocaleDateString();
    const cantidadEnLetras = convertirFacturaCtALetras(factura.facturaCt);
    const nombreCompleto = factura.patient
        ? `${factura.patient.pcteNom || ""} ${factura.patient.pcteNom2 || ""} ${factura.patient.pcteApe1 || ""} ${factura.patient.pcteApe2 || ""}`
        : "OTROS INGRESOS";

    // Título
    doc.setFontSize(22);
    doc.setFont(undefined, "bold");
    const detalleText = "RECIBO DE PAGO";
    const textWidth = doc.getTextWidth(detalleText);
    const centeredX = (pageWidth - textWidth) / 2;
    doc.text(detalleText, centeredX, margin + additionalMargin);
    doc.setFont(undefined, "normal");

    // Información del paciente y recibo
    doc.setFontSize(12);
    const profileText = `Recibimos en el día de hoy de parte de ${nombreCompleto}. Cédula de identidad número ${factura.patient?.pcteNumDoc || ""}, la suma de ${parseFloat(factura.facturaCt).toLocaleString("es-DO", {style: "currency", currency: "DOP", minimumFractionDigits: 2, maximumFractionDigits: 2})} (${cantidadEnLetras}), por concepto de ${getConceptoNombre(factura.conceptoId)} por consulta realizada en el día de hoy.`;

    const profileLines = doc.splitTextToSize(profileText, pageWidth - 2 * margin);
    let y = margin + additionalMargin + 20;
    const lineHeight = fontSize * 0.6;

    profileLines.forEach((line) => {
        if (y + lineHeight > pageHeight - margin - additionalMargin - lineHeight) {
            doc.addPage();
            y = margin + additionalMargin;
        }
        doc.text(line, margin, y);
        y += lineHeight;
    });

    // Nombre y exequátur del doctor
    const alturaNombre = y + 70;
    const lineaY = alturaNombre - 5;
    const lineaX = (pageWidth - 80) / 2;
    doc.setLineWidth(0.5);
    doc.line(lineaX, lineaY, lineaX + 80, lineaY);

    const nombreDoctor = profileData.docNom || "Doctor";
    doc.setFontSize(12);
    const nombreX = (pageWidth - doc.getTextWidth(nombreDoctor)) / 1.41;
    doc.text(nombreDoctor, nombreX, alturaNombre, { align: "center" });

    const exequaturText = `Exequátur: ${profileData.docExe || "N/A"}`;
    doc.text(exequaturText, nombreX, alturaNombre + 5, { align: "center" });

    // Convertir el PDF a Blob y generar la vista previa/imprimir
    const pdfBlob = doc.output('blob');
    let iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);

    const blobUrl = URL.createObjectURL(pdfBlob);
    iframe.src = blobUrl;

    iframe.onload = () => {
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
        iframe.contentWindow.onafterprint = () => {
            document.body.removeChild(iframe);
            URL.revokeObjectURL(blobUrl);
        };
    };
  };


  return (
    <div>
      <div
        className="d-flex flex-column flex-md-row justify-content-between miDivConGradiente px-3 rounded-bottom height-responsive"
      >
          <div className="d-flex align-items-center text-white mb-2 mb-md-0">
          <h2>Facturación</h2>
          </div>

          <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-md-0">
            <form className="d-flex mb-2 mb-md-0" role="search">
              <input
                className="form-control me-2"
                type="search"
                placeholder="Buscar"
                aria-label="Buscar"
                value={searchTerm} // Usa el estado del buscador del modal
                onChange={handleSearchTermChange} // Usa la función para el modal
              />
            </form>
            <button
            type="button"
            className="btn btn-danger ms-2"
            title="Crear Nuevo factura"
            onClick={() => openModal(null)}
            >
            <i className="bi bi-file-plus"></i> Nuevo factura
            </button>
          </div>
      </div>

      <div className="mb-2 table-responsive">
        {/* Mostrar tabla en pantallas grandes */}
        <div className="d-none d-md-block mb-2" style={{ height: "45vh", padding: "0rem 1rem" }}>
          <table className="table table-striped table-hover mt-5">
            <thead>
              <tr>
              <th>ID</th>
              <th>Tipo de Ingreso</th>
              <th>Concepto de la factura</th>
              <th>Fecha</th>
              <th>Monto</th>
              <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
                {loading ? (
                    Array.from({ length: 15 }).map((_, index) => <SkeletonTableRow key={index} />)
                ) : (
                    displayedFacturas.map((factura) => (
                        <tr key={factura.factId}>
                          <td>{factura.factId}</td>
                          <td>
                            {factura.patient
                              ? `${factura.patient.pcteNom || ""} ${factura.patient.pcteNom2 || ""} ${factura.patient.pcteApe1 || ""} ${factura.patient.pcteApe2 || ""}`
                              : "OTROS INGRESOS"}
                          </td>
                          <td>{getConceptoNombre(factura.conceptoId)}</td>
                          <td>{factura.factFec || "Fecha no disponible"}</td>
                          <td style={{ width: "10%" }}>
                            {parseFloat(factura.facturaCt).toLocaleString("es-DO", {
                              style: "currency",
                              currency: "DOP",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                        </td>
                          <td>
                            <div className="">
                              <button
                                type="button"
                                className="p-1 border-0"
                                title="Editar"
                                id="hoverIcon"
                                onClick={() => openModal(factura)}
                              >
                                <i className="bi bi-pencil-square"></i>
                              </button>
                              <button
                                type="button"
                                className="p-1 border-0"
                                title="Print"
                                id="hoverIcon"
                                onClick={() => previsualizarPDF(factura)}
                              >
                                <i className="bbi bi-printer-fill"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                    ))
                )}
            </tbody>
            </table>
        </div>

        {/* Mostrar tarjetas en vista móvil */}
        <div className="d-md-none mb-2" style={{ padding: "0rem 1rem" }}>
            <div className="row">
            {loading ? (
            Array.from({ length: 5 }).map((_, index) => (
                <div className="col-12 mb-3" key={index}>
                  <div className="card">
                    <div className="card-body">
                    <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "60%" }}></div>
                    <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "80%" }}></div>
                    <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "70%" }}></div>
                    <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "90%" }}></div>
                    <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "75%" }}></div>
                    <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "50%" }}></div>
                    </div>
                  </div>
                </div>
            ))
            ) : (
            displayedFacturas.map((factura) => (
                <div className="col-12 mb-3" key={factura.factId}>
                <div className="card">
                    <div className="card-body">
                    <h5 className="card-title">ID-{factura.factId}</h5>
                    <p className="card-text">
                        <strong>Nombre del factura:</strong> {factura.pcteId}
                    </p>
                    <p className="card-text">
                        <strong>Descripción:</strong> {factura.facturaDesc}
                    </p>
                    <p className="card-text">
                        <strong>Estatus:</strong> {factura.estatus}
                    </p>
                    <div className="d-flex justify-content-around">
                        <button
                        type="button"
                        className="btn btn-outline-primary"
                        title="Editar"
                        onClick={() => openModal(factura)}
                        >
                        <i className="bi bi-pencil-square"></i>
                        </button>
                        <button
                        type="button"
                        className="btn btn-outline-danger"
                        title="Eliminar"
                        // onClick={() => handleDelete(factura.facturaId)}
                        >
                        <i className="bi bi-trash"></i>
                        </button>
                    </div>
                    </div>
                </div>
                </div>
            ))
            )}
            </div>
        </div>
      </div>

      {/* Paginación */}
      {!showForm && (
      <div className="d-flex justify-content-end" style={{ padding: "0rem 1rem" }}>
          <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center">
              
              {/* Botón de página anterior */}
              <li className={`page-item ${pageNumber === 0 ? "disabled" : ""}`}>
              <span
                  className="page-link"
                  aria-label="Previous"
                  onClick={() => handlePageChange({ selected: pageNumber - 1 })}
                  style={{ cursor: pageNumber === 0 ? "not-allowed" : "pointer" }}
              >
                  <span aria-hidden="true">&laquo;</span>
              </span>
              </li>

              {Array.from(
              { length: Math.min(pageCount, 10) },
              (_, index) => (
                  <li
                  key={index}
                  className={`page-item ${
                      pageNumber === index ? " active" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                  >
                  <span
                      className="page-link"
                      onClick={() => handlePageChange({ selected: index })}
                  >
                      {index + 1}
                  </span>
                  </li>
              )
              )}

              {/* Botón de siguiente página */}
              <li className={`page-item ${pageNumber === pageCount - 1 ? "disabled" : ""}`}>
              <span
                  className="page-link"
                  aria-label="Next"
                  onClick={() => handlePageChange({ selected: pageNumber + 1 })}
                  style={{ cursor: pageNumber === pageCount - 1 ? "not-allowed" : "pointer" }}
              >
                  <span aria-hidden="true">&raquo;</span>
              </span>
              </li>
              
          </ul>
          </nav>
      </div>
      )}

      {currentForm === "facturaForm" && selectedFacturaLocal && (
          <div className="main-content"></div>
      )}
    
      <Modal
          isOpen={modalOpen}
          toggle={closeModal}
          style={{
              maxWidth: "60rem",
              overlay: {
              zIndex: 1002, // Ajusta el valor según sea necesario (mayor que el de los popovers)
              },
              content: {
              zIndex: 1003, // Ajusta el valor según sea necesario (mayor que el de los popovers)
              },
          }}
          >
          <ModalHeader toggle={closeModal}>
              {isEditing ? "Editar Factura" : "Crear Nueva Factura"}
          </ModalHeader>
          <ModalBody>
              {/* {selectedPaciente ? ( */}
              <div className=" gap-4">
                <div>
                  <div>
                      <p className="fs-5 fw-bold d-flex">
                          Datos del Paciente
                      </p>
                  </div>
                    <Form>
                      <Col md={12}>
                        <FormGroup
                          floating
                          className="d-flex align-items-center gap-2"
                          style={{ display: "flex" }}
                        >
                            <Input
                              type="text"
                              className="form-control"
                              id="nombreDelPaciente"
                              value={formulario.nombreDelPaciente || ""}
                              placeholder="Nombre del Paciente"
                              disabled
                            />


                            <Label htmlFor="pcteNom">Paciente</Label>
                            <Button
                              onClick={openPacienteModal}
                              className="d-flex justify-content-center align-items-center button-save"
                              style={{
                                  height: "3rem",
                                  width: "3rem",
                              }}
                              disabled={isEditing}
                            >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-user-search"
                                width="25"
                                height="25"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="#0c56b6"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                                />
                                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                                <path d="M6 21v-2a4 4 0 0 1 4 -4h1.5" />
                                <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                                <path d="M20.2 20.2l1.8 1.8" />
                            </svg>
                            </Button>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup floating>
                          <Input
                            type="text"
                            className="form-control"
                            id="pcteDir"
                            value={formulario.direccionPaciente}
                            onChange={(e) =>
                            setFormulario({
                                ...formulario,
                                direccionPaciente: e.target.value,
                            })
                            }
                            placeholder="Direccion del Paciente"
                            disabled
                          />
                        <Label htmlFor="pcteDir">Dirección</Label>
                        </FormGroup>
                      </Col>
                        <div className="d-flex gap-1">
                          <Col md={6}>
                            <FormGroup floating>
                              <Input
                                type="text"
                                className="form-control"
                                id="contactoPaciente"
                                value={formulario.contactoPaciente}
                                onChange={(e) =>
                                setFormulario({
                                    ...formulario,
                                    contactoPaciente: e.target.value,
                                })
                                }
                                placeholder="Contacto del Paciente"
                                disabled
                              />
                            <Label htmlFor="contactoPaciente">Contacto</Label>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup floating>
                              <Input
                                type="text"
                                className="form-control"
                                id="emailPaciente"
                                value={formulario.emailPaciente}
                                onChange={(e) =>
                                setFormulario({
                                    ...formulario,
                                    emailPaciente: e.target.value,
                                })
                                }
                                placeholder="Contacto del Paciente"
                                disabled
                              />
                              <Label htmlFor="emailPaciente">Email</Label>
                            </FormGroup>
                          </Col>
                        </div>
                    </Form>
                </div>
                  {/* Datos del paciente */}
                  <div>
                    <div>
                      <p className="fs-5 fw-bold d-flex justify-content-firt">
                          Datos de la Factura
                      </p>
                    </div>
                      <Form>
                      <div className="d-flex gap-3">
                        <Col>
                          <FormGroup floating>
                            <Input
                              type="select"
                              className="form-control"
                              id="conceptoId"
                              value={formulario.conceptoId || ""} // Usamos conceptoId
                              onChange={(e) =>
                                setFormulario({
                                  ...formulario,
                                  conceptoId: e.target.value, // Actualizamos el conceptoId correctamente
                                })
                              }
                            >
                              <option value="">Seleccione un concepto</option>
                              {conceptos.map((concepto) => (
                                <option key={concepto.conceptoId} value={concepto.conceptoId}>
                                  {concepto.conceptoNm}
                                </option>
                              ))}
                            </Input>
                            <Label htmlFor="conceptoId">Concepto</Label>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup >
                            <Input
                              style={{
                                height: "5rem",
                                fontSize: "3rem",
                                textAlign: "right",
                                // backgroundColor: "#cac6c6",
                              }}
                              type="text"
                              name="search"
                              id="search"
                              placeholder="RD$0"
                              value={formulario.montoFactura}
                              onChange={handleChange}
                              onFocus={handleFocus}
                            />
                          </FormGroup>
                        </Col>
                      </div>
                      <Col>
                          <FormGroup floating>
                          <Input
                              type="textarea"
                              className="form-control"
                              id="detalleFactura"
                              value={formulario.detalleFactura}
                              style={{
                              height: "8.5rem",
                              }}
                              onChange={(e) =>
                              setFormulario({
                                  ...formulario,
                                  detalleFactura: e.target.value,
                              })
                              }
                              placeholder="detalleFactura"
                          />
                          <Label htmlFor="detalleFactura">Detalle de Factura</Label>
                          </FormGroup>
                      </Col>
                      </Form>
                  </div>
                  {/* Detalle de la factura */}
              </div>
              
          </ModalBody>
              <div className="d-flex p-3 justify-content-end">
                  <div className="d-flex gap-2">
                  <Button
                      className="button-save text-primary"
                      onClick={isEditing ? editarFactura : guardarNuevoFactura }
                  >
                      <i class="bi bi-floppy px-1 text-primary"></i>
                      {isEditing ? "Editar Factura" : "Guardar"}
                  </Button>
                  <Button
                      className="button-cancel text-secondary"
                      onClick={closeModal}
                  >
                      <i class="bi bi-x-circle px-1"></i>
                      Cancelar
                  </Button>
                  </div>
              </div>
      </Modal>

      <Modal isOpen={isPacienteModalOpen} toggle={closePacienteModal} style={{
      maxWidth: "50rem",
      }}>
          <ModalHeader toggle={closePacienteModal}>
          Seleccionar Paciente
          </ModalHeader>
          <ModalBody>
          <Form className="mb-4 sticky-top pt-3 border-bottom bg-primary text-white px-2 rounded-3">
              <FormGroup>
                <div className="d-flex flex-column">
                  <Label for="search">Buscar por nombre:</Label>
                  <input
                      className="rounded-3 px-2 py-2 border-0"
                      type="text"
                      name="search"
                      id="search"
                      placeholder="Nombre del paciente"
                      value={modalSearchTerm} // Usa el estado del buscador del modal
                      onChange={handleModalSearchTermChange} // Usa la función para el modal
                      ref={searchInputRef}
                    />
                </div>
              </FormGroup>
          </Form>
          <ListGroup>
              {filteredPacientes
              .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
              )
              .map((paciente) => {
                  const fullName = [paciente.pcteNom, paciente.pcteNom2, paciente.pcteApe1, paciente.pcteApe2]
                  .filter(Boolean) 
                  .join(' ');
                  
                  return (
                  <ListGroupItem key={paciente.pcteId}>
                      <div className="d-flex justify-content-between">
                      {fullName && <span>{fullName}</span>}
                      <Button
                          color="primary"
                          title="Nuevo Certificado Médico"
                          onClick={() => handlePacienteSelection(paciente)}
                      >
                          <i className="bi bi-clipboard-check-fill"></i>
                      </Button>
                      </div>
                  </ListGroupItem>
                  );
              })}
          </ListGroup>
          <nav className="pt-3 d-flex justify-content-center">
              <ul className="pagination justify-content-center">
              <li className="page-item" style={{ cursor: "pointer" }}>
                  <span className="page-link" onClick={() => setCurrentPage(1)}>
                  Inicio
                  </span>
              </li>
      
              <li className="page-item" style={{ cursor: "pointer" }}>
                  <span
                  className="page-link"
                  style={{
                      height: "2.4rem",
                      width: "7rem",
                  }}
                  onClick={() => setCurrentPage(Math.max(1, currentPage - 10))}
                  >
                  Anterior 10
                  </span>
              </li>
      
              {Array.from(
                  { length: Math.min(Math.ceil(filteredPacientes.length / itemsPerPage), 10) },
                  (_, index) => (
                  <li
                      key={index}
                      className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
                      style={{ cursor: "pointer" }}
                  >
                      <span
                      className="page-link"
                      onClick={() => setCurrentPage(index + 1)}
                      >
                      {index + 1}
                      </span>
                  </li>
                  )
              )}
      
              <li className="page-item" style={{ cursor: "pointer" }}>
                  <span
                  className="page-link"
                  style={{
                      height: "2.4rem",
                      width: "7.5rem",
                  }}
                  onClick={() =>
                      setCurrentPage(
                      Math.min(
                          Math.ceil(filteredPacientes.length / itemsPerPage),
                          currentPage + 10
                      )
                      )
                  }
                  >
                  Siguiente 10
                  </span>
              </li>
      
              <li className="page-item" style={{ cursor: "pointer" }}>
                  <span
                  className="page-link"
                  onClick={() =>
                      setCurrentPage(Math.ceil(filteredPacientes.length / itemsPerPage))
                  }
                  >
                  Final
                  </span>
              </li>
              </ul>
          </nav>
          </ModalBody>
          <ModalFooter>
          <Button
              className="button-cancel text-secondary"
              onClick={closePacienteModal}
          >
              Cancelar
          </Button>
          </ModalFooter>
      </Modal>
    </div>
  );
  

};
export default FacturaForm;
