import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  showErrorAlert,
  showSuccessAlert,
  showSuccessEditAlert,
} from "../../../components/alerts";
import medicGeekApi from "../../../config/axiosConfig";

const ClassificationForm = ({ currentForm }) => {
  const [classification, setClassification] = useState([]);
  const [showForm] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const classificationsPerPage = 10;
  const [selectedClassificationLocal, setSelectedClassificationLocal] =
    useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  // Skeleton
  const [loading, setLoading] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);

  const [formulario, setFormulario] = useState({
    nombreClassification: "",
    descripcionClassification: "",
    estatusClassification: "activo",
  });

  const openModal = (classification) => {
    setSelectedClassificationLocal(classification); // Configura el classification seleccionado para la edición

    if (classification) {
      setIsEditing(true); // Establecer como modo edición cuando se abre el modal
      setFormulario({
        nombreClassification: classification.classificationNm,
        descripcionclassification: classification.classificationDesc,
        estatusClassification: classification.estatus ? "activo" : "inactivo",
      });
    } else {
      setIsEditing(false); // Establecer como modo creación cuando se abre el modal para crear uno nuevo
      // Limpia los campos si es necesario
      setFormulario({
        nombreClassification: "",
        descripcionclassification: "",
        estatusClassification: "activo",
      });
    }
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedClassificationLocal(null); // Limpia el classification seleccionado
    setIsEditing(false); // Resetear el modo edición cuando se cierra el modal
    setModalOpen(false);
  };

  const handleSearchTermChange = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Iniciar la carga
      try {
        const response = await medicGeekApi.get(
          `/classifications?search=${searchTerm}`,
          {
            params: {
              $or: [
                { classificationNm: { $regex: searchTerm, $options: "i" } },
                { classificationDesc: { $regex: searchTerm, $options: "i" } },
              ],
            },
          }
        );
        const sortedClassifications = response.data.sort(
          (a, b) => a.classificationId - b.classificationId
        );
        setClassification(sortedClassifications);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false); // Finalizar carga
      }
    };

    fetchData();
  }, [searchTerm]);

  const pageCount = Math.ceil(classification.length / classificationsPerPage);

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const displayedClassifications = classification
    .filter((classification) => {
      // Filtrar classifications basados en el término de búsqueda
      return (
        classification.classificationNm
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        classification.classificationDesc
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
    })
    .slice(
      pageNumber * classificationsPerPage,
      (pageNumber + 1) * classificationsPerPage
    );

  const updateClassificationForm = () => {
    medicGeekApi
      .get("/classifications")
      .then((response) => {
        setClassification(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const guardarNuevoClassification = () => {
    const nuevoClassification = {
      classificationNm: document.getElementById("nombreClassification").value,
      classificationDesc: document.getElementById("descripcionClassification")
        .value,
      estatus:
        document.getElementById("estatusClassification").value === "activo"
          ? "Activo"
          : "Inactivo",
    };
    medicGeekApi
      .post("/classifications", nuevoClassification)
      .then((response) => {
        showSuccessAlert();
        closeModal();
        updateClassificationForm();
      })
      .catch((error) => {
        console.error("Error al crear el Classification", error);
      });
  };

  const editarClassification = () => {
    if (
      selectedClassificationLocal &&
      selectedClassificationLocal.classificationId
    ) {
      const { classificationId } = selectedClassificationLocal;
      const nombreClassification = document.getElementById(
        "nombreClassification"
      ).value;
      const descripcionClassification = document.getElementById(
        "descripcionClassification"
      ).value;
      const estatusClassification =
        document.getElementById("estatusClassification").value === "activo"
          ? "Activo"
          : "Inactivo";

      const updatedClassification = {
        classificationId, // Asegurarse de enviar el ID
        classificationNm: nombreClassification,
        classificationDesc: descripcionClassification,
        estatus: estatusClassification,
      };

      medicGeekApi
        .patch(`/classifications/${classificationId}`, updatedClassification)
        .then((response) => {
          showSuccessEditAlert();
          closeModal();
          updateClassificationForm();
        })
        .catch((error) => {
          console.error("Error al actualizar el Classification", error);
        });
    } else {
      // Manejo de error o mensaje de que no se puede actualizar
      showErrorAlert("No se puede actualizar: Classification no válido.");
    }
  };

  const SkeletonTableRow = () => (
    <tr>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "60px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "150px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "100px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "70px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "200px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "100px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "60px" }}></div>
      </td>
    </tr>
  );

  return (
    <div>
      <div>
        <div
          className="d-flex flex-column flex-md-row justify-content-between miDivConGradiente px-3 rounded-bottom height-responsive"
        >
            <div className="d-flex align-items-center text-white mb-2 mb-md-0">
              <h2>Clasificación</h2>
            </div>

            <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-md-0">
              <form className="d-flex" role="search">
                <input
                  className="form-control me-2"
                  type="search"
                  placeholder="Buscar"
                  aria-label="Buscar"
                  value={searchTerm}
                  onChange={handleSearchTermChange}
                />
              </form>
              <button
                type="button"
                className="btn btn-danger"
                title="Crear Nueva Clasificación"
                onClick={() => openModal(null)}
              >
                <i className="bi bi-file-plus"></i> Nueva Clasificación
              </button>
            </div>
        </div>

        <div
          className="mb-2"
          style={{ height: "45vh", padding: "0rem 1rem" }}
        >
          {/* Tabla en pantallas medianas y grandes */}
          <table className="table table-striped table-hover mt-5 d-none d-md-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre de la Clasificación</th>
                <th>Descripción de la Clasificación</th>
                <th>Estatus</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
            {loading ? (
              // Mostrar 5 filas de skeleton mientras carga
              Array.from({ length: 15 }).map((_, index) => <SkeletonTableRow key={index} />)
            ) : (
              displayedClassifications.map((classification) => (
                <tr key={classification.classificationId}>
                  <td style={{ width: "5%" }}>{classification.classificationId}</td>
                  <td style={{ width: "30%" }}>{classification.classificationNm}</td>
                  <td
                  style={{ width: "30%"}}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={classification.classificationDesc}
                >
                  {classification.classificationDesc?.length > 20
                    ? `${classification.classificationDesc.slice(0, 30)}...`
                    : classification.classificationDesc}
                </td>
                  <td style={{ width: "30%" }}>{classification.estatus}</td>
                  <td style={{ width: "5%" }}>
                    <div className="">
                      <button
                        type="button"
                        className="p-1 border-0"
                        title="Editar"
                        id="hoverIcon"
                        onClick={() => openModal(classification)}
                      >
                        <i className="bi bi-pencil-square"></i>
                      </button>
                      <button
                        type="button"
                        className="p-1 border-0"
                        title="Eliminar"
                        id="hoverIcon"
                      >
                        <i className="bi bi-trash"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
            </tbody>
          </table>

          {/* Modo "card" en dispositivos móviles */}
          <div className="d-md-none">
          <div className="row">
          {loading ? (
            Array.from({ length: 5 }).map((_, index) => (
              <div className="col-12 mb-3" key={index}>
                <div className="card">
                  <div className="card-body">
                    <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "60%" }}></div>
                    <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "80%" }}></div>
                    <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "70%" }}></div>
                    <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "90%" }}></div>
                    <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "75%" }}></div>
                    <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "50%" }}></div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            displayedClassifications.map((classification) => (
              <div key={classification.classificationId} className="card mb-3">
                <div className="card-body">
                  <h5 className="card-title">
                    {classification.classificationNm} (ID: {classification.classificationId})
                  </h5>
                  <p className="card-text">
                    Descripción: {classification.classificationDesc}
                  </p>
                  <p className="card-text">Estatus: {classification.estatus}</p>
                  <div className="d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => openModal(classification)}
                    >
                      Editar
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                    >
                      Eliminar
                    </button>
                  </div>
                </div>
              </div>
             ))
            )}
            </div>
          </div>

          {/* Paginación */}
          {!showForm && (
            <div className="d-flex justify-content-end">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  
                  {/* Botón de página anterior */}
                  <li className={`page-item ${pageNumber === 0 ? "disabled" : ""}`}>
                    <span
                      className="page-link"
                      aria-label="Previous"
                      onClick={() => handlePageChange({ selected: pageNumber - 1 })}
                      style={{ cursor: pageNumber === 0 ? "not-allowed" : "pointer" }}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </span>
                  </li>

                  {Array.from(
                    { length: Math.min(pageCount, 10) },
                    (_, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          pageNumber === index ? " active" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          className="page-link"
                          onClick={() => handlePageChange({ selected: index })}
                        >
                          {index + 1}
                        </span>
                      </li>
                    )
                  )}

                  {/* Botón de siguiente página */}
                  <li className={`page-item ${pageNumber === pageCount - 1 ? "disabled" : ""}`}>
                    <span
                      className="page-link"
                      aria-label="Next"
                      onClick={() => handlePageChange({ selected: pageNumber + 1 })}
                      style={{ cursor: pageNumber === pageCount - 1 ? "not-allowed" : "pointer" }}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </span>
                  </li>
                  
                </ul>
              </nav>
            </div>
          )}
        </div>

        {/* Modal para creación/edición */}
        {currentForm === "classificationForm" &&
          selectedClassificationLocal && <div className="main-content"></div>}
        <Modal isOpen={modalOpen} toggle={closeModal}>
          <ModalHeader toggle={closeModal}>
            {isEditing ? "Editar Clasificación" : "Crear Nueva Clasificación"}
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="nombreClassification">
                  Nombre de la Clasificación
                </Label>
                <Input
                  type="text"
                  id="nombreClassification"
                  value={formulario.nombreClassification}
                  onChange={(e) =>
                    setFormulario({
                      ...formulario,
                      nombreClassification: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="descripcionClassification">Descripción</Label>
                <Input
                  type="text"
                  id="descripcionClassification"
                  value={formulario.descripcionClassification}
                  onChange={(e) =>
                    setFormulario({
                      ...formulario,
                      descripcionClassification: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="estatusClassification">Estado</Label>
                <Input
                  type="select"
                  id="estatusClassification"
                  value={formulario.estatusClassification}
                  onChange={(e) =>
                    setFormulario({
                      ...formulario,
                      estatusClassification: e.target.value,
                    })
                  }
                >
                  <option value="activo">Activo</option>
                  <option value="inactivo">Inactivo</option>
                </Input>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              className="button-save text-primary"
              onClick={
                isEditing ? editarClassification : guardarNuevoClassification
              }
            >
              {isEditing ? "Guardar Edición" : "Guardar"}
            </Button>
            <Button
              className="button-cancel text-secondary"
              onClick={closeModal}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};
export default ClassificationForm;
