// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
    display: flex !important;
    list-style: none;
    padding: 0;
    justify-content: center !important;
    align-items: center;
    /* width: 5rem; */
  }
  
  .pagination__item {
    margin: 0 5px;
    display: inline-block;
  }
  
  .pagination__link {
    text-decoration: none;
    padding: 8px 12px;
    color: #333;
  }
  
  .pagination__link--active {
    background-color: #e4e7e9;
    border-radius: 2rem;
    color: #0623cc;
    text-decoration: none;
  }
  
  .even-row {
    background-color: #f2f2f2; /* Color para filas pares */
  }
  
  .odd-row {
    background-color: #ffffff; /* Color para filas impares */
  }

  .pagination__content {
    display: flex !important;
    justify-content: center !important;
  }

  .pagination__arrow a{
    text-decoration: none;
    padding: 0 4px;
  }
  
  .modal-dialog {
    max-width: 90%; /* Ajusta según tus necesidades */
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/pagination.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,gBAAgB;IAChB,UAAU;IACV,kCAAkC;IAClC,mBAAmB;IACnB,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,qBAAqB;EACvB;;EAEA;IACE,qBAAqB;IACrB,iBAAiB;IACjB,WAAW;EACb;;EAEA;IACE,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,yBAAyB,EAAE,2BAA2B;EACxD;;EAEA;IACE,yBAAyB,EAAE,6BAA6B;EAC1D;;EAEA;IACE,wBAAwB;IACxB,kCAAkC;EACpC;;EAEA;IACE,qBAAqB;IACrB,cAAc;EAChB;;EAEA;IACE,cAAc,EAAE,iCAAiC;EACnD","sourcesContent":[".pagination {\n    display: flex !important;\n    list-style: none;\n    padding: 0;\n    justify-content: center !important;\n    align-items: center;\n    /* width: 5rem; */\n  }\n  \n  .pagination__item {\n    margin: 0 5px;\n    display: inline-block;\n  }\n  \n  .pagination__link {\n    text-decoration: none;\n    padding: 8px 12px;\n    color: #333;\n  }\n  \n  .pagination__link--active {\n    background-color: #e4e7e9;\n    border-radius: 2rem;\n    color: #0623cc;\n    text-decoration: none;\n  }\n  \n  .even-row {\n    background-color: #f2f2f2; /* Color para filas pares */\n  }\n  \n  .odd-row {\n    background-color: #ffffff; /* Color para filas impares */\n  }\n\n  .pagination__content {\n    display: flex !important;\n    justify-content: center !important;\n  }\n\n  .pagination__arrow a{\n    text-decoration: none;\n    padding: 0 4px;\n  }\n  \n  .modal-dialog {\n    max-width: 90%; /* Ajusta según tus necesidades */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
