import "cally";
import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { useEffect, useState } from "react";
import {
  showConfirmationAlert,
  showErrorAlert,
  showInfoAlert,
  showSuccessEditAlert,
} from "./../../../components/alerts";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import "../../../styles/calendar.css";
// import Skeleton from 'react-loading-skeleton';
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import medicGeekApi from "../../../config/axiosConfig";
import { jwtDecode } from "jwt-decode";
dayjs.extend(customParseFormat);
var isBetween = require("dayjs/plugin/isBetween");
var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);


const PayList = ({ currentForm }) => {
  const [loading, setLoading] = useState(true); // Estado para controlar la carga
  const [pagos, setPagos] = useState([]); // Estado para los pagos
  const [pagosSeguro, setPagosSeguro] = useState([]); // Estado para los pagos
  const [patientCed, setPatientCed] = useState([]); // Estado para los pagos
  const [patientsMap, setPatientsMap] = useState({});
  const [filtroFechaInicio, setFiltroFechaInicio] = useState(dayjs().format("YYYY-MM-DD"));
  const [filtroFechaFin, setFiltroFechaFin] = useState(dayjs().format("YYYY-MM-DD"));
  const [pagosFiltrados, setPagosFiltrados] = useState([]);
  const [showForm] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const paysPerPage = 10;
  const [selectedPayLocal, setselectedPayLocal] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [fechaHoy, setFechaHoy] = useState(dayjs().format("YYYY/MM/DD"));
  const [selectedRange, setSelectedRange] = useState({
    from: null,
    to: null,
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [seguros, setSeguros] = useState([]);
  const [seguroSeleccionado, setSeguroSeleccionado] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [selectedPay, setSelectedPay] = useState(null); // Estado para el registro seleccionado
  const [monto, setMonto] = useState(""); // Estado para el campo de monto
  const [displayedMonto, setDisplayedMonto] = useState(''); // Guardar el monto formateado para la visualización

  const [profileData, setProfileData] = useState(null);

  const handleSeguroChange = (e) => {
    setSeguroSeleccionado(e.target.value);
    filtrarPagos(); // Asegúrate de llamar a filtrarPagos aquí
  };  
  
  // Función para actualizar filtroFechaInicio
  const handleFechaInicioChange = (fecha) => {
    setFiltroFechaInicio(fecha);
  };

  // Función para actualizar filtroFechaFin
  const handleFechaFinChange = (fecha) => {
    setFiltroFechaFin(fecha);
  };

  const fetchData = async () => {
    try {
      const response = await medicGeekApi.get("/pays", {
        params: {
          search: searchTerm,
          filtroFechaInicio: filtroFechaInicio,
          filtroFechaFin: filtroFechaFin,
          fechaHoy: fechaHoy,
        },
      });
  
      const sortedPays = response.data.sort((a, b) => a.payId - b.payId);
      const patientResponse = await medicGeekApi.get("/patients");
      const patientData = patientResponse.data;
  
      // Crear un mapa para el nombre completo de los pacientes
      const patientsNameMap = {};
      const patientsSeguroMap = {};
      const patientsCedulaMap = {};
  
      patientData.forEach((patient) => {
        // Mapear el nombre completo del paciente
        patientsNameMap[patient.pcteId] = `${patient.pcteNom || ""} ${patient.pcteNom2 || ""} ${patient.pcteApe1 || ""} ${patient.pcteApe2 || ""}`;
        patientsSeguroMap[patient.pcteId] = patient.seguroNm || 'SIN SEGURO';
        patientsCedulaMap[patient.pcteId] = patient.pcteNumDoc || 'SIN SEGURO';
      });
  
      // Ahora puedes agregar tanto el nombre del paciente como el seguroNm a los pagos
      const pagosConSeguro = sortedPays.map((pay) => ({
        ...pay,
        patientName: patientsNameMap[pay.pcteId] || 'No disponible', // Nombre del paciente
        seguroNm: patientsSeguroMap[pay.pcteId] || 'SIN SEGURO',  // Nombre del seguro
        pcteNumDoc: patientsCedulaMap[pay.pcteId] || 'SIN CEDULA',  // Nombre del seguro
      }));
  
      setPagos(sortedPays);
      setPagosSeguro(patientsSeguroMap);
      setPatientCed(patientsCedulaMap);
      setPatientsMap(patientsNameMap);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    fetchData(); // Refresca la data cuando cambian los filtros
    fetchProfile();
  }, [filtroFechaInicio, filtroFechaFin, seguroSeleccionado]);
  
  // Llamar a la función para filtrar pagos cuando cambien las fechas o los pagos
  useEffect(() => {
    filtrarPagos();
  }, [filtroFechaInicio, filtroFechaFin, seguroSeleccionado, pagos]);  
  
  useEffect(() => {
    const fetchSeguros = async () => {
      try {
        const segurosResponse = await medicGeekApi.get("/seguros");
        const segurosData = segurosResponse.data.filter(
          (seguro) => seguro.estatus === "Activo"
        );
        setSeguros(segurosData);
      } catch (error) {
        console.error("Error fetching seguros:", error);
      }
    };
  
    fetchSeguros();
  }, []);

  const fetchProfile = async () => {
    try {
      // Obtener el token desde localStorage
      const token = localStorage.getItem("token");
      if (token) {
        // Decodificar el token para obtener el nombre de usuario
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username; // Asegúrate de que 'username' sea correcto

        if (username) {
          // Buscar perfil por nombre de usuario
          const response = await medicGeekApi.get(`/profile/user/${username}`);
          const data = response.data;

          // Verificar si se recibió el perfil
          if (data) {
            setProfileData(data);
            // setIsEditing(true);
          } else {
            console.error("No se encontró el perfil del usuario.");
          }
        } else {
          console.error("No se pudo obtener el nombre de usuario del token.");
        }
      } else {
        console.error("No se encontró el token.");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  // Manejar el cambio en los inputs de fecha
  const handleDateChange = (e) => {
      const { id, value } = e.target;
      if (id === "filtroFechaInicio") {
          setFiltroFechaInicio(value);  // Actualiza la fecha de inicio
      }
      if (id === "filtroFechaFin") {
          setFiltroFechaFin(value);  // Actualiza la fecha de fin
      }
  };

  const formatDateColumn = (date) => {
    if (!date) return "Fecha no disponible";  // Manejo de fechas nulas
    return dayjs(date, "DD/MM/YYYY hh:mm A").format("DD/MM/YYYY");  // Ajusta el formato a lo que necesitas
  };
  
  const filtrarPagos = () => {
    const pagosFiltrados = pagos.filter((pago) => {
      if (!pago?.payFec) return false;
  
      const fechaPago = dayjs(pago?.payFec, "DD/MM/YYYY hh:mm A");
      const fechaHoyFormatted = dayjs(fechaHoy, "YYYY-MM-DD").startOf("day");
  
      // Incluye pagos de la fecha actual
      if (fechaPago.isSame(fechaHoyFormatted, 'day')) return true;
  
      const fechaInicioFormatted = filtroFechaInicio ? dayjs(filtroFechaInicio, "YYYY-MM-DD").startOf("day") : null;
      const fechaFinFormatted = filtroFechaFin ? dayjs(filtroFechaFin, "YYYY-MM-DD").endOf("day") : null;
  
      // Verificar si hay filtros de fecha aplicados
      if (fechaInicioFormatted && fechaFinFormatted) {
        return fechaPago.isBetween(fechaInicioFormatted, fechaFinFormatted, null, '[]'); // Incluir límites
      }
  
      // Filtrar por seguro seleccionado
      if (seguroSeleccionado && seguroSeleccionado !== "") {
        return pago.seguroNm === seguroSeleccionado;
      }
  
      return true; // Si no hay filtros aplicados
    });
  
    setPagosFiltrados(pagosFiltrados); // Actualiza los pagos filtrados
  };
  
  const formatDateTimeColumn = (date) => {
    if (date === null) {
      return "";
    }
    return dayjs(date).format("YYYY/MM/DD h:mm A");
  };

  const isDateBetween = (dateToCheck, startDate, endDate) => {
    const date = dayjs(dateToCheck);
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    return date.isAfter(start) && date.isBefore(end);
  };

  const handleDateRangeChange = (ranges) => {
    const { selection } = ranges;
    const startDate = dayjs(selection.startDate).format("YYYY/MM/DD");
    const endDate = dayjs(selection.endDate).format("YYYY/MM/DD");
    setFiltroFechaInicio(startDate);
    setFiltroFechaFin(endDate);
  };

  const pageCount = Math.ceil(pagosFiltrados.length / paysPerPage);

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const displayedPay = pagosFiltrados
  .filter((pay) => {
    return (
      (typeof pay.payId === 'string' && pay.payId.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (typeof pay.pcteId === 'string' && pay.pcteId.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  })
  .slice(pageNumber * paysPerPage, (pageNumber + 1) * paysPerPage);

  // Contador de suma columna
  const totalAmount = pagosFiltrados.reduce(
    (total, pay) => total + parseFloat(pay.payCt),
    0
  );
  const currentDate = dayjs().format("DD-MM-DD"); // Obtiene la fecha actual

  const handleDayClick = (day) => {
    if (!filtroFechaInicio || (filtroFechaInicio && filtroFechaFin)) {
      setFiltroFechaInicio(day);
      setFiltroFechaFin(null);
    } else {
      setFiltroFechaFin(day);
    }
  };

  const handlePrintPDF = () => {
    const doc = new jsPDF();
    const totalPagesExp = "{total_pages_count_string}";

    // Información estática para el PDF
    const header = "Listado de Pagos"; // Encabezado del documento
    const currentDate = dayjs().format("DD/MM/YYYY"); // Fecha actual

    // Información dinámica del PDF
    const fechaInicio = filtroFechaInicio
      ? dayjs(filtroFechaInicio).format("DD/MM/YYYY")
      : "-";
    const fechaFin = filtroFechaFin
      ? dayjs(filtroFechaFin).format("DD/MM/YYYY")
      : "-";
    const totalRegistros = pagosFiltrados.length;
    const totalMonto = totalAmount.toFixed(2); // Total de monto formateado

    // Contenido del PDF
    const content = [
      { label: "Fecha de inicio:", value: fechaInicio },
      { label: "Fecha de fin:", value: fechaFin },
      { label: "Total de registros:", value: totalRegistros.toString() },
      { label: "Total de monto:", value: totalMonto },
      // Puedes agregar más campos según sea necesario
    ];

    // Añadir el encabezado al documento
    doc.setFontSize(18);
    doc.text(header, 14, 22);

    // Añadir la fecha actual al documento
    doc.setFontSize(12);
    doc.text(`Fecha: ${currentDate}`, 14, 30);

    // Añadir los campos y su contenido al documento
    doc.setFontSize(12);
    content.forEach((item, index) => {
      doc.text(`${item.label} ${item.value}`, 14, 40 + index * 10);
    });

    // Añadir el listado de pagos al documento (si hay pagos filtrados)
    if (pagosFiltrados.length > 0) {
      doc.autoTable({
        startY: 80, // Posición inicial de la tabla
        head: [["Recibo No.", "Fecha", "Paciente", "Cantidad", "Nota"]], // Encabezados de la tabla
        body: pagosFiltrados.map((pay) => [
          pay.payId,
          formatDateColumn(pay.payFec),
          patientsMap[pay.pcteId] || "Nombre no encontrado",
          parseFloat(pay.payCt).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          pay.payDesc,
        ]),
        theme: "grid", // Estilo de la tabla
        styles: { fontSize: 10 }, // Estilo del texto
      });
    }

    // Agregar el número total de páginas
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(
        14,
        doc.internal.pageSize.height - 10,
        `Página ${i} de ${pageCount}`
      );
    }

    // Descargar el PDF
    doc.save("pay-list.pdf");
  };

  const handleEditClick = (pay) => {
    setSelectedPay(pay);
    setMonto(formatNumber(pay.payCt));  // Aplicar el formato al monto seleccionado
    setShowModal(true);
  };

  const handleFocus = (event) => {
    event.target.select(); // Selecciona todo el texto del input
  };  
  
  const formatNumber = (value) => {
    return new Intl.NumberFormat("es-DO", {
      style: "currency",
      currency: "DOP",
      minimumFractionDigits: 0,
    }).format(value);
  };
  
  const handleMontoChange = (e) => {
    // Eliminar el prefijo RD$ y comas del valor antes de procesarlo
    const rawValue = e.target.value.replace(/RD\$/g, '').replace(/,/g, '');
  
    // Solo permitir valores numéricos o vacíos
    if (!isNaN(rawValue) || rawValue === "") {
      const numericValue = rawValue === "" ? "" : Number(rawValue); // Convertir a número
      
      // Actualizar el estado con el valor plano para almacenamiento
      setMonto(numericValue); // Guardar como número plano
      
      // También puedes actualizar la visualización con el formato de moneda si deseas
      const formattedValue = rawValue === "" ? "" : formatNumber(numericValue);
      setDisplayedMonto(formattedValue); // Actualizar el monto mostrado en el input
    }
  };

  // Función para guardar los cambios
  const handleSaveChanges = async () => {
    // Mostrar una alerta de confirmación antes de proceder
    const confirmResult = await showConfirmationAlert(
      "Confirmación",
      "¿Estás seguro de que deseas editar este pago?"
    );
  
    if (confirmResult.isConfirmed) {
      try {
        // Realizar la solicitud PUT o PATCH para actualizar el pago
        const response = await medicGeekApi.patch(`/pays/${selectedPay.payId}`, {
          payCt: monto, // El nuevo monto que el usuario editó
          pcteId: selectedPay.pcteId, // ID del paciente (si es necesario)
          // Otros campos que quieras actualizar
        });
  
        // Si la respuesta es exitosa, refrescar la lista de pagos
        if (response.status === 200) {
          fetchData();  // Volver a cargar los datos de pagos
          setShowModal(false);  // Cerrar el modal
          showSuccessEditAlert("El pago se ha actualizado correctamente."); // Mostrar alerta de éxito
        }
      } catch (error) {
        console.error("Error al actualizar el pago:", error);
        showErrorAlert("Ocurrió un error al intentar actualizar el pago."); // Mostrar alerta de error
      }
    } else {
      // Opción de cancelar la edición
      showInfoAlert("Edición cancelada.", "info");
    }
  };

  const convertirFechaLargaRecibo = (fecha) => {
    const opciones = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return fecha.toLocaleDateString('es-ES', opciones);
  };

  const numeroALetras = (num) => {
    const unidades = ["", "UNO", "DOS", "TRES", "CUATRO", "CINCO", "SEIS", "SIETE", "OCHO", "NUEVE"];
    const especiales = ["DIEZ", "ONCE", "DOCE", "TRECE", "CATORCE", "QUINCE", "DIECISEIS", "DIECISIETE", "DIECIOCHO", "DIECINUEVE"];
    const decenas = ["", "", "VEINTE", "TREINTA", "CUARENTA", "CINCUENTA", "SESENTA", "SETENTA", "OCHENTA", "NOVENTA"];
    const centenas = ["", "CIENTO", "DOSCIENTOS", "TRESCIENTOS", "CUATROCIENTOS", "QUINIENTOS", "SEISCIENTOS", "SETECIENTOS", "OCHOCIENTOS", "NOVECIENTOS"];

    if (num === 0) return "CERO";

    let letras = "";

    // Miles
    if (Math.floor(num / 1000) > 0) {
        if (Math.floor(num / 1000) === 1) {
            letras += "MIL ";
        } else {
            letras += numeroALetras(Math.floor(num / 1000)) + " MIL ";
        }
        num %= 1000;
    }

    // Centenas
    if (Math.floor(num / 100) > 0) {
        letras += centenas[Math.floor(num / 100)] + " ";
        num %= 100;
    }

    // Decenas y unidades especiales (10-19)
    if (num >= 10 && num < 20) {
        letras += especiales[num - 10] + " ";
    } else {
        // Decenas
        if (Math.floor(num / 10) > 1) {
            letras += decenas[Math.floor(num / 10)];
            if (num % 10 > 0) letras += " Y ";
        }

        // Unidades
        letras += unidades[num % 10] + " ";
    }

    return letras.trim();
  };

  const convertirpayCtALetras = (valor) => {
    if (valor === undefined || isNaN(valor)) return "CERO PESOS";
    
    const [parteEntera, decimales] = parseFloat(valor).toFixed(2).split(".");
    const cantidadEnLetras = numeroALetras(parseInt(parteEntera, 10));
    const centavosEnLetras = decimales === "00" ? "" : `CON ${numeroALetras(parseInt(decimales, 10))} CENTAVOS`;

    return `${cantidadEnLetras} PESOS ${centavosEnLetras}`.trim();
  };

  const previsualizarPDF = async (pay) => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 10;
    const additionalMargin = 40;
    const fontSize = 10;

    // const fechaFormateada = convertirFechaLargaRecibo();

    doc.setFontSize(fontSize);

    const fechaLarga = new Date(pay.payFec).toLocaleDateString();
    const cantidadEnLetras = convertirpayCtALetras(pay.payCt);
    const nombreCompleto = pay.patient
        ? `${pay.patient.pcteNom || ""} ${pay.patient.pcteNom2 || ""} ${pay.patient.pcteApe1 || ""} ${pay.patient.pcteApe2 || ""}`
        : "OTROS INGRESOS";

    // Título
    doc.setFontSize(22);
    doc.setFont(undefined, "bold");
    const detalleText = "RECIBO DE PAGO";
    const textWidth = doc.getTextWidth(detalleText);
    const centeredX = (pageWidth - textWidth) / 2;
    doc.text(detalleText, centeredX, margin + additionalMargin);
    doc.setFont(undefined, "normal");

    // Información del paciente y recibo
    doc.setFontSize(12);
    const profileText = `Recibimos en el día de hoy de parte de ${patientsMap[pay.pcteId] || ""}. Cédula de identidad número ${patientCed[pay.pcteId] || ""}, la suma de ${parseFloat(pay.payCt).toLocaleString("es-DO", {style: "currency", currency: "DOP", minimumFractionDigits: 2, maximumFractionDigits: 2})} (${cantidadEnLetras}), por consulta realizada en el día ${pay.payFec}.`;

    const profileLines = doc.splitTextToSize(profileText, pageWidth - 2 * margin);
    let y = margin + additionalMargin + 20;
    const lineHeight = fontSize * 0.6;

    profileLines.forEach((line) => {
        if (y + lineHeight > pageHeight - margin - additionalMargin - lineHeight) {
            doc.addPage();
            y = margin + additionalMargin;
        }
        doc.text(line, margin, y);
        y += lineHeight;
    });

    // Nombre y exequátur del doctor
    const alturaNombre = y + 70;
    const lineaY = alturaNombre - 5;
    const lineaX = (pageWidth - 80) / 2;
    doc.setLineWidth(0.5);
    doc.line(lineaX, lineaY, lineaX + 80, lineaY);

    const nombreDoctor = profileData.docNom || "Doctor";
    doc.setFontSize(12);
    const nombreX = (pageWidth - doc.getTextWidth(nombreDoctor)) / 1.41;
    doc.text(nombreDoctor, nombreX, alturaNombre, { align: "center" });

    const exequaturText = `Exequátur: ${profileData.docExe || "N/A"}`;
    doc.text(exequaturText, nombreX, alturaNombre + 5, { align: "center" });

    // Convertir el PDF a Blob y generar la vista previa/imprimir
    const pdfBlob = doc.output('blob');
    let iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);

    const blobUrl = URL.createObjectURL(pdfBlob);
    iframe.src = blobUrl;

    iframe.onload = () => {
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
        iframe.contentWindow.onafterprint = () => {
            document.body.removeChild(iframe);
            URL.revokeObjectURL(blobUrl);
        };
    };
  };

  return (
    <div>
      <div>
        <div
          className="d-flex justify-content-between miDivConGradiente px-3 rounded-bottom"
          style={{
            height: "5rem",
          }}
        >
          <div className="d-flex align-items-center text-white fw-bold text-uppercase">
            <h2>Reporte Cobros de Consultas</h2>
          </div>
          <div className="d-flex align-items-center">
            <button
              type="button"
              className="btn btn-success"
              title="Crear Nuevo Color"
              onClick={handlePrintPDF}
            >
              <i className="bbi bi-printer-fill"></i> Imprimir Listado
            </button>
          </div>
        </div>
        <div id="pay-list">
          <div
            className="mb-2"
            style={{
              padding: "0rem 1rem",
            }}
          >
            <div
              className="miDivConGradienteSmall rounded text-white fw-bold fs-5 my-2 px-2 d-flex justify-content-center py-2 gap-2"
              style={{ height: "3rem" }}
              >
                <div className="d-flex gap-2">
                  <label>Desde:</label>
                  <input
                    type="date"
                    id="filtroFechaInicio"
                    value={filtroFechaInicio}
                    onChange={(e) => setFiltroFechaInicio(e.target.value)}
                  />

                  <label>Hasta:</label>
                  <input
                    type="date"
                    id="filtroFechaFin"
                    value={filtroFechaFin}
                    onChange={(e) => setFiltroFechaFin(e.target.value)}
                  />

                  {/* <label>Seguro:</label>
                  <select id="seguroNm" onChange={handleSeguroChange}>
                    <option value="">Todos</option>
                    {seguros.map((seguro) => (
                      <option key={seguro.seguroId} value={seguro.seguroNm}>
                        {seguro.seguroNm}
                      </option>
                    ))}
                  </select> */}
                </div>
                
            </div>
            {/* FILTROS */}

            <div  style={{ height: "45vh", padding: "0rem 1rem", overflowY: "auto" }}>
              <table className="table table-striped table-hover mt-3">
                <thead>
                  <tr>
                    <th>Recibo No.</th>
                    <th>Fecha</th>
                    <th>Paciente</th>
                    <th>Cantidad</th>
                    <th>Seguro</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {displayedPay
                    .sort((a, b) => new Date(a.payFec) - new Date(b.payFec)) // Ordenar por fecha
                    .map((pay) => (
                      <tr key={pay.payId}>
                        <td style={{ width: "10%"}}>
                          {pay.payId}
                        </td>
                        <td style={{ width: "10%"}}>
                          <p>{formatDateColumn(pay.payFec)}</p>
                        </td>
                        <td style={{ width: "40%"}}>
                          {patientsMap[pay.pcteId] || ""}
                        </td>
                        <td style={{ width: "10%" }}>
                          {parseFloat(pay.payCt).toLocaleString("es-DO", {
                            style: "currency",
                            currency: "DOP",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td style={{ width: "40%" }}>
                          {pagosSeguro[pay.pcteId] || ""}
                        </td>
                        <td style={{ width: "5%"}}>
                          <div className="">
                            <button
                              type="button"
                              className="p-1 border-0"
                              title="editar"
                              id="hoverIcon"
                              onClick={() => handleEditClick(pay)} // Manejar el clic en el botón de editar
                            >
                              <i className="bi bi-pencil-square"></i>
                            </button>
                            <button
                                type="button"
                                className="p-1 border-0"
                                title="Print"
                                id="hoverIcon"
                                onClick={() => previsualizarPDF(pay)}
                              >
                                <i className="bbi bi-printer-fill"></i>
                              </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {/* Modal para editar el monto */}
            </div>
            <div>
              <Modal 
                isOpen={showModal}
                show={showModal} onHide={() => setShowModal(false)}
                 style={{
                  maxWidth: "50rem",
                  overlay: {
                  zIndex: 1002, // Ajusta el valor según sea necesario (mayor que el de los popovers)
                  },
                  content: {
                  zIndex: 1003, // Ajusta el valor según sea necesario (mayor que el de los popovers)
                  },
              }}>
                <ModalHeader closeButton>
                  <p>Editar Monto</p>
                </ModalHeader>
                <ModalBody>
                  {selectedPay && (
                    <div>
                      <p>Recibo No.: {selectedPay.payId}</p>
                      <p>Paciente: {patientsMap[selectedPay.pcteId] || ""}</p>
                      <p>Consulta No.: {selectedPay.consultId}</p>
                      <p>Fecha: {formatDateColumn(selectedPay.payFec)}</p>
                      <div className="form-group">
                        <Col>
                          <FormGroup>
                            <Input
                              style={{
                                height: "5rem",
                                fontSize: "3rem",
                                textAlign: "right",
                              }}
                              type="text"
                              name="monto"
                              id="monto"
                              placeholder="RD$0"
                              value={monto}
                              onChange={handleMontoChange}  // Llamar a la función cuando cambia el valor
                              onFocus={handleFocus}  // Selecciona el contenido cuando el input recibe foco
                            />
                          </FormGroup>
                        </Col>
                      </div>
                    </div>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Cancelar
                  </Button>
                  <Button variant="primary" onClick={handleSaveChanges}>
                    Guardar Cambios
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
            {/* Listado */}

            <div
              className="text-start miDivConGradienteSmall w-1 rounded text-uppercase text-white px-2 py-2 my-2 fs-5 d-flex justify-content-between"
              style={{ height: "3rem" }}
            >
              <label>
                Total de Registros:{" "}
                <span className="fw-bold text-decoration-underline">
                  {pagosFiltrados.length}
                </span>
              </label>
              <label>
                Total:{" "}
                <span className="fw-bold text-decoration-underline">
                  {totalAmount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </span>
              </label>
            </div>
            {/* Contador */}

            {!showForm && (
              <div className="d-flex justify-content-center mt-5">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                    <li className={`page-item`} style={{ cursor: "pointer" }}>
                      <span
                        className="page-link"
                        onClick={() => handlePageChange({ selected: 0 })}
                      >
                        Inicio
                      </span>
                    </li>
                    <li className={`page-item`} style={{ cursor: "pointer" }}>
                      <span
                        className="page-link"
                        style={{
                          height: "2.4rem",
                          width: "7rem",
                        }}
                        onClick={() =>
                          handlePageChange({
                            selected: Math.max(0, pageNumber - 10),
                          })
                        }
                      >
                        Anterior 10
                      </span>
                    </li>
                    {Array.from(
                      { length: Math.min(pageCount, 10) },
                      (_, index) => (
                        <li
                          key={index}
                          className={`page-item ${
                            pageNumber === index ? " active" : ""
                          }`}
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            className="page-link"
                            onClick={() =>
                              handlePageChange({ selected: index })
                            }
                          >
                            {index + 1}
                          </span>
                        </li>
                      )
                    )}
                    <li className={`page-item`} style={{ cursor: "pointer" }}>
                      <span
                        className="page-link"
                        style={{
                          height: "2.4rem",
                          width: "7.5rem",
                        }}
                        onClick={() =>
                          handlePageChange({
                            selected: Math.min(pageCount - 1, pageNumber + 10),
                          })
                        }
                      >
                        Siguiente 10
                      </span>
                    </li>
                    <li className={`page-item`} style={{ cursor: "pointer" }}>
                      <span
                        className="page-link"
                        onClick={() =>
                          handlePageChange({ selected: pageCount - 1 })
                        }
                      >
                        Final
                      </span>
                    </li>
                  </ul>
                </nav>
              </div>
            )}
            {/* Paginación */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default PayList;
