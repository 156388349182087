import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  showErrorAlert,
  showSuccessAlert,
  showSuccessEditAlert,
  showConfirmationAlert,
} from "../../../components/alerts";
import medicGeekApi from "../../../config/axiosConfig";

const CategoryList = ({ currentForm }) => {
  const [categorys, setCategorys] = useState([]);
  const [showForm] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const categorysPerPage = 10;
  const [selectedCategoryLocal, setSelectedCategoryLocal] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [paletaColor, setPaletaColor] = useState("");
  const [paletaColorMap, setPaletaColorMap] = useState("");
  const [paletaColorData, setPaletaColorData] = useState([]); // Define e inicializa paletaColorData

  // Skeleton
  const [loading, setLoading] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);

  const [formulario, setFormulario] = useState({
    nombreCategory: "",
    descripcionCategory: "",
    estatusCategory: "activo",
  });

  const openModal = (category) => {
    setSelectedCategoryLocal(category);
    if (category) {
      setIsEditing(true);
      const selectedColor = paletaColorMap[category.colorId];
      setFormulario({
        colorId: category.colorId, // Asegura que el colorId se establezca correctamente
        nombreCategory: category.categoryNm,
        descripcionCategory: category.categoryDesc,
        estatusCategory: category.estatus ? "activo" : "inactivo",
        colorRgb: selectedColor,
      });
    } else {
      setIsEditing(false);
      setFormulario({
        colorId: "", // Limpia el colorId al crear una nueva categoría
        nombreCategory: "",
        descripcionCategory: "",
        estatusCategory: "activo",
      });
    }
    setModalOpen(true);
  };
  

  const closeModal = () => {
    setSelectedCategoryLocal(null);
    setIsEditing(false);
    setModalOpen(false);
  };

  const handleSearchTermChange = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
  };

  useEffect(() => {
    fetchData();
  }, [searchTerm]);

  const fetchData = async () => {
    setLoading(true); // Iniciar la carga
    try {
      // Cargar las categorías
      const response = await medicGeekApi.get(`/categorys?search=${searchTerm}`);
      const sortedCategory = response.data.sort((a, b) => a.categoryId - b.categoryId);
  
      // Cargar los colores de la paleta desde la API
      const paletaColorResponse = await medicGeekApi.get("/paletaColors");
      const paletaColorData = paletaColorResponse.data;
      setPaletaColorData(paletaColorData); // Actualiza el estado con los datos de colores
      
  
      // Crear un mapa de colorId -> colorRgb
      const paletaColorMap = {};
      paletaColorData.forEach((paletaColor) => {
        paletaColorMap[paletaColor.colorId] = paletaColor.colorRgb;
      });
  
      // Combinar las categorías con sus colores
      const categoryDataWithColor = sortedCategory.map((category) => ({
        ...category,
        colorRgb: paletaColorMap[category.colorId] || "rgb(255, 0, 0)", // Color por defecto
      }));
  
      // Actualiza el estado de categorías con el color asignado
      setCategorys(categoryDataWithColor);
      setPaletaColorMap(paletaColorMap); // Guarda el mapa de colores
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Finalizar carga
    }
  };
  

  const pageCount = Math.ceil(categorys?.length / categorysPerPage);

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const displayedCategory = categorys
    .filter((category) => {
      // Filtrar category basados en el término de búsqueda
      return (
        category.categoryNm.toLowerCase().includes(searchTerm.toLowerCase()) ||
        category.categoryDesc.toLowerCase().includes(searchTerm.toLowerCase())
      );
    })
    .slice(pageNumber * categorysPerPage, (pageNumber + 1) * categorysPerPage);

  const guardarNuevaCategory = () => {
    if (!formulario.colorId) {
      showErrorAlert(
        "Debes seleccionar un color antes de Guardar la Categoria"
      );
      return;
    }
    const nuevoCategory = {
      categoryNm: document.getElementById("nombreCategory").value,
      categoryDesc: document.getElementById("descripcionCategory").value,
      colorId: formulario.colorId,
      estatus:
        document.getElementById("estatusCategory").value === "activo"
          ? "Activo"
          : "Inactivo",
    };
    
    medicGeekApi
      .post("/categorys", nuevoCategory)
      .then((response) => {
        showSuccessAlert();
        closeModal();
        fetchData();
      })
      .catch((error) => {
        console.error("Error al crear el categoria", error);
      });
  };

  const editarCategory = () => {
    if (!formulario.colorId) {
      showErrorAlert(
        "Debes seleccionar un color antes de Guardar la Categoria"
      );
      return;
    }
    if (selectedCategoryLocal && selectedCategoryLocal.categoryId) {
      const { categoryId } = selectedCategoryLocal;
      const nombreCategory = document.getElementById("nombreCategory").value;
      const descripcionCategory = document.getElementById(
        "descripcionCategory"
      ).value;
      const colorName = document.getElementById("colorCategory").value;
      const estatusCategory =
        document.getElementById("estatusCategory").value === "activo"
          ? "Activo"
          : "Inactivo";

      const updatedCategory = {
        categoryId, // Asegurarse de enviar el ID
        categoryNm: nombreCategory,
        categoryDesc: descripcionCategory,
        colorId: colorName,
        estatus: estatusCategory,
      };

      medicGeekApi
        .patch(`/categorys/${categoryId}`, updatedCategory)
        .then((response) => {
          showSuccessEditAlert();
          closeModal();
          fetchData();
          // updateCategoryList();
        })
        .catch((error) => {
          console.error("Error al actualizar la Categoria", error);
        });
    } else {
      // Manejo de error o mensaje de que no se puede actualizar
      showErrorAlert("No se puede actualizar: Categoria no válido.");
    }
  };

  const eliminarCategory = (categoryId) => {
    const confirmacion = window.confirm(
      "¿Estás seguro de que deseas eliminar esta categoría?"
    );
  
    if (!confirmacion) {
      return;
    }
  
    medicGeekApi
      .delete(`/categorys/${categoryId}`)
      .then((response) => {
        showSuccessAlert("Categoría eliminada exitosamente.");
        fetchData(); // Vuelve a cargar los datos después de eliminar
      })
      .catch((error) => {
        console.error("Error al eliminar la categoría", error);
        showErrorAlert("Hubo un error al eliminar la categoría.");
      });
  };

  const SkeletonTableRow = () => (
    <tr>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "60px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "150px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "100px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "70px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "200px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "100px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "60px" }}></div>
      </td>
    </tr>
  );
  
  
  
  return (
    <div>
      <div>
        <div className="d-flex flex-column flex-md-row justify-content-between miDivConGradiente px-3 rounded-bottom">
          <div className="d-flex align-items-center text-white mb-2 mb-md-0">
            <h2>Categoría</h2>
          </div>
          <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-md-0">
            <form className="d-flex" role="search">
              <input
                className="form-control me-2"
                type="search"
                placeholder="Buscar"
                aria-label="Buscar"
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
            </form>
            <button
              type="button"
              className="btn btn-danger"
              title="Crear Nueva Categoria"
              onClick={() => openModal(null)}
            >
              <i className="bi bi-file-plus"></i> Nueva Categoría
            </button>
          </div>
        </div>
        
        <div
          className="mb-2"
          style={{
            height: "45vh",
            padding: "0rem 1rem",
          }}
        >
          {/* Tabla en pantallas medianas y grandes */}
          <table className="table table-striped table-hover mt-5 d-none d-md-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre de la Categoría</th>
                <th>Descripción de la Categoría</th>
                <th>Color</th>
                <th>Estatus</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
            {loading ? (
              // Mostrar 5 filas de skeleton mientras carga
              Array.from({ length: 15 }).map((_, index) => <SkeletonTableRow key={index} />)
            ) : (
              displayedCategory.map((category) => (
                <tr key={category.categoryId}>
                  <td style={{ width: "5%" }}>{category.categoryId}</td>
                  <td style={{ width: "20%" }}>{category.categoryNm}</td>
                  <td style={{ width: "40%" }}>{category.categoryDesc}</td>
                  <td
                    className="rounded-circle"
                    style={{
                      width: "1%",
                      backgroundColor: category.colorRgb,
                      height: "1rem",
                      margin: "1rem",
                    }}
                  ></td>
                  <td style={{ width: "10%" }}>{category.estatus}</td>
                  <td style={{ width: "5%" }}>
                    <div className="">
                      <button
                        type="button"
                        className="p-1 border-0"
                        title="Editar"
                        id="hoverIcon"
                        onClick={() => openModal(category)}
                      >
                        <i className="bi bi-pencil-square"></i>
                      </button>
                      <button
                        type="button"
                        className="p-1 border-0"
                        title="Eliminar"
                        id="hoverIcon"
                        onClick={() => eliminarCategory(category.categoryId)}
                      >
                        <i className="bi bi-trash"></i>
                      </button>

                    </div>
                  </td>
                </tr>
             ))
            )}
            </tbody>
          </table>
  
          {/* Modo "card" en dispositivos móviles */}
          <div className="d-md-none">
            <div className="row">
            {loading ? (
              Array.from({ length: 5 }).map((_, index) => (
                <div className="col-12 mb-3" key={index}>
                  <div className="card">
                    <div className="card-body">
                      <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "60%" }}></div>
                      <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "80%" }}></div>
                      <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "70%" }}></div>
                      <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "90%" }}></div>
                      <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "75%" }}></div>
                      <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "50%" }}></div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              displayedCategory.map((category) => (
                <div key={category.categoryId} className="card mb-3">
                  <div className="card-body">
                    <h5 className="card-title">
                      {category.categoryNm} (ID: {category.categoryId})
                    </h5>
                    <p className="card-text">Descripción: {category.categoryDesc}</p>
                    <p className="card-text">
                      Color: 
                      <span
                        style={{
                          display: 'inline-block',
                          width: '20px',
                          height: '20px',
                          backgroundColor: category.colorRgb,
                          borderRadius: '50%',
                          marginLeft: '10px',
                        }}
                      ></span>
                      {category.colorRgb}
                    </p>
                    <p className="card-text">Estatus: {category.estatus}</p>
                    <div className="d-flex justify-content-between">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => openModal(category)}
                      >
                        Editar
                      </button>
                      <button type="button" className="btn btn-danger">
                        Eliminar
                      </button>
                    </div>
                  </div>
                </div>
              ))
              )}
            </div>
          </div>

  
          {/* Paginación */}
          {!showForm && (
            <div className="d-flex justify-content-end">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  {/* <li className={`page-item`} style={{ cursor: "pointer" }}>
                    <span
                      className="page-link"
                      onClick={() => handlePageChange({ selected: 0 })}
                    >
                      Inicio
                    </span>
                  </li> */}
                  {/* <li className={`page-item`} style={{ cursor: "pointer" }}>
                    <span
                      className="page-link"
                      style={{ height: "2.4rem", width: "7rem" }}
                      onClick={() =>
                        handlePageChange({
                          selected: Math.max(0, pageNumber - 10),
                        })
                      }
                    >
                      Anterior 10
                    </span>
                  </li> */}
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  {Array.from(
                    { length: Math.min(pageCount, 10) },
                    (_, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          pageNumber === index ? " active" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          className="page-link"
                          onClick={() => handlePageChange({ selected: index })}
                        >
                          {index + 1}
                        </span>
                      </li>
                    )
                  )}
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                  {/* <li className={`page-item`} style={{ cursor: "pointer" }}>
                    <span
                      className="page-link"
                      style={{ height: "2.4rem", width: "7.5rem" }}
                      onClick={() =>
                        handlePageChange({
                          selected: Math.min(pageCount - 1, pageNumber + 10),
                        })
                      }
                    >
                      Siguiente 10
                    </span>
                  </li> */}
                  {/* <li className={`page-item`} style={{ cursor: "pointer" }}>
                    <span
                      className="page-link"
                      onClick={() =>
                        handlePageChange({ selected: pageCount - 1 })
                      }
                    >
                      Final
                    </span>
                  </li> */}
                </ul>
              </nav>
            </div>
          )}
        </div>
  
        {/* Modal para creación/edición */}
        {currentForm === "categoryForm" && selectedCategoryLocal && (
          <div className="main-content"></div>
        )}
        <Modal isOpen={modalOpen} toggle={closeModal}>
          <ModalHeader toggle={closeModal}>
            {isEditing ? "Editar Categoría" : "Crear Nueva Categoría"}
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="nombreCategory">Nombre de la Categoría</Label>
                <Input
                  type="text"
                  id="nombreCategory"
                  value={formulario.nombreCategory}
                  onChange={(e) =>
                    setFormulario({
                      ...formulario,
                      nombreCategory: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="descripcionCategory">Descripción</Label>
                <Input
                  type="text"
                  id="descripcionCategory"
                  value={formulario.descripcionCategory}
                  onChange={(e) =>
                    setFormulario({
                      ...formulario,
                      descripcionCategory: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="colorCategory">Color</Label>
                <Input
                  type="select"
                  id="colorCategory"
                  value={formulario.colorId} // Selecciona el color actual
                  onChange={(e) =>
                    setFormulario({
                      ...formulario,
                      colorId: e.target.value, // Actualiza el colorId seleccionado
                    })
                  }
                >
                  <option value="">Selecciona un color</option>
                  {paletaColorData.map((color) => (
                    <option key={color.colorId} value={color.colorId}>
                      {color.colorName || color.name} {/* Mostrar el nombre del color */}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="estatusCategory">Estado</Label>
                <Input
                  type="select"
                  id="estatusCategory"
                  value={formulario.estatusCategory}
                  onChange={(e) =>
                    setFormulario({
                      ...formulario,
                      estatusCategory: e.target.value,
                    })
                  }
                >
                  <option value="activo">Activo</option>
                  <option value="inactivo">Inactivo</option>
                </Input>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              className="button-save text-primary"
              onClick={isEditing ? editarCategory : guardarNuevaCategory}
            >
              {isEditing ? "Guardar Edición" : "Guardar"}
            </Button>
            <Button className="button-cancel text-secondary" onClick={closeModal}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
  
};
export default CategoryList;
