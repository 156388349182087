import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import Fullcalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import React, { useEffect, useState, useRef } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import medicGeekApi from "../../config/axiosConfig";
import { API_BASE_URL } from "../../config/constants";
import {
  showConfirmationAlert,
  showErrorAlert,
  showSuccessAlert,
  showSuccessEditAlert,
} from "./../../components/alerts";
import ConsultaForm from "./../Consulta/ConsultaForm";
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';  // O el tema que prefieras
import { Spanish } from 'flatpickr/dist/l10n/es.js';  // Importa la localización en español


const MyCalendar = ({ setSelectedPaciente, setCurrentForm, currentForm }) => {
  const [events, setEvents] = useState([]);
  const [selectedPacienteLocal, setSelectedPacienteLocal] = useState(null);
  const [selectedFormulario, setSelectedFormulario] = useState("");

  const navigate = useNavigate();
  const searchInputRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCalendarLocal, setselectedCalendarLocal] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [pacientes, setPacientes] = useState([]);
  const [paciente, setPaciente] = useState([]);
  const [infEvento, setInfEvento] = useState([]);
  const [formulario, setFormulario] = useState({
    categoriaNm: "",
    horaFormateada: "",
    fechaCita: new Date().toISOString().slice(0, 10),
    nombrePaciente: "",
    nombreDelPaciente: "",
    direccionPaciente: "",
    contactoPaciente: "",
    emailPaciente: "",
    notaCita: "",
    estatusCalendar: "Activo",
    pacienteId: "",
  });
  const [formularioInicial, setFormularioInicial] = useState({});
  const [categorias, setCategorias] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [isPacienteModalOpen, setIsPacienteModalOpen] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isButtonDeleteDisabled, setIsButtonDeleteDisabled] = useState(false);
  const [pacienteSeleccionado, setPacienteSeleccionado] = useState(null);
  const [pacienteConsulta, setPacienteConsulta] = useState(null);
  const [categoriasMap, setCategoriasMap] = useState({});
  const [paletaColorData, setPaletaColorData] = useState([]); // Define e inicializa paletaColorData
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [selected, setSelected] = useState(false);

  const datePickerRef = useRef(null);  // Define the datePickerRef properly

  //  Enviar Paciente a Consulta

  const [showForm, setShowForm] = useState(false);

  // Pantalla de Carga
  const [isLoading, setIsLoading] = useState(false); // Estado de carga

  const openModal = (calendar, customPageSize = 10) => {
    setselectedCalendarLocal(calendar);
    setPageSize(customPageSize);

    if (calendar) {
      setIsEditing(true);
      setFormulario({
        categoryId: calendar.categoryId,
        categoriaNm: calendar.categoryNm,
        nombrePaciente: calendar.nombrePaciente || "",
        direccionPaciente: calendar.direccionPaciente,
        contactoPaciente: calendar.contactoPaciente,
        emailPaciente: calendar.emailPaciente,
        categoria: calendar.categoryId,
        fechaCita: calendar.fechaCita,
        horaFormateada: calendar.horaFormateada,
        notaCita: calendar.notaCita,
        estatusCalendar: calendar.estatusCalendar,

        nombreDelPaciente: calendar.nombreDelPaciente || "",
      });
      setSelectedPaciente(calendar.paciente);
      setFormularioInicial({ ...formulario });
    } else {
      setIsEditing(false);
      setFormulario({
        categoriaNm: "",
        nombrePaciente: "",
        direccionPaciente: "",
        contactoPaciente: "",
        emailPaciente: "",
        categoria: "",
        fechaCita: "",
        horaFormateada: "",
        notaCita: "",
        estatusCalendar: "Nuevo",
      });
      setSelectedPaciente(null);
      setFormularioInicial({});
    }
    setModalOpen(true);
    setIsButtonDisabled(false);
    setIsButtonDeleteDisabled(true);
  };

  const closeModal = () => {
    setselectedCalendarLocal(null);
    setIsEditing(false);
    setModalOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (datePickerRef.current) {
      Flatpickr(datePickerRef.current, {
        defaultDate: formulario.fechaCita || new Date(),  // Usar formulario.fechaCita o fecha actual
        onChange: (selectedDate) => {
          if (selectedDate.length > 0) {
            // Formato de fecha a DD/MM/YYYY
            const fechaFormateada = `${String(selectedDate[0].getDate()).padStart(2, '0')}/${String(selectedDate[0].getMonth() + 1).padStart(2, '0')}/${selectedDate[0].getFullYear()}`;
            setFormulario((prevFormulario) => ({
              ...prevFormulario,
              fechaCita: fechaFormateada,  // Actualizar con la fecha formateada
            }));
          }
        },
        locale: {
          ...Spanish,  // Usar la localización en español
          firstDayOfWeek: 0,  // Iniciar la semana en domingo
        },
        dateFormat: 'd/m/Y', // Mostrar en formato día/mes/año
      });
    }
  }, [formulario.fechaCita]);  // Re-ejecutar este efecto cuando fechaCita cambia

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const patientsResponse = await medicGeekApi.get(
        `/patients?search=${searchTerm}`,
        {
          params: {
            $or: [
              { pcteNom: { $regex: searchTerm, $options: "i" } },
              { pcteNom2: { $regex: searchTerm, $options: "i" } },
              { pcteApe1: { $regex: searchTerm, $options: "i" } },
            ],
          },
        }
      );

      const sortedPacientes = patientsResponse.data.sort(
        (a, b) => a.pcteId - b.pcteId
      );
      setPacientes(sortedPacientes);

      const [
        calendarResponse,
        categoriasResponse,
        paletaColorResponse,
      ] = await Promise.all([
        medicGeekApi.get(`/calendars/detail`),
        medicGeekApi.get(`/categorys/detail`),
        medicGeekApi.get(`/paletaColors`),
      ]);

      // Cargar los datos de los eventos del calendario desde el backend
      const calendarData = calendarResponse.data;
      const categoriasData = categoriasResponse.data;
      const paletaColorData = paletaColorResponse.data;
      
      setPaletaColorData(paletaColorData); // Actualiza el estado con los datos

      // Mapea las categorías a un mapa para futuras referencias
      const categoriasMap = {};
      categoriasData.forEach((categoria) => {
        categoriasMap[categoria.categoryNm] = categoria.categoryId;
      });
      // Crear un mapa para buscar colores por colorId
      const paletaColorMap = {};
      paletaColorData.forEach((paletaColor) => {
        paletaColorMap[paletaColor.colorId] = paletaColor.colorRgb;
      });

      // Obtener el colorRgb correspondiente para cada categoría
      const categoryDataWithColor = paletaColorData.map((category) => ({
        ...category,
        colorRgb: paletaColorMap[category.colorId] || "rgb(255, 0, 0)", // Proporciona un color por defecto en caso de que no haya una correspondencia
      }));

      // Ordena los eventos por hora
      const eventosOrdenados = calendarData.sort((a, b) => {
        const horaEventoA = a.horaCita;
        const horaEventoB = b.horaCita;
        return horaEventoA.localeCompare(horaEventoB);
      });

      // Mapea los datos del calendario al formato esperado por FullCalendar
      const formattedEvents = eventosOrdenados
        .filter(
          (event) =>
            event.fechaCita && event.horaCita && event.estatus === "Activo"
        ) // Filtra eventos válidos
        .map((event) => {
          const categoryId = categoriasMap[event.category.categoryNm] || null;
          const paletaColorId = categoryId
            ? categoriasData.find(
                (categoria) => categoria.categoryId === categoryId
              ).colorId
            : null;
          const colorRgb = paletaColorMap[paletaColorId] || "rgb(255, 0, 0)"; // Proporciona un color por defecto en caso de que no haya una correspondencia
          const paginate = (pageNumber) => {
            setCurrentPage(pageNumber);
          };

          const pageSize = 5; // Número de pacientes por página

          const totalPacientes = pacientes.length;
          const indexOfLastPaciente = currentPage * pageSize;
          const indexOfFirstPaciente = indexOfLastPaciente - pageSize;
          const currentPacientes = pacientes.slice(
            indexOfFirstPaciente,
            indexOfLastPaciente
          );

          return {
            calendarId: event.calendarId,
            title: `${event.patient.pcteNom} ${event.patient.pcteApe1}`,
            nombreDelPaciente: `${event.patient.pcteNom} ${event.patient.pcteNom2} ${event.patient.pcteApe1} ${event.patient.pcteApe2}`,
            pacienteId: event.patient.pcteId,
            estatusCalendar: event.estatus,
            fechaCita: event.fechaCita,
            horaCita: event.horaCita,
            direccionPaciente: event.patient.pcteDir,
            contactoPaciente: event.patient.pcteTel || event.patient.pcteCel,
            emailPaciente: event.patient.pcteEmail,
            start: convertirFechaHora(event.fechaCita, event.horaCita),
            end: convertirFechaHora(event.fechaCita, event.horaCita),
            notaCita: event.notaCita,
            categoryNm: event.category.categoryNm,
            categoryId: event.category.categoryId,
            colorId: paletaColorId,
            colorRgb,
          };
        });
      setEvents(formattedEvents);
      setCategorias(categoriasData);
      setCategoriasMap(categoriasMap);
    } catch (error) {
      console.error("Error al cargar datos:", error);
    }
    finally {
      setIsLoading(false); // Ocultar pantalla de carga cuando termine
    }
  };

  function obtenerHoraAMPM(fechaCita) {
    if (fechaCita) {
      const partes = fechaCita.split(" ");
      if (partes.length === 2) {
        return partes[1];
      } else {
        return "Formato de hora inválido";
      }
    } else {
      return "Formato de hora inválido";
    }
  }

  function convertirFechaHora(fechaCita) {
    if (fechaCita) {
      // Divide la fecha en partes: día, mes, año, hora y ampm
      const [fechaPartes, horaPartes] = fechaCita.split(" ");
      const [dia, mes, año] = fechaPartes.split("/");
      const [hora12, minutos] = horaPartes.split(":");
      const ampm = horaPartes.trim(); // Elimina espacios en blanco innecesarios

      let hora24 = hora12;

      if (ampm === "PM" && hora12 !== "12") {
        hora24 = String(Number(hora12) + 12);
      } else if (ampm === "AM" && hora12 === "12") {
        hora24 = "00";
      }

      // Formatea la fecha y hora en formato ISO8601
      const fechaISO8601 = `${año}-${mes}-${dia}T${hora24}:${minutos}:00`;

      return fechaISO8601;
    } else {
      return ""; // Devuelve una cadena vacía si no se proporciona fechaCita
    }
  }

  const handleSearchTermChange = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
  };

  const openPacienteModal = () => {
    setIsPacienteModalOpen(true);

    setTimeout(() => {
      if (searchInputRef.current) {
          searchInputRef.current.focus();
          // console.log('Forzado el enfoque en el input', searchInputRef.current);
      }
  }, 100); // Un retraso corto
  };

  const closePacienteModal = () => {
    setIsPacienteModalOpen(false);
  };
  // Función para formatear la hora con AM/PM
  function formatearHora(fecha) {
    const ampm = fecha.getHours() >= 12 ? "PM" : "AM";
    let horas = fecha.getHours() % 12;
    horas = horas === 0 ? 12 : horas;
    const minutos = fecha.getMinutes();
    const horaFormateada = `${horas.toString().padStart(2, "0")}:${minutos
      .toString()
      .padStart(2, "0")} ${ampm}`;
    return horaFormateada;
  }
  // Función para formatear la fecha y hora en el formato deseado
  function formatearFechaHora(fecha) {
    const dia = fecha.getDate().toString().padStart(2, "0");
    const mes = fecha.getMonth() + 1;
    const año = fecha.getFullYear();

    // Formatea la hora con AM/PM
    const horaFormateada = formatearHora(fecha);

    return `${dia}/${mes}/${año} ${horaFormateada}`;
  }
  // Función para formatear la fecha de la cita en el formato deseado (sin hora)
  function formatearFechaCita(fecha) {
    const dia = (fecha.getDate() + 1).toString().padStart(2, "0");
    const mes = (fecha.getMonth() + 1).toString().padStart(2, "0"); // Ajusta el mes sumando 1
    const año = fecha.getFullYear();
    return `${dia}/${mes}/${año}`;
  }

  function formatearHora12Horas(horaFormateada) {
    const [hora, minutos] = horaFormateada.split(":");
    const horaNumero = parseInt(hora);

    // Función auxiliar para agregar un cero delante de números menores que 10
    const agregarCero = (numero) => (numero < 10 ? `0${numero}` : `${numero}`);

    if (horaNumero === 0) {
      return `12:${minutos} AM`;
    } else if (horaNumero < 12) {
      return `${agregarCero(horaNumero)}:${minutos} AM`;
    } else if (horaNumero === 12) {
      return `12:${minutos} PM`;
    } else {
      return `${agregarCero(horaNumero - 12)}:${minutos} PM`;
    }
  }
  // Filtro para los pacientes en funcion de termino de busqueda
  const filteredPacientes = pacientes.filter((paciente) => {
    const fullName =
      `${paciente.pcteNom} ${paciente.pcteNom2} ${paciente.pcteApe1} ${paciente.pcteApe2}`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  });

  const totalPages = Math.ceil(filteredPacientes.length / itemsPerPage);

  const handlePacienteSelection = (paciente) => {
    if (paciente) {
      setFormulario((prevFormulario) => ({
        ...prevFormulario,
        nombreDelPaciente: `${paciente.pcteNom} ${paciente.pcteNom2} ${paciente.pcteApe1} ${paciente.pcteApe2}`,
        direccionPaciente: paciente.pcteDir,
        contactoPaciente: paciente.pcteTel || paciente.pcteCel,
        emailPaciente: paciente.pcteEmail,
      }));

      setModalOpen(true);
      setIsButtonDisabled(true);
      closePacienteModal();
      setPacienteSeleccionado(paciente); // Establece pacienteSeleccionado aquí
    } else {
      console.error("El paciente seleccionado es nulo.");
    }
  };

  const handleEventClick = async (eventClickInfo) => {
    const event = eventClickInfo.event;
    const calendarId = event.extendedProps.calendarId;
    const categoryId = event.extendedProps.categoryId;
    const categoria = event.extendedProps.categoryNm;
    const horaCita = event.extendedProps.horaCita;
    const horaCitaDate = new Date(`1/1/2000 ${horaCita}`);
    const horaFormateada = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }).format(horaCitaDate);
    const estatusCalendar = event.extendedProps.estatusCalendar;
    const notaCita = event.extendedProps.notaCita;
    const nombreDelPaciente = event.extendedProps.nombreDelPaciente;
    const pacienteId = event.extendedProps.pacienteId;
    const contactoPaciente = event.extendedProps.contactoPaciente;
    const direccionPaciente = event.extendedProps.direccionPaciente;
    const emailPaciente = event.extendedProps.emailPaciente;
    const fechaCita = event.extendedProps.fechaCita;
    const [fecha, hora] = fechaCita?.split(" ");
    const fechaFormateada = formatearFecha(fecha);

    function formatearFecha(fecha) {
      const [dia, mes, año] = fecha.split("/");
      return `${año}-${mes}-${dia}`;
    }

    try {
      // Realizar una solicitud adicional para obtener información de pacientes
      medicGeekApi.get("/patients").then((responsePatients) => {
        const patientsData = responsePatients.data;
        const paciente = patientsData.find(
          (paciente) => paciente.pcteId === pacienteId
        );

        setFormulario((prevFormulario) => ({
          ...prevFormulario,
          calendarId: calendarId,
          estatusCalendar: estatusCalendar,
          nombrePaciente: event.title,
          nombreDelPaciente: nombreDelPaciente,
          direccionPaciente: direccionPaciente,
          contactoPaciente: contactoPaciente,
          emailPaciente: emailPaciente,
          categoriaNm: categoria,
          categoryId: categoryId,
          horaFormateada: hora,
          fechaCita: fechaCita,
          notaCita: notaCita,
        }));

        setIsEditing(true);
        setModalOpen(true);
        setIsButtonDisabled(true);
        setIsButtonDeleteDisabled(false);

        setPacienteConsulta(paciente);
        setPacienteSeleccionado(paciente);
        setInfEvento(event.extendedProps);

        // console.log(`El paciente que seleccionó es ${nombreDelPaciente} (ID: ${pacienteId})`);

        // console.log('Información de la Cita', infEvento);
      });
    } catch (error) {
      console.error("Error al obtener la información del paciente:", error);
    }
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const saveAppointment = () => {
    if (!pacienteSeleccionado || !formulario.categoriaNm) {
      showErrorAlert(
        "Debes seleccionar un paciente y/o un motivo de consulta antes de guardar la cita."
      );
      return;
    }

    try {
      // Obtener la fecha actual y formatearla
      const fechaActual = new Date();
      const fechaCitaReg = formatearFechaHora(fechaActual);
      const cita = formulario.fechaCita;
      // if (isNaN(cita.getTime())) {
      //   showErrorAlert(
      //     "La fecha de cita es inválida. Por favor, verifique el formato."
      //   );
      //   return;
      // }
      const horaFormateada = formulario.horaFormateada.trim();
      const horaFormateada12Horas = formatearHora12Horas(horaFormateada);
      const categoryId = categoriasMap[formulario.categoriaNm];
      if (!categoryId) {
        showErrorAlert("La categoría seleccionada no es válida.");
        return;
      }
      const nuevaCita = {
        pcteId: pacienteSeleccionado.pcteId,
        categoryId: categoryId,
        fechaCitaReg: fechaCitaReg,
        fechaCita: `${cita} ${horaFormateada12Horas}`,
        horaCita: horaFormateada12Horas,
        notaCita: formulario.notaCita,
        estatus: "Activo",
      };
      console.log("Información del Paciente:", nuevaCita);
      medicGeekApi
        .post("/calendars", nuevaCita)
        .then((response) => {
          closeModal();
          showSuccessAlert("Cita creada con éxito.", 3000);
          fetchData();
        })
        .then((response) => {
          closeModal();
        })
        .catch((error) => {
          console.error("Error al crear la cita", error);
        });
    } catch (error) {
      console.error("Error al crear la Cita:", error);
      showErrorAlert("Hubo un error al crear la Cita");
    }
  };

  const editAppointment = async () => {
    const horaFormateada = formulario.horaFormateada.trim();
    const horaFormateada12Horas = formatearHora12Horas(horaFormateada);
    const cita = formulario.fechaCita;

    // if (isNaN(cita.getTime())) {
    //   showErrorAlert(
    //     "La fecha de cita es inválida. Por favor, verifique el formato."
    //   );
    //   return;
    // }

    if (formulario && formulario.calendarId) {
      const { calendarId } = formulario;
      const categoryId = categoriasMap[formulario.categoriaNm];
      const fechaCita = `${cita} ${horaFormateada12Horas}`;
      const notaCita = formulario.notaCita;

      // Verificar si hubo cambios en la cita
      const cambiosEnCita =
        categoryId !== formulario.categoryId ||
        fechaCita !==`${cita} ${formulario.horaFormateada}` ||
        notaCita !== formulario.notaCita;

      if (cambiosEnCita) {
        const confirmResult = await showConfirmationAlert(
          "Confirmación",
          "¿Estás seguro de que deseas actualizar esta cita?"
        );

        if (confirmResult.isConfirmed) {
          const updatedCalendar = {
            calendarId,
            categoryId,
            fechaCita,
            horaCita: horaFormateada12Horas, // Actualizar también la hora
            notaCita,
            estatus: "Activo",
          };

          try {
            await medicGeekApi.patch(
              `/calendars/${calendarId}`,
              updatedCalendar
            );
            closeModal();
            showSuccessEditAlert("Cita Actualizada con éxito.");
            fetchData();
          } catch (error) {
            console.error("Error al actualizar la cita", error);
          }
        }
      } else {
        if (
          window.confirm(
            "No se realizaron cambios en la cita. ¿Desea cerrar el modal?"
          )
        ) {
          closeModal();
        }
      }
    }
  };

  const deleteAppointment = async () => {
    if (formulario && formulario.calendarId) {
      const { calendarId, estatus } = formulario;

      // Verifica que el estatus actual sea diferente de 'Cancelada' antes de continuar
      if (estatus !== "Cancelada") {
        const confirmResult = await showConfirmationAlert(
          "Confirmación",
          "¿Estás seguro de que deseas cancelar esta cita?"
        );

        if (confirmResult.isConfirmed) {
          const updatedCalendar = {
            calendarId,
            estatus: "Cancelada",
          };

          try {
            await medicGeekApi.patch(
              `/calendars/${calendarId}`,
              updatedCalendar
            );
            closeModal();
            showSuccessAlert("Cita Cancelada con éxito.");
            fetchData();
          } catch (error) {
            console.error("Error al Cancelar la cita", error);
          }
        }
      } else {
        console.error('La cita ya está marcada como "Cancelada".');
      }
    } else {
      if (
        window.confirm(
          "No se realizaron cambios en la cita. ¿Desea cerrar el modal?"
        )
      ) {
        closeModal();
      }
    }
  };

  const handleEnviarConsultaClick = async (paciente, infEvento) => {
    try {
      const pacienteId = paciente?.pcteId;
      const { categoryId, estatusCalendar, calendarId, notaCita } = formulario;
      const consultaCita = {
        ...paciente,
        categoryId,
        estatusCalendar,
        calendarId,
        notaCita,
      };

      setSelectedPaciente(consultaCita);
      setCurrentForm("consultaForm");
      setSelected(true);

      navigate(`/patients/${pacienteId}/encounters/0`);
    } catch (error) {
      console.error("Error al cargar la última consulta:", error);
    }
  };
  

  return (
    <div>
      {isLoading && (
        <div className="loading-screen">
          <div className="spinner"></div>
          <p>Cargando...</p>
        </div>
      )}
       {/* El contenido principal de la página */}
      {!isLoading && (
      <div>
        <div
          className="d-flex justify-content-between miDivConGradiente px-3 rounded-bottom"
          style={{
            height: "5rem",
          }}
        >
          <div className="d-flex align-calendarios-center text-white fw-bold text-uppercase">
            <h2>Agenda</h2>
          </div>
          <div className="">
            <button
              type="button"
              className="btn btn-danger m-2"
              title="Crear Nueva Cita"
              onClick={() => openModal(null)}
            >
              <i className="bi bi-file-earmark-plus-fill"></i> Nueva Cita
            </button>
          </div>
        </div>
        <div
          className="d-flex"
          style={{
            height: "50vh",
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <Fullcalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView={"dayGridMonth"}
              headerToolbar={{
                start: "today prev,next",
                center: "title",
                end: "dayGridMonth,timeGridWeek,timeGridDay",
              }}
              buttonText={{
                today: "Hoy",
                month: "Mes",
                week: "Semana",
                day: "Día",
              }}
              height={"80vh"}
              events={events}
              themeSystem="bootstrap5"
              eventDisplay="auto"
              dayMaxEventRows={3}
              eventContent={(eventInfo) => {
                const horaFormateada = eventInfo.event.extendedProps.horaCita;
                const categoria = eventInfo.event.extendedProps.categoryNm;
                // Crea el contenido personalizado del evento
                const content = document.createElement("div");
                const colorRgb = eventInfo.event.extendedProps.colorRgb;

                // Estilos del Div del Evento //
                content.style.width = "90%";
                content.style.height = "100%";
                content.style.backgroundColor = colorRgb;
                content.style.display = "flex";
                content.style.flexDirection = "column";
                content.style.justifyContent = "center";
                content.style.color = "white";
                content.style.padding = "0.3rem";
                content.style.borderRadius = "0.4rem";

                content.innerHTML = `
                    ${horaFormateada}<br />
                    ${categoria}<br>
                    <strong>${eventInfo.event.title}</strong><br />
                  `;

                // Devuelve el contenido personalizado
                return { domNodes: [content] };
              }}
              eventClick={handleEventClick}
              // validRange={{
              //   start: '2023-09-01', // Fecha de inicio de días laborables
              //   end: '2023-09-30',   // Fecha de finalización de días laborables
              // }}
              businessHours={{
                daysOfWeek: [1, 2, 3, 4, 5, 6], // 1=lunes, 2=martes, ..., 7=domingo
                startTime: "06:00", // Hora de inicio
                endTime: "19:00", // Hora de finalización
              }}
              allDaySlot={true} // Permite que todos los días estén habilitados
            />
            <Modal
              isOpen={modalOpen}
              toggle={closeModal}
              style={{
                maxWidth: "70rem",
                overlay: {
                  zIndex: 1002, // Ajusta el valor según sea necesario (mayor que el de los popovers)
                },
                content: {
                  zIndex: 1003, // Ajusta el valor según sea necesario (mayor que el de los popovers)
                },
              }}
            >
              <ModalHeader toggle={closeModal}>
                {isEditing ? "Editar Cita" : "Crear Nueva Cita"}
              </ModalHeader>
              <ModalBody>
                {/* {selectedPaciente ? ( */}
                <div className="d-flex gap-4">
                  <div
                    style={{
                      width: "50%",
                    }}
                  >
                    <div>
                      <p className="fs-5 fw-bold d-flex justify-content-firt">
                        Datos de la Cita
                      </p>
                    </div>
                    <Form>
                      <div className="d-flex gap-3">
                        <Col>
                          <FormGroup floating>
                            <Input
                              type="select"
                              className="form-control"
                              id="Tipo de Consulta"
                              placeholder="Seleccione la Categoria"
                              value={formulario.categoriaNm}
                              onChange={(e) => {
                                const categoriaSeleccionada = e.target.value;
                                setFormulario({
                                  ...formulario,
                                  categoriaNm: categoriaSeleccionada,
                                });
                                // handleEnviarConsultaClick(paciente, categoriaSeleccionada);
                              }}
                            >
                              <option value="">Seleccione una categoría</option>
                              {categorias.map((categoria) => (
                                <option
                                  key={categoria.id}
                                  value={categoria.categoryNm}
                                >
                                  {categoria.categoryNm}
                                </option>
                              ))}
                            </Input>
                            <Label for="Tipo de Consulta">
                              Motivo de Consulta
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup floating>
                            <Input
                              type="text"
                              className="form-control"
                              id="notaCita"
                              value={formulario.estatusCalendar}
                              onChange={(e) =>
                                setFormulario({
                                  ...formulario,
                                  estatusCalendar: e.target.value,
                                })
                              }
                              placeholder="estatusCalendar"
                              disabled
                            />
                            <Label htmlFor="estatusCalendar">Estatus</Label>
                          </FormGroup>
                        </Col>
                      </div>
                      <Col>
                        <FormGroup floating>
                          <Input
                            type="textarea"
                            className="form-control"
                            id="notaCita"
                            value={formulario.notaCita}
                            style={{
                              height: "8.5rem",
                            }}
                            onChange={(e) =>
                              setFormulario({
                                ...formulario,
                                notaCita: e.target.value,
                              })
                            }
                            placeholder="Nota"
                          />
                          <Label htmlFor="notaCita">Nota</Label>
                        </FormGroup>
                      </Col>
                      <div className="d-flex gap-3">
                        <Col md={4}>
                          <FormGroup floating>
                            <Flatpickr
                              value={formulario.fechaCita}
                              onChange={(selectedDate) => {
                                if (selectedDate.length > 0) {
                                  const fechaFormateada = `${String(selectedDate[0].getDate()).padStart(2, '0')}/${String(selectedDate[0].getMonth() + 1).padStart(2, '0')}/${selectedDate[0].getFullYear()}`;
                                  setFormulario((prevFormulario) => ({
                                    ...prevFormulario,
                                    fechaCita: fechaFormateada,
                                  }));
                                }
                              }}
                              options={{
                                dateFormat: 'd/m/Y', // Mostrar en formato día/mes/año
                                locale: {
                                  ...Spanish,
                                  firstDayOfWeek: 0, // Iniciar la semana en domingo
                                  minDate: "today",
                                },
                              }}
                              className="form-control"
                              id="fechaCita"
                            />

                            <Label htmlFor="fechaCita">Fecha</Label>
                          </FormGroup>
                        </Col>

                        <Col md={4}>
                          <FormGroup floating>
                            {/* Input para la Hora */}
                            <Input
                              type="time"
                              className="form-control"
                              id="horaFormateada"
                              value={formulario.horaFormateada}
                              onChange={(e) =>
                                setFormulario({
                                  ...formulario,
                                  horaFormateada: e.target.value,
                                })
                              }
                              placeholder="Hora de Cita"
                            />
                            <Label htmlFor="horaFormateada">Hora</Label>
                          </FormGroup>
                        </Col>
                      </div>

                    </Form>
                  </div>
                  <div
                    style={{
                      width: "50%",
                    }}
                  >
                    <div>
                      <p className="fs-5 fw-bold d-flex justify-content-firt">
                        Datos del Paciente
                      </p>
                    </div>
                    <Form>
                      <Col md={10}>
                        <FormGroup
                          floating
                          className="d-flex align-items-center gap-2"
                          style={{
                            display: "flex",
                          }}
                        >
                          <Input
                            type="text"
                            className="form-control"
                            id="pcteNom"
                            value={formulario.nombreDelPaciente || ""}
                            onChange={(e) =>
                              setFormulario({
                                ...formulario,
                                nombreDelPaciente: e.target.value,
                              })
                            }
                            placeholder="Nombre del Paciente"
                            disabled
                          />
                          <Label htmlFor="pcteNom">Paciente</Label>
                          <Button
                            onClick={openPacienteModal}
                            className="d-flex justify-content-center align-items-center button-save"
                            style={{
                              height: "3rem",
                              width: "3rem",
                            }}
                            disabled={isButtonDisabled}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="icon icon-tabler icon-tabler-user-search"
                              width="25"
                              height="25"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="#0c56b6"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                              <path d="M6 21v-2a4 4 0 0 1 4 -4h1.5" />
                              <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                              <path d="M20.2 20.2l1.8 1.8" />
                            </svg>
                          </Button>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup floating>
                          <Input
                            type="text"
                            className="form-control"
                            id="pcteDir"
                            value={formulario.direccionPaciente}
                            onChange={(e) =>
                              setFormulario({
                                ...formulario,
                                direccionPaciente: e.target.value,
                              })
                            }
                            placeholder="Direccion del Paciente"
                            disabled
                          />
                          <Label htmlFor="pcteDir">Dirección</Label>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup floating>
                          <Input
                            type="text"
                            className="form-control"
                            id="contactoPaciente"
                            value={formulario.contactoPaciente}
                            onChange={(e) =>
                              setFormulario({
                                ...formulario,
                                contactoPaciente: e.target.value,
                              })
                            }
                            placeholder="Contacto del Paciente"
                            disabled
                          />
                          <Label htmlFor="contactoPaciente">Contacto</Label>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup floating>
                          <Input
                            type="text"
                            className="form-control"
                            id="emailPaciente"
                            value={formulario.emailPaciente}
                            onChange={(e) =>
                              setFormulario({
                                ...formulario,
                                emailPaciente: e.target.value,
                              })
                            }
                            placeholder="Contacto del Paciente"
                            disabled
                          />
                          <Label htmlFor="emailPaciente">Email</Label>
                        </FormGroup>
                      </Col>
                    </Form>
                  </div>
                </div>
              </ModalBody>
              <div className="d-flex justify-content-between p-3">
                <div className="d-flex">
                  <button
                    className="button-send"
                    title="Enviar a Consulta"
                    id="hoverIcon"
                    style={{
                      color: "#208a25",
                    }}
                    onClick={() => handleEnviarConsultaClick(pacienteConsulta)}
                    disabled={isButtonDeleteDisabled}
                  >
                    Enviar a Consulta
                    <i className="bi bi-file-earmark-text px-1"></i>
                  </button>
                </div>
                <div className="d-flex gap-2">
                  <Button
                    className="button-delete text-danger"
                    onClick={deleteAppointment}
                    disabled={isButtonDeleteDisabled}
                  >
                    <i class="bi bi-trash3-fill px-1"></i>
                    Eliminar Cita
                  </Button>
                  <Button
                    className="button-save text-primary"
                    onClick={isEditing ? editAppointment : saveAppointment}
                  >
                    <i class="bi bi-floppy px-1 text-primary"></i>
                    {isEditing ? "Actualizar Cita" : "Guardar"}
                  </Button>
                  <Button
                    className="button-cancel text-secondary"
                    onClick={closeModal}
                  >
                    <i class="bi bi-x-circle px-1"></i>
                    Cancelar
                  </Button>
                </div>
              </div>
            </Modal>

            <Modal isOpen={isPacienteModalOpen} toggle={closePacienteModal} style={{
            maxWidth: "50rem",
          }}>
              <ModalHeader toggle={closePacienteModal}>
                Seleccionar Paciente
              </ModalHeader>
              <ModalBody>
                <Form className="mb-4 sticky-top pt-3 border-bottom bg-primary text-white px-2 rounded-3">
                  <FormGroup>
                    <div className="d-flex flex-column">
                      <Label for="search">Buscar por nombre:</Label>
                      <input
                       className="rounded-3 px-2 py-2 border-0"
                        type="text"
                        name="search"
                        id="search"
                        placeholder="Nombre del paciente"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        ref={searchInputRef}
                      />
                    </div>
                    
                  </FormGroup>
                </Form>
                <ListGroup>
                  {filteredPacientes
                    .slice(
                      (currentPage - 1) * itemsPerPage,
                      currentPage * itemsPerPage
                    )
                    .map((paciente) => {
                      // Construir el nombre completo excluyendo valores nulos o vacíos
                      const fullName = [paciente.pcteNom, paciente.pcteNom2, paciente.pcteApe1, paciente.pcteApe2]
                        .filter(Boolean) // Filtra los valores nulos, undefined o vacíos
                        .join(' '); // Junta los nombres filtrados con espacios
                      
                      return (
                        <ListGroupItem key={paciente.pcteId}>
                          <div className="d-flex justify-content-between">
                            {/* Mostrar solo si el nombre completo tiene contenido */}
                            {fullName && <span>{fullName}</span>}
                            <Button
                              color="primary"
                              title="Nuevo Certificado Médico"
                              onClick={() => handlePacienteSelection(paciente)}
                            >
                              <i className="bi bi-clipboard-check-fill"></i>
                            </Button>
                          </div>
                        </ListGroupItem>
                      );
                    })}
                </ListGroup>
                <nav className="pt-3 d-flex justify-content-center">
                  <ul className="pagination justify-content-center">
                    <li className="page-item" style={{ cursor: "pointer" }}>
                      <span className="page-link" onClick={() => setCurrentPage(1)}>
                        Inicio
                      </span>
                    </li>
            
                    <li className="page-item" style={{ cursor: "pointer" }}>
                      <span
                        className="page-link"
                        style={{
                          height: "2.4rem",
                          width: "7rem",
                        }}
                        onClick={() => setCurrentPage(Math.max(1, currentPage - 10))}
                      >
                        Anterior 10
                      </span>
                    </li>
            
                    {Array.from(
                      { length: Math.min(Math.ceil(filteredPacientes.length / itemsPerPage), 10) },
                      (_, index) => (
                        <li
                          key={index}
                          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            className="page-link"
                            onClick={() => setCurrentPage(index + 1)}
                          >
                            {index + 1}
                          </span>
                        </li>
                      )
                    )}
            
                    <li className="page-item" style={{ cursor: "pointer" }}>
                      <span
                        className="page-link"
                        style={{
                          height: "2.4rem",
                          width: "7.5rem",
                        }}
                        onClick={() =>
                          setCurrentPage(
                            Math.min(
                              Math.ceil(filteredPacientes.length / itemsPerPage),
                              currentPage + 10
                            )
                          )
                        }
                      >
                        Siguiente 10
                      </span>
                    </li>
            
                    <li className="page-item" style={{ cursor: "pointer" }}>
                      <span
                        className="page-link"
                        onClick={() =>
                          setCurrentPage(Math.ceil(filteredPacientes.length / itemsPerPage))
                        }
                      >
                        Final
                      </span>
                    </li>
                  </ul>
                </nav>
              </ModalBody>
              <ModalFooter>
                <Button
                  className="button-cancel text-secondary"
                  onClick={closePacienteModal}
                >
                  Cancelar
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      </div>
    )}
      {currentForm === "consultaForm" && selected && paciente && formulario && (
        <div className="main-content">
          <ConsultaForm
            onCloseForm={handleCloseForm}
            paciente={paciente}
            formulario={formulario}
            setCurrentForm={setCurrentForm}
          />
        </div>
      )}
    </div>
  );
};

export default MyCalendar;
