import React from 'react';
import { UncontrolledDropdown, Button, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { useAuth } from './../AuthContext'; // Asegúrate de ajustar la ruta de importación según sea necesario

const Navbar = ({ toggleSidebar }) => {
  const { logout } = useAuth(); // Obtiene la función logout desde el contexto de autenticación

  const handleLogout = () => {
    // Llama a la función logout al hacer clic en "Salir"
    logout();
    // Aquí podrías redirigir al usuario al login, por ejemplo:
    // history.push('/login');
    window.location.href = '/';
  };

  return (
    <nav className="navbar-light miDivConGradiente">
      <div className="container-fluid d-flex justify-content-between py-2" style={{ height: '5rem' }}>
        
        <div className='d-flex'>
          <button
            className="border-0"
            style={{ height: '2rem', width: '3rem', backgroundColor: 'transparent' }}
            onClick={toggleSidebar}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-menu-2" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M4 6l16 0" />
              <path d="M4 12l16 0" />
              <path d="M4 18l16 0" />
            </svg>
          </button>
        </div>

        <div className='d-flex justify-content-end' style={{ height: '3rem' }}>
          <UncontrolledDropdown group>
            <Button className='border-0' style={{backgroundColor:'transparent'}}>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-bell-ringing" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" />
                <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
                <path d="M21 6.727a11.05 11.05 0 0 0 -2.794 -3.727" />
                <path d="M3 6.727a11.05 11.05 0 0 1 2.792 -3.727" />
              </svg>
            </Button>
            <DropdownToggle caret color="primary" className='border-0' style={{backgroundColor:'transparent'}}>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user-circle" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855" />
              </svg>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem>
                <NavLink to="/profile" className="nav-link text-body text-center text-sm-start">
                  Profile
                </NavLink>
              </DropdownItem>
              <DropdownItem>
                Configuración
              </DropdownItem>
              <DropdownItem>
                Soporte
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={handleLogout}>
                Salir
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
