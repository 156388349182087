import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import medicGeekApi from "../../config/axiosConfig";
import {
  showConfirmationAlert,
  showErrorAlert,
  showSuccessAlert,
} from "./../../components/alerts";

const ProfileForm = ({ onCloseForm, profileToEdit }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    logo1: null,
    logo2: null,
    passwordActual: "",
    password: "",
    confirmPassword: "",
  });
  const { profileId } = useParams();
  const [profileData, setProfileData] = useState(null);
  const history = useNavigate();
  const [paises, setPaises] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalFormData, setModalFormData] = useState({
    passwordActual: "",
    password: "",
    confirmPassword: "",
  });
  const [username, setUsername] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtener el token desde localStorage
        const token = localStorage.getItem("token");
        if (token) {
          // Decodificar el token para obtener el nombre de usuario
          const decodedToken = jwtDecode(token);
          const username = decodedToken.username; // Asegúrate de que 'username' sea correcto

          if (username) {
            // Buscar perfil por nombre de usuario
            const response = await medicGeekApi.get(`/profile/user/${username}`);
            const data = response.data;

            // Verificar si se recibió el perfil
            if (data) {
              setProfileData(data);
              setFormData(data);
              setIsEditing(true);
            } else {
              console.error("No se encontró el perfil del usuario.");
            }
          } else {
            console.error("No se pudo obtener el nombre de usuario del token.");
          }
        } else {
          console.error("No se encontró el token.");
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };
    loadProvincias();
    loadMunicipios();
    loadCountries();
    fetchData();
  }, [profileId]);

  const loadProvincias = async () => {
    try {
      const provinciasResponse = await medicGeekApi.get("/provincias");
      const provinciasData = provinciasResponse.data;
      setProvincias(provinciasData);
    } catch (error) {
      console.error("Error al obtener los datos de la provincias", error);
    }
  };

  const loadMunicipios = async () => {
    try {
      const municipiosResponse = await medicGeekApi.get("/municipios");
      const municipiosData = municipiosResponse.data;
      setMunicipios(municipiosData);
    } catch (error) {
      console.error("Error al obtener los datos de municipios", error);
    }
  };

  const loadCountries = async () => {
    try {
      const countriesResponse = await medicGeekApi.get("/countries");
      const countriesData = countriesResponse.data;
      setCountries(countriesData);
    } catch (error) {
      console.error("Error al obtener los datos de countries", error);
    }
  };

  const getDefaultFormData = () => ({
    user: "",
    docNom: "Dr. Juan Pérez",
    nomCentro: "Centro de Salud Médico Juan Pérez",
    docEsp: "Cardiologo",
    docDir: "C/ Juan Pablo Duarte, Santo Domingo, RD.",
    docExe: "1234",
    docEmail: "correo@correo.com",
    docTel: "809-000-0000",
    docExt: "2255",
    docCel: "829-000-0000",
  });

  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleFileInputChange = (field, file) => {
    if (file instanceof Blob || file instanceof File) {
      const reader = new FileReader();
      reader.onload = () => {
        setFormData({
          ...formData,
          [field]: reader.result,
        });
      };
      reader.readAsDataURL(file);
    } else {
      console.error("El archivo seleccionado no es válido.");
    }
  };

  const guardarProfile = async () => {
    try {
      // Realiza la consulta al endpoint para obtener el perfil según username o docEmail
      const response = await medicGeekApi.post(`/profile/${formData.user}`);

      if (response.ok) {
        const perfil = await response.json();

        // Verifica si se encontró un perfil válido
        if (
          perfil.username === formData.user ||
          perfil.docEmail === formData.user
        ) {
          showSuccessAlert("El perfil existe");
        } else {
          showErrorAlert("No se encontró ningún perfil correspondiente");
        }
      } else {
        showErrorAlert(
          "No se pudo consultar el perfil. Por favor, inténtelo de nuevo."
        );
      }
    } catch (error) {
      console.error("Error al consultar el perfil:", error);
      showErrorAlert(
        "Hubo un error al consultar el perfil. Por favor, inténtelo de nuevo más tarde."
      );
    }
  };

  const editarProfile = async () => {
    try {
      const originalData = profileData;
      const updateData = {};

      const excludedProperties = ["showErrorAlert", "otraPropiedad"]; // Agrega aquí cualquier propiedad que deba excluirse

      for (const field in formData) {
        if (
          !excludedProperties.includes(field) &&
          formData[field] !== originalData[field]
        ) {
          updateData[field] = formData[field];
        }
      }

      if (Object.keys(updateData).length === 0) {
        onCloseForm();
        return;
      }

      const confirmResult = await showConfirmationAlert(
        "Confirmación",
        "¿Está seguro que desea actualizar el Perfil?"
      );

      if (confirmResult.isConfirmed) {
        // Actualizar el perfil usando medicGeekApi.patch
        const response = await medicGeekApi.patch(
          `/profile/${originalData.profileId}`,
          updateData
        );

        if (response.status === 200) {
          // El perfil se actualizó correctamente
          showSuccessAlert("El registro fue Actualizado Correctamente");
        } else {
          // Mostrar un mensaje de error específico para una respuesta de estado inesperada
          showErrorAlert(
            "Hubo un error inesperado al actualizar el Perfil. Por favor, inténtelo de nuevo."
          );
        }
      }
    } catch (error) {
      // Mostrar un mensaje de error genérico para cualquier otro error
      console.error("Error al editar el Perfil:", error);
      showErrorAlert(
        "Hubo un error al editar el Perfil. Por favor, inténtelo de nuevo."
      );
    }
  };

  const handleSubmit = async () => {
    // Lógica de manejo de envío de formulario
    if (isEditing) {
      await editarProfile();
    } else {
      await guardarProfile();
    }
  };

  const handleCancel = () => {
    // Puedes agregar una confirmación si hay cambios no guardados
    onCloseForm();
  };

  // Modal para restaurar contraseña
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleModalInputChange = (field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleChange = (e) => {
    setModalFormData({
      ...modalFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleResetPassword = async () => {
   
    try {
      // Verificar y obtener los valores de formData
      const { passwordActual, password, confirmPassword } = formData;

   
     

      // Validación básica de las contraseñas
      if (password !== confirmPassword) {
        console.log("Las contraseñas no coinciden");
        showErrorAlert("Las contraseñas no coinciden");
        return;
      }

      // Verificar si los valores de las contraseñas están definidos
      if (!passwordActual || !password) {
        console.log(
          "La contraseña actual o la nueva contraseña no están definidas"
        );
        showErrorAlert(
          "La contraseña actual o la nueva contraseña no están definidas"
        );
        return;
      }

      // Obtener el token desde localStorage
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("Token no está disponible");
        showErrorAlert("Token no está disponible");
        return;
      }

      // Decodificar el token para obtener el username
      const decodedToken = jwtDecode(token);
      const username = decodedToken?.username;
      if (!username) {
        console.log("Username no está definido en el token");
        showErrorAlert("Username no está definido en el token");
        return;
      }

      // // Verifica que se está enviando el objeto correcto
      // console.log("Datos que se envían en la solicitud:", {
      //   currentPassword: passwordActual,
      //   newPassword: password,
      // });

      // Llamada a la API para cambiar la contraseña
      const response = await medicGeekApi.patch(
        `/users/${username}/password`,
        {
          currentPassword: formData.passwordActual,
          newPassword: formData.password,
          confirmPassword: formData.confirmPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

     
      showSuccessAlert("Contraseña cambiada correctamente");
      toggleModal(); // Cerrar el modal después de enviar los datos
    } catch (error) {
      console.error("Error al cambiar la contraseña:", error);
      console.error("Datos de error:", error.response?.data);
      showErrorAlert("Hubo un error al cambiar la contraseña");
    }
  };

  return (
    <div className="" style={{ width: "99%" }}>
      <div className="bg-secundary d-flex justify-content-between mt-4 mx-2">
        <div>
          <p className="fs-5 fw-bold">Información del Usuario</p>
        </div>
        <div>
          <button className="btn btn-danger" onClick={toggleModal}>
            Restaurar Contraseña
          </button>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        toggle={toggleModal}
        style={{
          maxWidth: "40rem",
          height: "50rem",
        }}
      >
        <ModalHeader toggle={toggleModal}>Restaurar Contraseña</ModalHeader>
        <ModalBody>
          <div className="text-center px-4 d-flex flex-column">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                viewBox="0 0 20 20"
              >
                <path
                  fill="#DB5227"
                  fillRule="evenodd"
                  d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98zM11 13a1 1 0 1 1-2 0a1 1 0 0 1 2 0m-1-8a1 1 0 0 0-1 1v3a1 1 0 0 0 2 0V6a1 1 0 0 0-1-1"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div>
              <label className="fw-bold fs-5">Cambiar contraseña</label>
            </div>
            <div>
              <p className="pt-2">
                MedicGeek se toma muy enserio la confidencialidad y la seguridad
                de su información y la de sus pacientes. Le pedimos por favor
                cambiar su contraseña a continuación.{" "}
              </p>
            </div>
          </div>
          <div>
            <label className="fw-bold pb-3">Contraseña Actual</label>
            <FormGroup floating>
              <Input
                style={{ height: "4rem" }}
                type="password"
                className="form-control border border-0 bg-body-tertiary"
                id="passwordActual"
                value={formData.passwordActual || ""}
                onChange={(e) =>
                  handleModalInputChange("passwordActual", e.target.value)
                }
                placeholder="Escriba su contraseña actual"
              />
              <Label htmlFor="passwordActual">Contraseña Actual</Label>
            </FormGroup>
          </div>
          <div>
            <label className="fw-bold">Nueva Contraseña</label>
            <FormGroup floating>
              <Input
                style={{ height: "4rem" }}
                type="password"
                className="form-control border border-0 bg-body-tertiary"
                id="password"
                value={formData.password || ""}
                onChange={(e) =>
                  handleModalInputChange("password", e.target.value)
                }
                placeholder="Escriba su nueva contraseña"
              />
              <Label htmlFor="password">Nueva Contraseña</Label>
            </FormGroup>
            <FormGroup floating>
              <Input
                style={{ height: "4rem" }}
                type="password"
                className="form-control border border-0 bg-body-tertiary"
                id="confirmPassword"
                value={formData.confirmPassword}
                onChange={(e) =>
                  handleModalInputChange("confirmPassword", e.target.value)
                }
                placeholder="Confirme su nueva contraseña"
              />
              <Label htmlFor="confirmPassword">Confirmar Contraseña</Label>
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleResetPassword}>
            Restaurar
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      <div className="container mt-2">
        <Row>
          <Form onSubmit={handleSubmit} className="row ">
            <div>
              <div className="row ">
                <Col md={12}>
                  <FormGroup floating>
                    <Input
                      type="text"
                      className="form-control"
                      id="user"
                      value={formData.username || ""}
                      onChange={(e) =>
                        handleInputChange("user", e.target.value)
                      }
                      placeholder="Nombre de Usuario"
                      disabled
                    />

                    <Label for="user">Nombre de Usuario</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      type="text"
                      className="form-control"
                      id="docNom"
                      value={formData.docNom}
                      onChange={(e) =>
                        handleInputChange("docNom", e.target.value)
                      }
                      placeholder="Dr. Jose Pérez"
                    />
                    <Label For="docNom">Nombre Completo</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      type="text"
                      className="form-control"
                      id="docCed"
                      value={formData.docCed}
                      onChange={(e) =>
                        handleInputChange("docCed", e.target.value)
                      }
                      placeholder="000-0000000-0"
                    />
                    <Label For="docCed">Cédula de Identidad</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      type="text"
                      className="form-control"
                      id="nomCentro"
                      value={formData.nomCentro}
                      onChange={(e) =>
                        handleInputChange("nomCentro", e.target.value)
                      }
                      placeholder="Centro"
                    />
                    <Label For="nomCentro">Centro de Salud</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      type="text"
                      className="form-control"
                      id="docEsp"
                      value={formData.docEsp}
                      onChange={(e) =>
                        handleInputChange("docEsp", e.target.value)
                      }
                      placeholder="Especialidad"
                    />
                    <Label For="docEsp">Especialidad</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      className="form-control"
                      id="pcteNac"
                      value={formData.docNac}
                      onChange={(e) =>
                        handleInputChange("docNac", e.target.value)
                      }
                      name="select"
                      type="select"
                    >
                      {countries.map((countries) => (
                        <option key={countries.code} value={countries.pais}>
                          {countries.pais}
                        </option>
                      ))}
                    </Input>
                    <Label For="pcteNac">País de Residencia</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      className="form-control"
                      id="pcteNac"
                      value={formData.docProv}
                      onChange={(e) =>
                        handleInputChange("docProv", e.target.value)
                      }
                      name="select"
                      type="select"
                    >
                      {provincias.map((provincias) => (
                        <option
                          key={provincias.idProvincia}
                          value={provincias.Description}
                        >
                          {provincias.Description}
                        </option>
                      ))}
                    </Input>
                    <Label For="pcteNac">Provincia</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      type="text"
                      className="form-control"
                      id="docDir"
                      value={formData.docDir}
                      onChange={(e) =>
                        handleInputChange("docDir", e.target.value)
                      }
                      placeholder="Dirección"
                    />
                    <Label For="docDir">Dirección</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      type="text"
                      className="form-control"
                      id="docExe"
                      value={formData.docExe}
                      onChange={(e) =>
                        handleInputChange("docExe", e.target.value)
                      }
                      placeholder="Exequátur"
                    />
                    <Label For="docExe">Exequátur</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      type="text"
                      className="form-control"
                      id="docEmail"
                      value={formData.docEmail}
                      onChange={(e) =>
                        handleInputChange("docEmail", e.target.value)
                      }
                      placeholder="Email"
                    />
                    <Label For="docEmail">Email</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      type="text"
                      className="form-control"
                      id="docTel"
                      value={formData.docTel}
                      onChange={(e) =>
                        handleInputChange("docTel", e.target.value)
                      }
                      placeholder="Teléfono"
                    />
                    <Label For="docTel">Teléfono</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      type="text"
                      className="form-control"
                      id="docExt"
                      value={formData.docExt}
                      onChange={(e) =>
                        handleInputChange("docExt", e.target.value)
                      }
                      placeholder="Ext."
                    />
                    <Label For="docExt">Ext.</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      type="text"
                      className="form-control"
                      id="docCel"
                      value={formData.docCel}
                      onChange={(e) =>
                        handleInputChange("docCel", e.target.value)
                      }
                      placeholder="Celular"
                    />
                    <Label For="docCel">Celular</Label>
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup floating>
                    <Input
                      type="text"
                      className="form-control"
                      id="docDirCert"
                      value={formData.docDirCert}
                      onChange={(e) =>
                        handleInputChange("docDirCert", e.target.value)
                      }
                      placeholder="Dirección"
                    />
                    <Label For="docDirCert">
                      Dirección Expedido de Certificado
                    </Label>
                  </FormGroup>
                </Col>
              </div>

              <div>
                <p className="fs-5 fw-bold pt-4 d-flex justify-content-firt">
                  Imagenes
                </p>
              </div>

              <div
                className="d-flex justify-content-around align-items-center"
                style={{
                  height: "20rem",
                  width: "100%",
                }}
              >
                <div className=" ">
                  <label>Logo 1</label>
                  <div
                    className="logoDr1 border"
                    style={{
                      height: "10rem",
                      width: "20rem",
                    }}
                  ></div>
                </div>

                <div className=" ">
                  <label>Logo 2</label>
                  <div
                    className="logoDr2 border"
                    style={{
                      height: "10rem",
                      width: "20rem",
                    }}
                  ></div>
                </div>
              </div>
              <div>
                <label className="text-danger text-start fst-italic">
                  Si tiene intención de cambiar los logo, favor de contactar con
                  los técnicos de <span className="fw-bold">SoftGeek.</span>
                </label>
              </div>
            </div>

            <div className="g-4 d-flex justify-content-end">
              <Link to="/patients">
                <Button
                  type="button"
                  className="btn btn-danger m-2"
                  onClick={handleCancel}
                >
                  Cancelar
                </Button>
              </Link>
              <Button
                className="btn btn-primary m-2"
                onClick={isEditing ? editarProfile : guardarProfile}
              >
                {isEditing ? "Guardar Edición" : "Guardar"}
              </Button>
            </div>
          </Form>
        </Row>
      </div>
    </div>
  );
};

export default ProfileForm;
