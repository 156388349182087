import "cally";
import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { useEffect, useState } from "react";
import "../../../styles/calendar.css";
// import Skeleton from 'react-loading-skeleton';
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import medicGeekApi from "../../../config/axiosConfig";
dayjs.extend(customParseFormat);
var isBetween = require("dayjs/plugin/isBetween");
var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

const GeneralList = ({ currentForm }) => {
  const [loading, setLoading] = useState(true); 
  const [pagos, setPagos] = useState([]); 
  const [facturas, setFacturas] = useState([]);
  const [combinedData, setCombinedData] = useState([]); // Nuevo estado para combinar pagos y facturas
  const [patientsMap, setPatientsMap] = useState({});
  const [filtroFechaInicio, setFiltroFechaInicio] = useState(dayjs().format("YYYY-MM-DD"));
  const [filtroFechaFin, setFiltroFechaFin] = useState(dayjs().format("YYYY-MM-DD"));
  const [fechaHoy, setFechaHoy] = useState(dayjs().format("YYYY/MM/DD"));
  const [pagosFiltrados, setPagosFiltrados] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const paysPerPage = 10;
  const [pageNumber, setPageNumber] = useState(0);
  const [showForm, setShowForm] = useState(false);


 // Manejo del cambio en el rango de fechas
  const handleDateChange = (e) => {
    const { id, value } = e.target;
    if (id === "filtroFechaInicio") setFiltroFechaInicio(value);
    if (id === "filtroFechaFin") setFiltroFechaFin(value);
  };

  // Función para formatear la fecha
  const formatDateColumn = (date) => {
    if (!date) return "Fecha no disponible";
    return dayjs(date, "DD/MM/YYYY").format("DD/MM/YYYY");
  };

  useEffect(() => {
    fetchData(); 
  }, [filtroFechaInicio, filtroFechaFin]);

  useEffect(() => {
    filtrarPagos(); // Aplicar el filtro después de cargar los datos o cambiar fechas
  }, [combinedData, filtroFechaInicio, filtroFechaFin]);

  const fetchData = async () => {
    try {
      // Obtener pacientes
      const patientResponse = await medicGeekApi.get("/patients");
      const patientData = patientResponse.data;
      const patientsNameMap = {};
      
      patientData.forEach((patient) => {
        patientsNameMap[patient.pcteId] = `${patient.pcteNom || ""} ${patient.pcteNom2 || ""} ${patient.pcteApe1 || ""}`;
      });

      setPatientsMap(patientsNameMap);

      // Obtener pagos
      const responsePagos = await medicGeekApi.get("/pays", { params: { filtroFechaInicio, filtroFechaFin } });
      const sortedPagos = responsePagos.data.map((pay) => ({
        ...pay,
        type: "Consulta",
        patientName: patientsNameMap[pay.pcteId] || 'OTROS INGRESOS',
        fecha: pay.payFec,
        monto: pay.payCt,
        reciboId: pay.payId,
      }));

      // Obtener facturas
      const responseFacturas = await medicGeekApi.get("/facturas", { params: { filtroFechaInicio, filtroFechaFin } });
      const sortedFacturas = responseFacturas.data.map((factura) => ({
        ...factura,
        type: "Factura",
        patientName: patientsNameMap[factura.pcteId] || 'OTROS INGRESOS',
        fecha: factura.factFec,
        monto: factura.facturaCt,
        reciboId: factura.factId,
      }));

      // Unir pagos y facturas en un solo arreglo
      const combined = [...sortedPagos, ...sortedFacturas];
      setCombinedData(combined); // Guardar la data combinada en el estado
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  
  const displayedData = pagosFiltrados.slice(
    pageNumber * paysPerPage,
    (pageNumber + 1) * paysPerPage
  );
  
  const filtrarPagos = () => {
    const pagosFiltrados = combinedData
      .filter((pago) => {
        // Verificar si existe la fecha en el objeto de pago
        if (!pago?.fecha) return false;
  
        // Asegúrate de que la fecha de pago está correctamente formateada
        const fechaPago = dayjs(pago.fecha, "DD/MM/YYYY hh:mm A"); // Cambia este formato si tus datos no coinciden
  
        // Formatear las fechas de inicio y fin según el input de fecha del usuario
        const fechaInicioFormatted = filtroFechaInicio
          ? dayjs(filtroFechaInicio, "YYYY-MM-DD").startOf("day")
          : null;
        const fechaFinFormatted = filtroFechaFin
          ? dayjs(filtroFechaFin, "YYYY-MM-DD").endOf("day")
          : null;
  
        // Aplicar los filtros basados en la fecha
        if (fechaInicioFormatted && fechaFinFormatted) {
          // Verifica si la fecha de pago está dentro del rango (inclusive)
          return fechaPago.isBetween(fechaInicioFormatted, fechaFinFormatted, null, '[]');
        } else if (fechaInicioFormatted) {
          // Verificar si la fecha de pago es después o igual a la fecha de inicio
          return fechaPago.isAfter(fechaInicioFormatted) || fechaPago.isSame(fechaInicioFormatted, 'day');
        } else if (fechaFinFormatted) {
          // Verificar si la fecha de pago es antes o igual a la fecha de fin
          return fechaPago.isBefore(fechaFinFormatted) || fechaPago.isSame(fechaFinFormatted, 'day');
        }
  
        // Si no hay rango de fechas, incluir todos los pagos
        return true;
      })
      // Ordenar los pagos por fecha en orden descendente
      .sort((a, b) => {
        const fechaA = dayjs(a.fecha, "DD/MM/YYYY hh:mm A");
        const fechaB = dayjs(b.fecha, "DD/MM/YYYY hh:mm A");
        return fechaB.diff(fechaA); // Fechas más recientes primero
      });
  
    // Actualizar el estado con los pagos filtrados
    setPagosFiltrados(pagosFiltrados);
  };
    
  const pageCount = Math.ceil(pagosFiltrados.length / paysPerPage);

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  // Contador de suma columna
  const totalAmount = pagosFiltrados.reduce((total, pay) => {
    // Verifica si payCt es un número válido, si no lo es, usa 0 por defecto
    const amount = parseFloat(pay.monto) || 0;
    return total + amount;
  }, 0);
  
  const currentDate = dayjs().format("DD-MM-DD"); // Obtiene la fecha actual

  const handleDayClick = (day) => {
    if (!filtroFechaInicio || (filtroFechaInicio && filtroFechaFin)) {
      setFiltroFechaInicio(day);
      setFiltroFechaFin(null);
    } else {
      setFiltroFechaFin(day);
    }
  };

  const toggleForm = () => {
    setShowForm(!showForm); // Alterna entre true y false
  };
  
  const handlePrintPDF = () => {
    const doc = new jsPDF();
    const totalPagesExp = "{total_pages_count_string}";

    // Información estática para el PDF
    const header = "Listado de Pagos"; // Encabezado del documento
    const currentDate = dayjs().format("DD/MM/YYYY"); // Fecha actual

    // Información dinámica del PDF
    const fechaInicio = filtroFechaInicio
      ? dayjs(filtroFechaInicio).format("DD/MM/YYYY")
      : "-";
    const fechaFin = filtroFechaFin
      ? dayjs(filtroFechaFin).format("DD/MM/YYYY")
      : "-";
    const totalRegistros = pagosFiltrados.length;
    const totalMonto = totalAmount.toFixed(2); // Total de monto formateado

    // Contenido del PDF
    const content = [
      { label: "Fecha de inicio:", value: fechaInicio },
      { label: "Fecha de fin:", value: fechaFin },
      { label: "Total de registros:", value: totalRegistros.toString() },
      { label: "Total de monto:", value: totalMonto },
      // Puedes agregar más campos según sea necesario
    ];

    // Añadir el encabezado al documento
    doc.setFontSize(18);
    doc.text(header, 14, 22);

    // Añadir la fecha actual al documento
    doc.setFontSize(12);
    doc.text(`Fecha: ${currentDate}`, 14, 30);

    // Añadir los campos y su contenido al documento
    doc.setFontSize(12);
    content.forEach((item, index) => {
      doc.text(`${item.label} ${item.value}`, 14, 40 + index * 10);
    });

    // Añadir el listado de pagos al documento (si hay pagos filtrados)
    if (pagosFiltrados.length > 0) {
      doc.autoTable({
        startY: 80, // Posición inicial de la tabla
        head: [["Recibo No.", "Fecha", "Paciente", "Cantidad", "Nota"]], // Encabezados de la tabla
        body: pagosFiltrados.map((pay) => [
          pay.payId,
          formatDateColumn(pay.payFec),
          patientsMap[pay.pcteId] || "Nombre no encontrado",
          parseFloat(pay.payCt).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          pay.payDesc,
        ]),
        theme: "grid", // Estilo de la tabla
        styles: { fontSize: 10 }, // Estilo del texto
      });
    }

    // Agregar el número total de páginas
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(
        14,
        doc.internal.pageSize.height - 10,
        `Página ${i} de ${pageCount}`
      );
    }

    // Descargar el PDF
    doc.save("pay-list.pdf");
  };


  return (
    <div>
      <div>
        <div
          className="d-flex justify-content-between miDivConGradiente px-3 rounded-bottom"
          style={{
            height: "5rem",
          }}
        >
          <div className="d-flex align-items-center text-white fw-bold text-uppercase">
            <h2>Reporte General</h2>
          </div>
          <div className="d-flex align-items-center">
            <button
              type="button"
              className="btn btn-success"
              title="Crear Nuevo Color"
              onClick={handlePrintPDF}
            >
              <i className="bbi bi-printer-fill"></i> Imprimir Listado
            </button>
          </div>
        </div>
        <div id="pay-list">
          <div
            className="mb-2"
            style={{
              padding: "0rem 1rem",
            }}
          >
            <div
              className="miDivConGradienteSmall rounded text-white fw-bold fs-5 my-2 px-2 d-flex justify-content-center py-2 gap-2"
              style={{ height: "3rem" }}
              >
                <div className="d-flex gap-2">
                  <label>Desde:</label>
                  <input
                    type="date"
                    id="filtroFechaInicio"
                    value={filtroFechaInicio}
                    onChange={(e) => setFiltroFechaInicio(e.target.value)}
                  />

                  <label>Hasta:</label>
                  <input
                    type="date"
                    id="filtroFechaFin"
                    value={filtroFechaFin}
                    onChange={(e) => setFiltroFechaFin(e.target.value)}
                  />
                </div>
            </div>
            {/* FILTROS */}

            <div  style={{ height: "45vh", padding: "0rem 1rem", overflowY: "auto" }}>
            <table className="table table-striped table-hover mt-3" >
              <thead>
                <tr>
                  <th>Recibo No.</th>
                  <th>Fecha</th>
                  <th>Tipo de Ingreso</th>
                  <th>Monto</th>
                  <th>Detalle</th>
                </tr>
              </thead>
              <tbody>
                {displayedData.map((data) => (
                  <tr key={data.reciboId}>
                    <td>{data.reciboId}</td>
                    <td>{formatDateColumn(data.fecha)}</td>
                    <td>{data.patientName}</td>
                    <td>
                      {parseFloat(data.monto).toLocaleString("es-DO", {
                        style: "currency",
                        currency: "DOP",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                    <td>{data.type}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
            {/* Listado */}

            <div
              className="text-start miDivConGradienteSmall w-1 rounded text-uppercase text-white px-2 py-2 my-2 fs-5 d-flex justify-content-between"
              style={{ height: "3rem" }}
            >
              <label>
                Total de Registros:{" "}
                <span className="fw-bold text-decoration-underline">
                  {pagosFiltrados.length}
                </span>
              </label>
              <label>
                Total:{" "}
                <span className="fw-bold text-decoration-underline">
                  {totalAmount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </span>
              </label>
            </div>
            {/* Contador */}

            {!showForm && (
              <div className="d-flex justify-content-center mt-5">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                    <li className={`page-item`} style={{ cursor: "pointer" }}>
                      <span
                        className="page-link"
                        onClick={() => handlePageChange({ selected: 0 })}
                      >
                        Inicio
                      </span>
                    </li>
                    <li className={`page-item`} style={{ cursor: "pointer" }}>
                      <span
                        className="page-link"
                        style={{
                          height: "2.4rem",
                          width: "7rem",
                        }}
                        onClick={() =>
                          handlePageChange({
                            selected: Math.max(0, pageNumber - 10),
                          })
                        }
                      >
                        Anterior 10
                      </span>
                    </li>
                    {Array.from(
                      { length: Math.min(pageCount, 10) },
                      (_, index) => (
                        <li
                          key={index}
                          className={`page-item ${
                            pageNumber === index ? " active" : ""
                          }`}
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            className="page-link"
                            onClick={() =>
                              handlePageChange({ selected: index })
                            }
                          >
                            {index + 1}
                          </span>
                        </li>
                      )
                    )}
                    <li className={`page-item`} style={{ cursor: "pointer" }}>
                      <span
                        className="page-link"
                        style={{
                          height: "2.4rem",
                          width: "7.5rem",
                        }}
                        onClick={() =>
                          handlePageChange({
                            selected: Math.min(pageCount - 1, pageNumber + 10),
                          })
                        }
                      >
                        Siguiente 10
                      </span>
                    </li>
                    <li className={`page-item`} style={{ cursor: "pointer" }}>
                      <span
                        className="page-link"
                        onClick={() =>
                          handlePageChange({ selected: pageCount - 1 })
                        }
                      >
                        Final
                      </span>
                    </li>
                  </ul>
                </nav>
              </div>
            )}
            {/* Paginación */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default GeneralList;
