// LoginForm.js
import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"; // Importa Modal y ModalHeader, ModalBody
import imagenTransparente from "../../assets/images/3-transparente.png";
import imagenBackground from "../../assets/images/dr-tablet.png";
import { useAuth } from "../../AuthContext";
import medicGeekApi from "../../config/axiosConfig";
import { useNavigate } from 'react-router-dom';
import { showErrorAlert, showLoginsAlert } from "./../../components/alerts";
import RestoreForm from "./restore"; // Importa el formulario de restauración

const LoginForm = () => {
  const { login } = useAuth(); // Obtén la función de login del contexto de autenticación
  const { setIsAuthenticated } = useAuth();
  const [formData, setFormData] = useState({
    user: "",
    userpass: "",
  });
  const [modal, setModal] = useState(false); // Estado para controlar el modal
  const navigate = useNavigate(); // Hook para la redirección

  const toggleModal = () => setModal(!modal); // Función para alternar el modal

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await medicGeekApi.post(`/auth/login`, formData);
  //     const token = response.data.access_token;
  //     login(token); // Usa la función de login del contexto de autenticación
  //     showLoginsAlert("¡Bienvenido a MedicGeek!");
  //     navigate('/'); // Redirige al contenido principal tras el inicio de sesión exitoso
  //   } catch (error) {
  //     const errorMessage = error.response ? error.response.data.message : error.message;
  //     showErrorAlert(errorMessage);
  //     console.error("Error logging in:", errorMessage);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await medicGeekApi.post(`/auth/login`, formData);
      const token = response.data.access_token;
      localStorage.setItem("token", token);
      login(token); // Usa la función de login del contexto de autenticación
      showLoginsAlert("¡Bienvenido a MedicGeek!");
      navigate('/'); // Redirige al contenido principal tras el inicio de sesión exitoso
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      showErrorAlert(errorMessage);
      console.error("Error logging in:", errorMessage);
    }
  };
  

  return (
    <div className="container-fluid login-container" style={{ height: "100vh"}}>
      <div className="row shadow-sm p-3 mb-5 bg-body-tertiary rounded ">
        <div className="col-lg-7 col-md-12 col-sm-12 d-flex align-items-center justify-content-center d-none d-lg-flex">
          <img
            src={imagenBackground}
            className="img-fluid rounded"
            alt="Imagen de logo"
            style={{
              height: "100%",
              width: "100%",
            }}
          />
        </div>
  
        <div className="col-lg-5 col-md-0 col-sm-12 d-flex flex-column justify-content-around px-5">
          <div className="d-flex align-items-center justify-content-center">
            <img
              src={imagenTransparente}
              className="img-fluid"
              alt="Imagen de logo"
              style={{
                height: "10rem",
                width: "15rem",
              }}
            />
          </div>
  
          <div className="d-flex align-items-center flex-column ">
            <div className="d-flex justify-content-center w-100">
              <label className="fs-4 py-3">Iniciar sesión</label>
            </div>
  
              <div className="col-lg-9 col-md-12 col-sm-12">
                  <div className="text-start fw-bold w-100">
                    <FormGroup>
                      <Label>Usuario</Label>
                      <div className="d-flex align-items-center border-bottom shadow-sm p-2 mb-4 bg-body-tertiary rounded">
                        <i className="bi bi-person fs-2"></i>
                        <Input
                          type="text"
                          className="form-control border-0 bg-body-tertiary"
                          id="user"
                          value={formData.user}
                          onChange={handleChange}
                          placeholder="Escriba su usuario"
                        />
                      </div>
                    </FormGroup>
        
                    <FormGroup>
                      <Label>Contraseña</Label>
                      <div className="d-flex align-items-center border-bottom shadow-sm p-2 mb-4 bg-body-tertiary rounded">
                        <i className="bi bi-file-lock2 fs-2"></i>
                        <Input
                          type="password"
                          className="form-control border-0 bg-body-tertiary"
                          id="userpass"
                          value={formData.userpass}
                          onChange={handleChange}
                          placeholder="*********"
                        />
                      </div>
                    </FormGroup>
                  </div>
              </div>
            
  
            <div className="d-flex justify-content-around w-100 px-3">
              <Form onSubmit={handleSubmit} className="w-100">
                <Button className="btn button-login w-100" type="submit">
                  Acceder
                </Button>
              </Form>
            </div>
  
            <div className="d-flex justify-content-center button-link my-2 w-100">
              <button
                className="btn border-0"
                data-bs-toggle="button"
                onClick={toggleModal}
              >
                ¿Has olvidado tu usuario/contraseña?
              </button>
            </div>
          </div>
        </div>
      </div>
  
      <div className="text-center mt-4">
        <label>
          CopyRight@Todos los derechos reservador SOFTGEEK TECHNOLOGY
        </label>
      </div>
  
      <Modal
        isOpen={modal}
        toggle={toggleModal}
        style={{
          maxWidth: "100%",
        }}
      >
        <ModalHeader toggle={toggleModal}>Restaurar Contraseña</ModalHeader>
        <ModalBody>
          <RestoreForm />
        </ModalBody>
      </Modal>
    </div>
  );
  
  
};

export default LoginForm;
