import "cally";
import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { useEffect, useState } from "react";
import "../../../styles/calendar.css";
// import Skeleton from 'react-loading-skeleton';
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import medicGeekApi from "../../../config/axiosConfig";
dayjs.extend(customParseFormat);
var isBetween = require("dayjs/plugin/isBetween");
var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

const FactList = ({ currentForm }) => {
  const [loading, setLoading] = useState(true); // Estado para controlar la carga
  const [facturas, setFacturas] = useState([]); // Estado para los facturas
  const [facturasConcepto, setFacturasConcepto] = useState([]); // Estado para los facturas
  const [patientsMap, setPatientsMap] = useState({});
  const [filtroFechaInicio, setFiltroFechaInicio] = useState(dayjs().format("YYYY-MM-DD"));
  const [filtroFechaFin, setFiltroFechaFin] = useState(dayjs().format("YYYY-MM-DD"));
  const [facturasFiltrados, setFacturasFiltrados] = useState([]);
  const [showForm] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const factsPerPage = 10;
  const [selectedFactLocal, setselectedFactLocal] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [fechaHoy, setFechaHoy] = useState(dayjs().format("YYYY/MM/DD"));
  const [selectedRange, setSelectedRange] = useState({
    from: null,
    to: null,
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [conceptos, setConceptos] = useState([]);
  const [conceptoSeleccionado, setConceptoSeleccionado] = useState("");


  const handleConceptoChange = (e) => {
    setConceptoSeleccionado(e.target.value);
    filtrarFacturas(); // Asegúrate de llamar a filtrarfacturas aquí
  };  
  
  // Función para actualizar filtroFechaInicio
  const handleFechaInicioChange = (fecha) => {
    setFiltroFechaInicio(fecha);
  };

  // Función para actualizar filtroFechaFin
  const handleFechaFinChange = (fecha) => {
    setFiltroFechaFin(fecha);
  };

  const fetchData = async () => {
    try {
      const response = await medicGeekApi.get("/facturas", {
        params: {
          search: searchTerm,
          filtroFechaInicio: filtroFechaInicio,
          filtroFechaFin: filtroFechaFin,
          fechaHoy: fechaHoy,
        },
      });
      
      const sortedFacts = response.data.sort((a, b) => a.factId - b.factId);
      const patientResponse = await medicGeekApi.get("/patients");
      const patientData = patientResponse.data;

  
      // Crear un mapa para el nombre completo de los pacientes
      const patientsNameMap = {};
      const patientsConceptoMap = {};
  
      patientData.forEach((patient) => {
        // Mapear el nombre completo del paciente
        patientsNameMap[patient.pcteId] = `${patient.pcteNom || ""} ${patient.pcteNom2 || ""} ${patient.pcteApe1 || ""}`;
        // Mapear el ConceptoNm del paciente
        patientsConceptoMap[patient.pcteId] = patient.conceptoNm || 'No disponible';
      });

      const facturasConConcepto = sortedFacts.map((facts) => ({
        ...facts,
        patientName: patientsNameMap[facts.pcteId] || 'No disponible', // Nombre del paciente
        conceptoNm: patientsConceptoMap[facts.pcteId] || 'No disponible',  // Nombre del seguro
      }));
  
      // Ahora puedes agregar tanto el nombre del paciente como el ConceptoNm a los Facts
      setFacturas(sortedFacts);
      setFacturasConcepto(patientsConceptoMap);
      setPatientsMap(patientsNameMap);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    fetchData(); // Refresca la data cuando cambian los filtros
  }, [filtroFechaInicio, filtroFechaFin, conceptoSeleccionado]);
  

  // Llamar a la función para filtrar facturas cuando cambien las fechas o los facturas
  useEffect(() => {
    filtrarFacturas();
  }, [filtroFechaInicio, filtroFechaFin, conceptoSeleccionado, facturas]);  
  

  useEffect(() => {
    const fetchConceptos = async () => {
      try {
        const conceptosResponse = await medicGeekApi.get("/conceptos");
        const conceptosData = conceptosResponse.data.filter(
          (concepto) => concepto.estatus === "Activo"
        );
        setConceptos(conceptosData);
      } catch (error) {
        console.error("Error fetching conceptos:", error);
      }
    };
  
    fetchConceptos();
  }, []);

  // Manejar el cambio en los inputs de fecha
  const handleDateChange = (e) => {
      const { id, value } = e.target;
      if (id === "filtroFechaInicio") {
          setFiltroFechaInicio(value);  // Actualiza la fecha de inicio
      }
      if (id === "filtroFechaFin") {
          setFiltroFechaFin(value);  // Actualiza la fecha de fin
      }
  };

  const formatDateColumn = (date) => {
    if (!date) return "Fecha no disponible";  // Manejo de fechas nulas
    return dayjs(date, "DD/MM/YYYY hh:mm A").format("DD/MM/YYYY");  // Ajusta el formato a lo que necesitas
  };
  
  const filtrarFacturas = () => {
    const facturasFiltrados = facturas.filter((factura) => {
      if (!factura?.factFec) return false;
  
      const fechaFactura = dayjs(factura?.factFec, "DD/MM/YYYY hh:mm A");
      const fechaHoyFormatted = dayjs(fechaHoy, "YYYY-MM-DD").startOf("day");
  
      // Incluye facturas de la fecha actual
      if (fechaFactura.isSame(fechaHoyFormatted, 'day')) return true;
  
      const fechaInicioFormatted = filtroFechaInicio ? dayjs(filtroFechaInicio, "YYYY-MM-DD").startOf("day") : null;
      const fechaFinFormatted = filtroFechaFin ? dayjs(filtroFechaFin, "YYYY-MM-DD").endOf("day") : null;
  
      // Verificar si hay filtros de fecha aplicados
      if (fechaInicioFormatted && fechaFinFormatted) {
        return fechaFactura.isBetween(fechaInicioFormatted, fechaFinFormatted, null, '[]'); // Incluir límites
      }
  
      // Filtrar por concepto seleccionado
      if (conceptoSeleccionado && conceptoSeleccionado !== "") {
        return factura.conceptoNm === conceptoSeleccionado;
      }
  
      return true; // Si no hay filtros aplicados
    });
  
    setFacturasFiltrados(facturasFiltrados);
  };

  const formatDateTimeColumn = (date) => {
    if (date === null) {
      return "";
    }
    return dayjs(date).format("YYYY/MM/DD h:mm A");
  };

  const isDateBetween = (dateToCheck, startDate, endDate) => {
    const date = dayjs(dateToCheck);
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    return date.isAfter(start) && date.isBefore(end);
  };

  const handleDateRangeChange = (ranges) => {
    const { selection } = ranges;
    const startDate = dayjs(selection.startDate).format("YYYY/MM/DD");
    const endDate = dayjs(selection.endDate).format("YYYY/MM/DD");
    setFiltroFechaInicio(startDate);
    setFiltroFechaFin(endDate);
  };

  const pageCount = Math.ceil(facturasFiltrados.length / factsPerPage);

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  // const displayedFactura = facturasFiltrados
  // .filter((factura) => {
  //   return (
  //     (typeof factura.factId === 'string' && factura.factId.toLowerCase().includes(searchTerm.toLowerCase())) ||
  //     (typeof factura.pcteId === 'string' && factura.pcteId.toLowerCase().includes(searchTerm.toLowerCase()))
      
  //   );
  // })
  // .slice(pageNumber * factsPerPage, (pageNumber + 1) * factsPerPage);

  const displayedFactura = facturasFiltrados.filter((factura) => true).slice(pageNumber * factsPerPage, (pageNumber + 1) * factsPerPage);


  // Contador de suma columna
  const totalAmount = facturasFiltrados.reduce(
    (total, factura) => total + parseFloat(factura.facturaCt),
    0
  );
  const currentDate = dayjs().format("DD-MM-DD"); // Obtiene la fecha actual

  const handleDayClick = (day) => {
    if (!filtroFechaInicio || (filtroFechaInicio && filtroFechaFin)) {
      setFiltroFechaInicio(day);
      setFiltroFechaFin(null);
    } else {
      setFiltroFechaFin(day);
    }
  };

  const getConceptoNombre = (conceptoId) => {
    const concepto = conceptos.find(c => Number(c.conceptoId) === Number(conceptoId));
    return concepto ? concepto.conceptoNm : "Concepto no disponible";
  };

  // const handlePrintPDF = () => {
  //   const doc = new jsPDF();
  //   const totalPagesExp = "{total_pages_count_string}";

  //   // Información estática para el PDF
  //   const header = "Listado de facturas"; // Encabezado del documento
  //   const currentDate = dayjs().format("DD/MM/YYYY"); // Fecha actual

  //   // Información dinámica del PDF
  //   const fechaInicio = filtroFechaInicio
  //     ? dayjs(filtroFechaInicio).format("DD/MM/YYYY")
  //     : "-";
  //   const fechaFin = filtroFechaFin
  //     ? dayjs(filtroFechaFin).format("DD/MM/YYYY")
  //     : "-";
  //   const totalRegistros = facturasFiltrados.length;
  //   const totalMonto = totalAmount.toFixed(2); // Total de monto formateado

  //   // Contenido del PDF
  //   const content = [
  //     { label: "Fecha de inicio:", value: fechaInicio },
  //     { label: "Fecha de fin:", value: fechaFin },
  //     { label: "Total de registros:", value: totalRegistros.toString() },
  //     { label: "Total de monto:", value: totalMonto },
  //     // Puedes agregar más campos según sea necesario
  //   ];

  //   // Añadir el encabezado al documento
  //   doc.setFontSize(18);
  //   doc.text(header, 14, 22);

  //   // Añadir la fecha actual al documento
  //   doc.setFontSize(12);
  //   doc.text(`Fecha: ${currentDate}`, 14, 30);

  //   // Añadir los campos y su contenido al documento
  //   doc.setFontSize(12);
  //   content.forEach((item, index) => {
  //     doc.text(`${item.label} ${item.value}`, 14, 40 + index * 10);
  //   });

  //   // Añadir el listado de facturas al documento (si hay facturas filtrados)
  //   if (facturasFiltrados.length > 0) {
  //     doc.autoTable({
  //       startY: 80, // Posición inicial de la tabla
  //       head: [["Recibo No.", "Fecha", "Paciente", "Cantidad", "Nota"]], // Encabezados de la tabla
  //       body: facturasFiltrados.map((pay) => [
  //         pay.payId,
  //         formatDateColumn(pay.payFec),
  //         patientsMap[pay.pcteId] || "Nombre no encontrado",
  //         parseFloat(pay.payCt).toLocaleString("en-US", {
  //           style: "currency",
  //           currency: "USD",
  //           minimumFractionDigits: 2,
  //           maximumFractionDigits: 2,
  //         }),
  //         pay.payDesc,
  //       ]),
  //       theme: "grid", // Estilo de la tabla
  //       styles: { fontSize: 10 }, // Estilo del texto
  //     });
  //   }

  //   // Agregar el número total de páginas
  //   const pageCount = doc.internal.getNumberOfPages();
  //   for (let i = 1; i <= pageCount; i++) {
  //     doc.setPage(i);
  //     doc.text(
  //       14,
  //       doc.internal.pageSize.height - 10,
  //       `Página ${i} de ${pageCount}`
  //     );
  //   }

  //   // Descargar el PDF
  //   doc.save("pay-list.pdf");
  // };

  // console.log(filtroFechaInicio)

  return (
    <div>
      <div>
        <div
          className="d-flex justify-content-between miDivConGradiente px-3 rounded-bottom"
          style={{
            height: "5rem",
          }}
        >
          <div className="d-flex align-items-center text-white fw-bold text-uppercase">
            <h2>Reporte de Ventas</h2>
          </div>
          <div className="d-flex align-items-center">
            <button
              type="button"
              className="btn btn-success"
              title="Crear Nuevo Color"
              // onClick={handlePrintPDF}
            >
              <i className="bbi bi-printer-fill"></i> Imprimir Listado
            </button>
          </div>
        </div>
        <div id="pay-list">
          <div
            className="mb-2"
            style={{
              padding: "0rem 1rem",
            }}
          >
            <div
              className="miDivConGradienteSmall rounded text-white fw-bold fs-5 my-2 px-2 d-flex justify-content-center py-2 gap-2"
              style={{ height: "3rem" }}
              >
                <div className="d-flex gap-2">
                  <label>Desde:</label>
                  <input
                    type="date"
                    id="filtroFechaInicio"
                    value={filtroFechaInicio}
                    onChange={(e) => setFiltroFechaInicio(e.target.value)}
                  />

                  <label>Hasta:</label>
                  <input
                    type="date"
                    id="filtroFechaFin"
                    value={filtroFechaFin}
                    onChange={(e) => setFiltroFechaFin(e.target.value)}
                  />
                </div>
                
            </div>
            {/* FILTROS */}

            <div style={{ height: "45vh", padding: "0rem 1rem", overflowY: "auto" }}>
              <table className="table table-striped table-hover mt-3">
                <thead>
                  <tr>
                    <th>Recibo No.</th>
                    <th>Fecha</th>
                    <th>Tipo de Ingreso</th>
                    <th>Concepto</th>
                    <th>Detalle</th>
                    <th>Cantidad</th>
                    {/* <th>Acciones</th> */}
                  </tr>
                </thead>
                <tbody>
                  {displayedFactura
                    .sort((a, b) => new Date(a.factFec) - new Date(b.factFec)) // Ordenar por fecha
                    .map((factura) => (
                      <tr key={factura.factId}>
                        <td style={{ width: "10%"}}>{factura.factId}</td>
                        <td style={{ width: "10%"}}><p>{formatDateColumn(factura.factFec)}</p></td>
                        <td style={{ width: "30%"}}>{patientsMap[factura.pcteId] || "OTROS INGRESOS"}</td>
                        <td style={{ width: "30%"}}>{getConceptoNombre(factura.conceptoId)}</td>
                        <td style={{ width: "30%"}} data-bs-toggle="tooltip" data-bs-placement="top" title={factura.factDetails}>
                          {factura.factDetails?.length > 20
                            ? `${factura.factDetails.slice(0, 30)}...`
                            : factura.factDetails}
                        </td>
                        <td style={{ width: "10%" }}>
                          {parseFloat(factura.facturaCt).toLocaleString("es-DO", {
                            style: "currency",
                            currency: "DOP",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        {/* <td style={{ width: "5%"}}>
                          <div className="">
                            <button type="button" className="p-1 border-0" title="Imprimir" id="hoverIcon">
                              <i className="bi bi-printer-fill"></i>
                            </button>
                          </div>
                        </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            {/* Listado */}

            <div
              className="text-start miDivConGradienteSmall w-1 rounded text-uppercase text-white px-2 py-2 my-2 fs-5 d-flex justify-content-between"
              style={{ height: "3rem" }}
            >
              <label>
                Total de Registros:{" "}
                <span className="fw-bold text-decoration-underline">
                  {facturasFiltrados.length}
                </span>
              </label>
              <label>
                Total:{" "}
                <span className="fw-bold text-decoration-underline">
                  {totalAmount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </span>
              </label>
            </div>
            {/* Contador */}

            {!showForm && (
              <div className="d-flex justify-content-center mt-5">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                    <li className={`page-item`} style={{ cursor: "pointer" }}>
                      <span
                        className="page-link"
                        onClick={() => handlePageChange({ selected: 0 })}
                      >
                        Inicio
                      </span>
                    </li>
                    <li className={`page-item`} style={{ cursor: "pointer" }}>
                      <span
                        className="page-link"
                        style={{
                          height: "2.4rem",
                          width: "7rem",
                        }}
                        onClick={() =>
                          handlePageChange({
                            selected: Math.max(0, pageNumber - 10),
                          })
                        }
                      >
                        Anterior 10
                      </span>
                    </li>
                    {Array.from(
                      { length: Math.min(pageCount, 10) },
                      (_, index) => (
                        <li
                          key={index}
                          className={`page-item ${
                            pageNumber === index ? " active" : ""
                          }`}
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            className="page-link"
                            onClick={() =>
                              handlePageChange({ selected: index })
                            }
                          >
                            {index + 1}
                          </span>
                        </li>
                      )
                    )}
                    <li className={`page-item`} style={{ cursor: "pointer" }}>
                      <span
                        className="page-link"
                        style={{
                          height: "2.4rem",
                          width: "7.5rem",
                        }}
                        onClick={() =>
                          handlePageChange({
                            selected: Math.min(pageCount - 1, pageNumber + 10),
                          })
                        }
                      >
                        Siguiente 10
                      </span>
                    </li>
                    <li className={`page-item`} style={{ cursor: "pointer" }}>
                      <span
                        className="page-link"
                        onClick={() =>
                          handlePageChange({ selected: pageCount - 1 })
                        }
                      >
                        Final
                      </span>
                    </li>
                  </ul>
                </nav>
              </div>
            )}
            {/* Paginación */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default FactList;
