import { format } from "date-fns";
import jsPDF from "jspdf";
import { jwtDecode } from "jwt-decode";
import React, { useEffect,useRef, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import medicGeekApi from "../../../config/axiosConfig";
import { showErrorAlert, showSuccessAlert, showInfoAlert } from "./../../../components/alerts";

const CertMedicoList = ({
  setCurrentForm,
  currentForm,
  setSelectedCertMedicos,
}) => {
  const [certMedicos, setCertMedicos] = useState([]);
  const [selectedPaciente, setSelectedPaciente] = useState(null);
  const [showForm] = useState(false);

  // Skeleton
  const [loading, setLoading] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const certMedicosPerPage = 10;
  const filteredCertMedicos = certMedicos;
  const [pageCount, setPageCount] = useState(0);

  const [pacientes, setPacientes] = useState([]);
  const [modalSearchTerm, setModalSearchTerm] = useState("");
  const [modalCurrentPage, setModalCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const [selectedCertMedicosLocal, setSelectedCertMedicosLocal] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // Variable para distinguir entre editar y crear

  const [formulario, setFormulario] = useState({
    fechaCertMedico: "",
    constatado: "",
    recomedacion: "",
  });
  const [sortColumn, setSortColumn] = useState(''); // Columna actual para ordenar
  const [sortDirection, setSortDirection] = useState('asc'); // Dirección del ordenamiento

  const [pageSize, setPageSize] = useState(10); // Inicialmente, muestra 10 registros por página
  const [currentPage, setCurrentPage] = useState(1);
  const [profile, setProfile] = useState({
    docNom: "",
    docEsp: "",
    docCed: "",
    docExe: "",
    docProv: "",
  });
  const [constatado, setConstatado] = useState("");
  const [recomedacion, setRecomedacion] = useState("");

  // Contador de caracteres
  const maxChars1 = 5000;
  const [profileData, setProfileData] = useState(null);
  const searchInputRef = useRef(null);


  const openModal = (certMedico, customPageSize = 10) => {
    // Configura el certMedico seleccionado para la edición
    setSelectedCertMedicosLocal(certMedico); 
    setPageSize(customPageSize); // Establece la cantidad de registros por página
  
    if (certMedico) {
      setIsEditing(true); // Establecer como modo edición cuando se abre el modal
      
      // Extrae los datos del paciente del certMedico
      const paciente = certMedico.paciente || {}; // Usa un objeto vacío si paciente no está definido

      setFormulario({
        fechaCertMedico: certMedico.certifi_fe || "",
        constatado: certMedico.constatado || "",
        recomedacion: certMedico.recomedacion || "",
        nombrePaciente: paciente.pcteNom || "",
        segundoNombrePaciente: paciente.pcteNom2 || "",
        apellidPaciente: paciente.pcteApe1 || "",
        segundoApellidPaciente: paciente.pcteApe2 || "",
      });
      
      // Establece el paciente seleccionado para el modal
      setSelectedPaciente(paciente);
    } else {
      setIsEditing(false); // Establecer como modo creación cuando se abre el modal para crear uno nuevo

      const now = new Date();
      const formattedDate = now.toLocaleString(); // Obtiene la fecha y hora actual en formato legible
      
      setFormulario({
        fechaCertMedico: formattedDate,
        constatado: "",
        recomedacion: "",
        estatusCertMedico: "activo",
        nombrePaciente: "",
        segundoNombrePaciente: "",
        apellidPaciente: "",
        segundoApellidPaciente: "",
      });

      setSelectedPaciente(null);
    }
    
    setModalOpen(true); // Abre el modal
  };

  const closeModal = () => {
    setSelectedCertMedicosLocal(null); // Limpia el CertMedico seleccionado
    setIsEditing(false); // Resetear el modo edición cuando se cierra el modal
    setModalOpen(false);
  };

  useEffect(() => {
    fetchProfile();
    fetchData();
   
  }, []);

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
    setPageNumber(0);
  };

  const handleModalSearchTermChange = (e) => {
    setModalSearchTerm(e.target.value);
    setModalCurrentPage(1);
  };

  const fetchData = async () => {
    setLoading(true); // Iniciar la carga
    try {
      const patientsResponse = await medicGeekApi.get(
        `/patients?search=${searchTerm}`,
        {
          params: {
            $or: [
              { pcteNom: { $regex: searchTerm, $options: "i" } },
              { pcteNom2: { $regex: searchTerm, $options: "i" } },
              { pcteApe1: { $regex: searchTerm, $options: "i" } },
            ],
          },
        }
      );
      const sortedPacientes = patientsResponse.data.sort(
        (a, b) => a.pcteId - b.pcteId
      );
      
      setPacientes(sortedPacientes);
  
      const certMedicosResponse = await medicGeekApi.get("/certmedicos");
      
  
      if (certMedicosResponse.status === 200) {
        const combinedData = certMedicosResponse.data.map((certMedico) => {
          const paciente = sortedPacientes.find(
            (p) => Number(p.pcteId) === Number(certMedico.pcteId)
          );
  
  
          return {
            ...certMedico,
            pcteNom: paciente?.pcteNom || '',
            pcteNom2: paciente?.pcteNom2 || '',
            pcteApe1: paciente?.pcteApe1 || '',
            pcteApe2: paciente?.pcteApe2 || '',
            pcteDir: paciente?.pcteDir || '',
            pcteCel: paciente?.pcteCel || '',
            paciente: paciente || {},
          };
        });
  
        const sortedData = combinedData.sort((a, b) => a.certifiId - b.certifiId);
        setCertMedicos(sortedData);
        setPageCount(Math.ceil(certMedicos.length / itemsPerPage));
        setHasLoaded(true);
      
      } else {
        console.log("Error al obtener datos de certificados médicos:", certMedicosResponse.statusText);
      }
    } catch (error) {
      console.log("Error al obtener datos:", error);
    } finally {
      setLoading(false); // Finalizar carga
    }
  };
  
  const fetchProfile = async () => {
    try {
      // Obtener el token desde localStorage
      const token = localStorage.getItem("token");
      if (token) {
        // Decodificar el token para obtener el nombre de usuario
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username; // Asegúrate de que 'username' sea correcto

        if (username) {
          // Buscar perfil por nombre de usuario
          const response = await medicGeekApi.get(`/profile/user/${username}`);
          const data = response.data;

          // Verificar si se recibió el perfil
          if (data) {
            setProfileData(data);
            setIsEditing(true);
          } else {
            console.error("No se encontró el perfil del usuario.");
          }
        } else {
          console.error("No se pudo obtener el nombre de usuario del token.");
        }
      } else {
        console.error("No se encontró el token.");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  // Filtro para los pacientes en funcion de termino de busqueda
  const filteredPacientes = pacientes
  .filter((paciente) => {
    // Separar los términos de búsqueda por espacio y eliminar espacios vacíos
    const searchWords = modalSearchTerm.toLowerCase().trim().split(' ');

    // Combinar nombre completo del paciente
    const fullName = [
      paciente.pcteNom,
      paciente.pcteNom2,
      paciente.pcteApe1,
      paciente.pcteApe2,
    ]
      .filter(Boolean) // Filtrar los valores nulos o undefined
      .map((name) => name.toLowerCase()) // Convertir cada nombre a minúsculas
      .join(' '); // Unir en una cadena completa

    // Verificar si todos los términos de búsqueda coinciden con el nombre completo
    return searchWords.every((word) => fullName.includes(word));
  });

  // Filtrar y paginar certMedicos
  const displayedcertMedicos = certMedicos
  .sort((a, b) => b.certifiId - a.certifiId) // Ordena en orden descendente
  .filter((paciente) => {
    const searchWords = searchTerm.toLowerCase().split(" ");
    return searchWords.some(
      (word) =>
        paciente.pcteNom.toLowerCase().includes(word) ||
        (paciente.pcteNom2 && paciente.pcteNom2.toLowerCase().includes(word)) ||
        paciente.pcteApe1.toLowerCase().includes(word) ||
        (paciente.pcteApe2 && paciente.pcteApe2.toLowerCase().includes(word))
    );
  })
  .slice(pageNumber * certMedicosPerPage, (pageNumber + 1) * certMedicosPerPage)
  .map((paciente) => ({
    ...paciente,
    fullName: `${paciente.pcteNom || ''} ${paciente.pcteNom2 || ''} ${paciente.pcteApe1 || ''} ${paciente.pcteApe2 || ''}`.trim()
  }));

  const guardarCertMedico = () => {
    const fechaActual = new Date();
    const dia = String(fechaActual.getDate()).padStart(2, "0");
    const mes = String(fechaActual.getMonth() + 1).padStart(2, "0");
    const año = fechaActual.getFullYear();
    const ampm = fechaActual.getHours() >= 12 ? "PM" : "AM";
    let horas = fechaActual.getHours() % 12;
    horas = horas === 0 ? 12 : horas;
    const minutos = String(fechaActual.getMinutes()).padStart(2, "0");
  
    const fechaHoraFormateada = `${dia}/${mes}/${año} ${horas}:${minutos} ${ampm}`;
  
    // Validación de campos
    if (!formulario.constatado.trim() || !formulario.recomedacion.trim()) {
      showErrorAlert("Algunos campos son obligatorios. Por favor, complete la información.");
      return;
    }
  
    const nuevoCertMedico = {
      certifi_fe: fechaHoraFormateada,
      constatado: formulario.constatado.trim(),
      recomedacion: formulario.recomedacion.trim(),
      pcteId: selectedPaciente ? selectedPaciente.pcteId : null,
    };
  
    // Decide la acción según si es un registro nuevo o existente
    const action = selectedCertMedicosLocal ? 'edit' : 'create';
    const apiUrl = action === 'edit' ? `/certmedicos/${selectedCertMedicosLocal.id}` : "/certmedicos";
  
    medicGeekApi[action === 'edit' ? 'put' : 'post'](apiUrl, nuevoCertMedico)
      .then((response) => {
        closeModal();
        showSuccessAlert("Certificado " + (action === 'edit' ? "editado" : "creado") + " con éxito.");
        fetchData();
        // previsualizarPDF(nuevoCertMedico.certifi_fe);
      })
      .catch((error) => {
        showErrorAlert("Error al " + (action === 'edit' ? "editar" : "crear") + " el Certificado Médico.");
        console.error("Error al " + (action === 'edit' ? "editar" : "crear") + " el Certificado Médico", error);
      });
  };
  
  const editCertMedico = () => {
    if (selectedCertMedicosLocal && selectedCertMedicosLocal.certifiId) {
      const { certifiId, constatado: originalConstatado, recomedacion: originalRecomedacion } = selectedCertMedicosLocal;
  
      const nuevoConstatado = document.getElementById("constatado").value;
      const nuevaRecomedacion = document.getElementById("recomedacion").value;

      const fechaActual = new Date();
      const dia = String(fechaActual.getDate()).padStart(2, "0");
      const mes = String(fechaActual.getMonth() + 1).padStart(2, "0"); // Meses empiezan en 0
      const año = fechaActual.getFullYear();
      const ampm = fechaActual.getHours() >= 12 ? "PM" : "AM";
      let horas = fechaActual.getHours() % 12;
      horas = horas === 0 ? 12 : horas; // Si es 0, establece 12
      const minutos = String(fechaActual.getMinutes()).padStart(2, "0");
    
      const fechaHoraFormateada = `${dia}/${mes}/${año} ${horas}:${minutos} ${ampm}`;
  
      // Verifica si ha habido cambios
      const hasChanges = 
        originalConstatado !== nuevoConstatado || 
        originalRecomedacion !== nuevaRecomedacion;
  
      if (hasChanges) {
        const fechaActual = new Date().toISOString().split("T")[0];
        
        const newCertMedico = {
          certifi_fe: fechaHoraFormateada,
          constatado: nuevoConstatado,
          recomedacion: nuevaRecomedacion,
          pcteId: selectedPaciente ? selectedPaciente.pcteId : null,
        };
  
        // Realiza una solicitud POST para guardar el nuevo registro en el servidor
        medicGeekApi
          .post("/certmedicos", newCertMedico)
          .then((response) => {
            closeModal();
            showSuccessAlert("Nuevo Certificado creado con éxito.");
            fetchData();
          })
          .catch((error) => {
            console.error("Error al crear el nuevo Certificado Médico", error);
            showErrorAlert("Error al crear el nuevo Certificado Médico.");
          });
      } else {
        showInfoAlert("No se han detectado cambios.");
      }
    } else {
      showErrorAlert("No se puede editar: registro no válido.");
    }
  };

  const handlePacienteSelection = (paciente) => {
    setSelectedPaciente(paciente);
    setModalOpen(false); // Cierra el modal actual
    openPacienteModal(); // Abre el nuevo modal para el paciente seleccionado
    
  };

  const openPacienteModal = () => {
    setModalOpen(true);

    setTimeout(() => {
      if (searchInputRef.current) {
          searchInputRef.current.focus();
      }
  }, 100);
  };

  function convertirFechaLarga(fechaStr) {
    const fecha = new Date(fechaStr);
    if (isNaN(fecha.getTime())) {
        console.error("Fecha inválida para convertir.");
        return "";
    }

    const opcionesFecha = { day: "numeric", month: "long", year: "numeric" };
    const opcionesHora = { hour: "numeric", minute: "numeric", hour12: true };

    // Obtener partes de la fecha
    const fechaLarga = new Intl.DateTimeFormat("es-ES", opcionesFecha).format(fecha);
    const horaLarga = new Intl.DateTimeFormat("es-ES", opcionesHora).format(fecha);

    const partesFecha = fechaLarga.split(" de ");
    const dia = partesFecha[0];
    const mes = partesFecha[1];
    const año = partesFecha[2];

    return `día ${dia} del mes de ${mes} del año ${año} a las ${horaLarga}`;
  }
  const fechaStr = "6/6/2024 9:44 AM";
  const fechaLarga = convertirFechaLarga(fechaStr);


  const convertirFechaActualLarga = () => {
    const fecha = new Date();
    if (isNaN(fecha.getTime())) {
        console.error("Fecha inválida para convertir.");
        return "";
    }

    const opcionesFecha = { day: "numeric", month: "long", year: "numeric" };
    const opcionesHora = { hour: "numeric", minute: "numeric", hour12: true };

    // Obtener partes de la fecha
    const fechaLarga = new Intl.DateTimeFormat("es-ES", opcionesFecha).format(fecha);
    const horaLarga = new Intl.DateTimeFormat("es-ES", opcionesHora).format(fecha);

    const partesFecha = fechaLarga.split(" de ");
    const dia = partesFecha[0];
    const mes = partesFecha[1];
    const año = partesFecha[2];

    return `día ${dia} del mes de ${mes} del año ${año} a las ${horaLarga}`;
  };

  const previsualizarPDF = async (certMedico) => {
    const doc = new jsPDF();

    // Define las variables de estilo
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 10;
    const additionalMargin = 40; // Margen adicional de altura
    const fontSize = 10;

    doc.setFontSize(fontSize);

    const fechaLarga = convertirFechaActualLarga();

    // Título
    doc.setFontSize(22); // Puedes ajustar el tamaño como prefieras
    doc.setFont(undefined, "bold");
    const detalleText = "CERTIFICADO MEDICO";
    const textWidth = doc.getTextWidth(detalleText);
    const centeredX = (pageWidth - textWidth) / 2;
    doc.text(detalleText, centeredX, margin + additionalMargin);
    doc.setFont(undefined, "normal");
    

    // Restablece la fuente y el tamaño normal para el resto del contenido
    doc.setFontSize(12);

    // Usa datos del formulario en lugar de certMedico
    const profileText = `Yo, ${profileData.docNom}, Médico ${profileData.docEsp}, Cédula de identidad número. ${profileData.docCed}, provisto del correspondiente exequátur No. ${profileData.docExe}, certifico haber examinado a ${selectedPaciente.pcteNom || ""} ${selectedPaciente.pcteNom2 || ""} ${selectedPaciente.pcteApe1 || ""} ${selectedPaciente.pcteApe2 || ""} Cédula de identidad número. ${selectedPaciente.pcteNumDoc || ""}`.trim();

    // Agrega el profileText al PDF
    const profileLines = doc.splitTextToSize(profileText, pageWidth - 2 * margin);
    let y = margin + additionalMargin + 20; // Ajusta la coordenada y
    const lineHeight = fontSize * 0.6;

    profileLines.forEach((line) => {
        if (y + lineHeight > pageHeight - margin - additionalMargin - lineHeight) {
            doc.addPage();
            y = margin + additionalMargin; // Resetea la coordenada y en la nueva página
        }
        doc.text(line, margin, y);
        y += lineHeight;
    });

    // Certificaciones y recomendaciones
    const certificarText = `Certifico:\n${formulario.constatado}`;
    const recomendarText = `\n\nPor lo que recomiendo:\n${formulario.recomedacion}`; // Añadir dos saltos de línea antes de la recomendación

    const contenidoRestante = `${certificarText}${recomendarText}`; // Combinar ambos textos
    const lines = doc.splitTextToSize(contenidoRestante, pageWidth - 5 * margin);
    y += lineHeight;

    lines.forEach((line) => {
        if (y + lineHeight > pageHeight - margin - additionalMargin - lineHeight) {
            doc.addPage();
            y = margin + additionalMargin;
        }
        doc.text(line, margin, y);
        y += lineHeight;
    });

    // Footer
    const footerText = `${profileData.docDirCert} a petición del interesado, hoy ${fechaLarga}`.trim();
    y += lineHeight + 5;
    const footerLines = doc.splitTextToSize(footerText, pageWidth - 2 * margin);

    if (y + footerLines.length * lineHeight > pageHeight - margin - additionalMargin) {
        doc.addPage();
        y = margin + additionalMargin;
    }

    footerLines.forEach((line) => {
        doc.text(line, margin, y);
        y += lineHeight;
    });

    // Nombre y exequátur
    const alturaNombre = y + 70; // Ajusta esta altura según sea necesario
    const lineaY = alturaNombre - 5; // Ajusta la posición de la línea
    const lineaX = (pageWidth - 80) / 2; // Centra la línea
    doc.setLineWidth(0.5); // Grosor de la línea
    doc.line(lineaX, lineaY, lineaX + 80, lineaY); // Dibuja la línea

    const nombreDoctor = `${profileData.docNom}`;
    doc.setFontSize(12); // Tamaño de fuente
    const nombreX = (pageWidth - doc.getTextWidth(nombreDoctor)) / 1.41; // Calcula la posición centrada
    doc.text(nombreDoctor, nombreX, alturaNombre, { align: "center" }); // Centra el texto

    const exequaturText = `${profileData.docExe}`;
    doc.text(exequaturText, nombreX, alturaNombre + 5, { align: "center" }); // Alinea a la derecha

    // Convertir el PDF a Blob
    const pdfBlob = doc.output('blob');
    let iframe = document.createElement('iframe');
    iframe.style.display = 'none';  // Mantener el iframe oculto
    document.body.appendChild(iframe);

    const blobUrl = URL.createObjectURL(pdfBlob);
    iframe.src = blobUrl;

    iframe.onload = () => {
        iframe.contentWindow.focus();
        iframe.contentWindow.print();

        iframe.contentWindow.onafterprint = () => {
            document.body.removeChild(iframe);
            URL.revokeObjectURL(blobUrl);
        };
    };
  };


  // Contador de caractereres
  const handleChange = (e) => {
    const text = e.target.value;
    if (text.length <= maxChars1) {
      setConstatado(text);
      setRecomedacion(text);
    }
  };

  const sortData = (column) => {
  const isAscending = sortColumn === column && sortDirection === 'asc';

  const sortFunction = (a, b) => {
    let aValue = a[column];
    let bValue = b[column];

    if (column === 'id') {
      // Ordenar numéricamente
      return isAscending ? aValue - bValue : bValue - aValue;
    } else if (column === 'fechaCertificado') {
      // Asegúrate de que aValue y bValue sean fechas válidas
      return isAscending
        ? new Date(aValue) - new Date(bValue)
        : new Date(bValue) - new Date(aValue);
    } else {
      // Comparar cadenas
      aValue = String(aValue || ""); // Convertir a cadena o usar una cadena vacía
      bValue = String(bValue || ""); // Convertir a cadena o usar una cadena vacía

      return isAscending
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }
  };

  // Ordena los datos
  const sortedData = [...certMedicos].sort(sortFunction);

    setCertMedicos(sortedData); // Actualiza el estado con los datos ordenados
    setSortColumn(column);
    setSortDirection(isAscending ? 'desc' : 'asc');
  };

  const SkeletonTableRow = () => (
    <tr>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "60px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "150px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "100px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "70px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "200px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "100px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "60px" }}></div>
      </td>
    </tr>
  );

  return (
    <div>
      <div>
        <div
          className="d-flex justify-content-between miDivConGradiente px-3 rounded-bottom"
          style={{
            height: "5rem",
          }}
        >
          <div className="d-flex align-items-center text-white fw-bold text-uppercase">
            <h2>Certificados Médicos</h2>
          </div>
          <div className="d-flex justify-content-right align-items-center">
            <form className="d-flex" role="search">
              <input
                className="form-control me-2"
                type="search"
                placeholder="Buscar"
                aria-label="Buscar"
                value={searchTerm}
                onChange={handleSearchTermChange} // Usa la función para el buscador principal
              />
            </form>

            <button
              type="button"
              className="btn btn-danger"
              title="Crear Nuevo seguro"
              onClick={() => openModal(null, 10)}
            >
              <i className="bi bi-person-add"></i> Nuevo Certificado Médico
            </button>
          </div>
        </div>
        <div
          className="mb-2"
          style={{
            height: "45vh",
            padding: "0rem 1rem",
          }}
        >
          <table className="table table-striped table-hover mt-5">
            <thead>
              <tr>
                <th onClick={() => sortData('certifiId')}>
                  ID {sortColumn === 'certifiId' && (sortDirection === 'asc' ? " 🔼" : " 🔽")}
                </th>
                <th onClick={() => sortData('nombre')}>
                  Nombre {sortColumn === 'nombre' && (sortDirection === 'asc' ? " 🔼" : " 🔽")}
                </th>
                <th onClick={() => sortData('direccion')}>
                  Dirección {sortColumn === 'direccion' && (sortDirection === 'asc' ? " 🔼" : " 🔽")}
                </th>
                <th onClick={() => sortData('telefono')}>
                  Teléfono {sortColumn === 'telefono' && (sortDirection === 'asc' ? " 🔼" : " 🔽")}
                </th>
                <th onClick={() => sortData('fechaCertificado')}>
                  Fecha de Certificado {sortColumn === 'fechaCertificado' && (sortDirection === 'asc' ? " 🔼" : " 🔽")}
                </th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                // Mostrar 5 filas de skeleton mientras carga
                Array.from({ length: 13 }).map((_, index) => <SkeletonTableRow key={index} />)
              ) : (
              displayedcertMedicos.map((certMedico) => (
                <tr key={certMedico.certifiId}>
                  <td style={{ width: "5%" }}>
                    {certMedico.certifiId}
                  </td>
                  <td
                    style={{ width: "35%", textAlign: "left" }}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={certMedico.fullName}
                  >
                    {certMedico.fullName?.length > 20
                      ? `${certMedico.fullName.slice(0, 40)}...`
                      : certMedico.fullName}
                  </td>
                  <td
                    style={{ width: "30%", textAlign: "left" }}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={certMedico.pcteDir}
                  >
                    {certMedico.pcteDir?.length > 20
                      ? `${certMedico.pcteDir.slice(0, 35)}...`
                      : certMedico.pcteDir}
                  </td>
                  <td style={{ width: "10%" }}>
                    {certMedico.pcteCel}
                  </td>
                  <td style={{ width: "20%" }}>
                    {certMedico.certifi_fe}
                  </td>
                  <td style={{ width: "5%" }}>
                    <div>
                      <button
                        type="button"
                        className="p-1 border-0"
                        title="Editar"
                        onClick={() => openModal(certMedico)}
                        aria-label="Editar"
                      >
                        <i className="bi bi-pencil-square"></i>
                      </button>
                      {/* <button 
                        type='button' 
                        className='p-1 border-0' 
                        title="Imprimir"
                        aria-label="Imprimir"
                      >
                        <i className="bi bi-printer"></i>
                      </button> */}
                    </div>
                  </td>
                </tr>
              ))
            )}
            </tbody>
          </table>

          {/* Paginación */}
          {!showForm && (
            <div className="d-flex justify-content-end" style={{ padding: "0rem 1rem" }}>
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  
                  {/* Botón de página anterior */}
                  <li className={`page-item ${pageNumber === 0 ? "disabled" : ""}`}>
                    <span
                      className="page-link"
                      aria-label="Previous"
                      onClick={() => handlePageChange({ selected: pageNumber - 1 })}
                      style={{ cursor: pageNumber === 0 ? "not-allowed" : "pointer" }}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </span>
                  </li>

                  {Array.from(
                    { length: Math.min(pageCount, 10) },
                    (_, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          pageNumber === index ? " active" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          className="page-link"
                          onClick={() => handlePageChange({ selected: index })}
                        >
                          {index + 1}
                        </span>
                      </li>
                    )
                  )}

                  {/* Botón de siguiente página */}
                  <li className={`page-item ${pageNumber === pageCount - 1 ? "disabled" : ""}`}>
                    <span
                      className="page-link"
                      aria-label="Next"
                      onClick={() => handlePageChange({ selected: pageNumber + 1 })}
                      style={{ cursor: pageNumber === pageCount - 1 ? "not-allowed" : "pointer" }}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </span>
                  </li>
                  
                </ul>
              </nav>
            </div>
          )}
        </div>

        {currentForm === "CertMedicoList" && selectedCertMedicosLocal && (
          <div className="main-content"></div>
        )}
        <Modal
          isOpen={modalOpen}
          toggle={closeModal}
          modalClassName="custom-modal"
          contentClassName="custom-modal-content"
          style={{
            maxWidth: "50rem",
          }}
        >
          <ModalHeader toggle={closeModal}>
            {isEditing
              ? "Editar Certificado Médico"
              : "Crear Nuevo Certificado Médico"}
          </ModalHeader>
          <ModalBody>
            {selectedPaciente ? (
              <div>
                <div class="mb-4 sticky-top pt-3 border-bottom bg-primary text-white px-2 rounded-3">
                  <div className="d-flex justify-content-start">
                    <img
                      alt="Sample"
                      src="https://www.shareicon.net/data/128x128/2016/08/05/806962_user_512x512.png"
                      style={{
                        width: "3rem",
                        marginTop: "1rem",
                      }}
                    />
                    <div className="input-group mx-2 input-group-sm text-uppercase fw-bold d-flex align-items-center">
                      <label className="border-0 fs-4" id="pcteNom">
                        {selectedPaciente.pcteNom} {selectedPaciente.pcteNom2}{" "}
                        {selectedPaciente.pcteApe1} {selectedPaciente.pcteApe2}
                      </label>
                    </div>
                    <div className="border-0 fs-6 d-flex align-items-center">
                      <label className="text-end" id="certifi_fe">
                        {formulario.fechaCertMedico
                         }
                      </label>
                    </div>
                  </div>
                  <div class="d-flex bd-highlight mb-3">
                    <div className="d-flex justify-content-start">
                      <label className="border-0 fs-4 m-1" id="pcteEd">
                        {selectedPaciente.pcteEd} |
                      </label>
                      <label className="border-0 fs-4 m-1" id="pcteNumDoc">
                        {selectedPaciente.pcteNumDoc} |
                      </label>
                      <label className="border-0 fs-4 m-1" id="pcteSx">
                        {selectedPaciente.pcteSx}
                      </label>
                    </div>
                  </div>
                  <div></div>
                </div>
                <Form>
                  <FormGroup>
                    <Label for="constatado">
                      Constatado <span className="text-danger fw-bold">*</span>
                    </Label>
                    <Input
                      type="textarea"
                      id="constatado"
                      value={formulario.constatado}
                      onChange={(e) =>
                        setFormulario({
                          ...formulario,
                          constatado: e.target.value,
                        })
                      }
                      style={{
                        height: "10rem",
                      }}
                      maxLength={maxChars1}
                    />
                    <p style={{ fontSize: "12px", display: "flex" }}>
                      {maxChars1 - formulario.constatado.length}/{maxChars1}
                    </p>
                  </FormGroup>
                  <FormGroup>
                    <Label for="recomedacion">
                      Recomendación{" "}
                      <span className="text-danger fw-bold">*</span>
                    </Label>
                    <Input
                      type="textarea"
                      id="recomedacion"
                      value={formulario.recomedacion}
                      onChange={(e) =>
                        setFormulario({
                          ...formulario,
                          recomedacion: e.target.value,
                        })
                      }
                      style={{
                        height: "10rem",
                      }}
                      maxLength={maxChars1}
                      required
                    />
                    <p style={{ fontSize: "12px", display: "flex" }}>
                      {maxChars1 - formulario.recomedacion.length}/{maxChars1}
                    </p>
                  </FormGroup>
                  <FormGroup>
                    <Label for="recomedacion">Dirección</Label>
                    <Input
                      type="textarea"
                      id=""
                      value={profileData.docDirCert}
                      // onChange={(e) => profile && profile[0].docDirCert}
                      disabled
                    />
                  </FormGroup>
                </Form>
              </div>
            ) : (
              <div>
              <Form className="mb-4 sticky-top pt-3 border-bottom bg-primary text-white px-2 rounded-3">
                <FormGroup>
                  <div className="d-flex flex-column">
                    <Label for="search">Buscar por nombre:</Label>
                    <input
                      className="rounded-3 px-2 py-2 border-0"
                      type="text"
                      name="search"
                      id="search"
                      placeholder="Nombre del paciente"
                      value={modalSearchTerm} // Usa el estado del buscador del modal
                      onChange={handleModalSearchTermChange} // Usa la función para el modal
                      ref={searchInputRef}
                    />
                  </div>
                </FormGroup>
              </Form>
              
              <ListGroup>
                {filteredPacientes
                  .slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                  )
                  .map((paciente) => {
                    // Construir el nombre completo excluyendo valores nulos o vacíos
                    const fullName = [paciente.pcteNom, paciente.pcteNom2, paciente.pcteApe1, paciente.pcteApe2]
                      .filter(Boolean) // Filtra los valores nulos, undefined o vacíos
                      .join(' '); // Junta los nombres filtrados con espacios
                    
                    return (
                      <ListGroupItem key={paciente.pcteId}>
                        <div className="d-flex justify-content-between">
                          {/* Mostrar solo si el nombre completo tiene contenido */}
                          {fullName && <span>{fullName}</span>}
                          <Button
                            color="primary"
                            title="Nuevo Certificado Médico"
                            onClick={() => handlePacienteSelection(paciente)}
                          >
                            <i className="bi bi-clipboard-check-fill"></i>
                          </Button>
                        </div>
                      </ListGroupItem>
                    );
                  })}
              </ListGroup>

              {/* Paginación adaptada al modal */}
              <nav className="pt-3 d-flex justify-content-center">
                <ul className="pagination justify-content-center">
                  <li className="page-item" style={{ cursor: "pointer" }}>
                    <span className="page-link" onClick={() => setCurrentPage(1)}>
                      Inicio
                    </span>
                  </li>
          
                  <li className="page-item" style={{ cursor: "pointer" }}>
                    <span
                      className="page-link"
                      style={{
                        height: "2.4rem",
                        width: "7rem",
                      }}
                      onClick={() => setCurrentPage(Math.max(1, currentPage - 10))}
                    >
                      Anterior 10
                    </span>
                  </li>
          
                  {Array.from(
                    { length: Math.min(Math.ceil(filteredPacientes.length / itemsPerPage), 10) },
                    (_, index) => (
                      <li
                        key={index}
                        className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          className="page-link"
                          onClick={() => setCurrentPage(index + 1)}
                        >
                          {index + 1}
                        </span>
                      </li>
                    )
                  )}
          
                  <li className="page-item" style={{ cursor: "pointer" }}>
                    <span
                      className="page-link"
                      style={{
                        height: "2.4rem",
                        width: "7.5rem",
                      }}
                      onClick={() =>
                        setCurrentPage(
                          Math.min(
                            Math.ceil(filteredPacientes.length / itemsPerPage),
                            currentPage + 10
                          )
                        )
                      }
                    >
                      Siguiente 10
                    </span>
                  </li>
          
                  <li className="page-item" style={{ cursor: "pointer" }}>
                    <span
                      className="page-link"
                      onClick={() =>
                        setCurrentPage(Math.ceil(filteredPacientes.length / itemsPerPage))
                      }
                    >
                      Final
                    </span>
                  </li>
                </ul>
              </nav>
            </div>
            )}
          </ModalBody>
          <ModalFooter>
            {selectedPaciente ? (
              <>
                <Button
                  className="button-save text-primary"
                  color="primary"
                  onClick={isEditing ? editCertMedico : guardarCertMedico}
                  style={{
                    height: "3rem",
                  }}
                >
                  <i class="bi bi-floppy-fill pe-1"></i>
                  {isEditing ? "Guardar Edición" : "Guardar"}
                </Button>

                <Button
                  className="button-print"
                  onClick={() => previsualizarPDF(selectedCertMedicosLocal)}
                  type="button"
                  style={{
                    color: "#cb05f3",
                    height: "3rem",
                    marginLeft: "10px",
                  }}
                >
                  <i className="bbi bi-printer-fill pe-1"></i>
                  Imprimir
                </Button>
              </>
            ) : (
              <Button
                className="button-cancel text-secondary"
                onClick={closeModal}
                style={{ height: "3rem" }}
              >
                Cancelar
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default CertMedicoList;
